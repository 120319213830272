// returns a new array with the first element matching the predicate (if any), with the replacement
function findAndReplace(arr, predicate, replacement) {
  const newArr = [...arr];
  const idx = arr.findIndex(predicate);
  if (idx !== -1) {
    newArr.splice(idx, 1, replacement);
  }
  return newArr;
}

export { findAndReplace };
