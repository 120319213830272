import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import NutritionalPanelCatalogCard from 'components/nutritional_panels/NutritionalPanelCatalogCard';

function NutritionalPanelCatalogView({ nutritionalPanels, panelCount }) {
  return (
    <div className={classNames(css(styles.container), 'd-flex flex-wrap justify-content-center')}>
      {panelCount ? (
        nutritionalPanels.map(nutritionalPanel => (
          <NutritionalPanelCatalogCard
            key={nutritionalPanel.id}
            nutritionalPanel={nutritionalPanel}
          />
        ))
      ) : (
        <div className="p-5 text-muted h5">No nutritional panels found for the given upload</div>
      )}
    </div>
  );
}
const styles = StyleSheet.create({
  container: {
    minHeight: 500,
  },
});

NutritionalPanelCatalogView.propTypes = {
  nutritionalPanels: PropTypes.array,
  panelCount: PropTypes.number,
};

export default NutritionalPanelCatalogView;
