import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import { PRODUCT_OUTLIERS_CHANGES } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function ProductChangesTableView({ globalProductId }) {
  const { data: { data: product_changes = [] } = {}, loading } = useGet(
    PRODUCT_OUTLIERS_CHANGES,
    {
      global_product_id: globalProductId,
    },
    !globalProductId,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!product_changes || product_changes.length === 0) {
    return <div className="d-flex justify-content-center m-5 text-muted">No activities found</div>;
  }

  const formatMetaData = metaData => {
    return (
      <ul>
        {Object.entries(metaData).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong>{' '}
            {typeof value === 'object' &&
            value !== null &&
            value !== undefined &&
            !Array.isArray(value) ? (
              <ul>
                {Object.entries(value).map(([key1, value1]) => (
                  <li key={key1}>
                    <strong>{key1}:</strong> {value1}
                  </li>
                ))}
              </ul>
            ) : typeof value === 'boolean' ? (
              value.toString()
            ) : Array.isArray(value) ? (
              value.join(', ')
            ) : (
              value
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Table bordered striped hover>
      <thead>
        <tr>
          <th>User</th>
          <th>Date</th>
          <th>Change Type</th>
          <th>Meta Data</th>
        </tr>
      </thead>
      <tbody>
        {product_changes.map(log => {
          return (
            <tr key={log.id}>
              <td className="py-2">{log.user.name}</td>
              <td className="py-2">{dayjs(log.date_created).format('YYYY-MM-DD HH:mm:ss')}</td>
              <td className="py-2">{log.meta_data.change_type}</td>
              <td className="py-2">{log.meta_data ? formatMetaData(log.meta_data) : ''}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

ProductChangesTableView.propTypes = {
  globalProductId: PropTypes.number,
};

export default ProductChangesTableView;
