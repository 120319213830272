import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faCopy, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { ATTRIBUTION_RECALCULATE_UPB_SCORES } from 'lib/networking/endpoints';
import pointerOnHover from 'lib/css/pointerOnHover';
import usePost from 'lib/hooks/usePost';
import useProductScoreStyle from 'lib/hooks/product/useProductScoreStyle';

function ProductScoreCard({ product = {}, productType }) {
  const [jsonLogs, setJsonLogs] = useState();
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [newScores, setNewScores] = useState({});
  const { letter: baseScoreLetter, backgroundStyle: baseScoreBackground } = useProductScoreStyle(
    product.base_and_logistics_score,
  );
  const { letter: imageScoreLetter, backgroundStyle: imageScoreBackground } = useProductScoreStyle(
    product.image_and_marketing_score,
  );
  const { letter: nutritionScoreLetter, backgroundStyle: nutritionScoreBackground } =
    useProductScoreStyle(product.nutrition_score);
  const { letter: topOffScoreLetter, backgroundStyle: topOffScoreBackground } =
    useProductScoreStyle(product.top_off_and_claims_score);

  const { postData: calculateScore, loading } = usePost(ATTRIBUTION_RECALCULATE_UPB_SCORES, res => {
    setNewScores(res?.data?.scores);
    setJsonLogs(res?.data?.score_logs);
  });

  const jsonRef = React.useRef(null);

  const handleCopyClick = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(jsonRef.current.innerText);
    } else {
      document.execCommand('copy', true, jsonRef.current.innerText);
    }
    Swal.fire({
      icon: 'success',
      title: 'Logs copied to clipboard',
      showConfirmButton: false,
      timer: 1000,
    });
  };

  if (
    !product.base_and_logistics_score &&
    !product.image_and_marketing_score &&
    !product.nutrition_score &&
    !product.top_off_and_claims_score &&
    !loading &&
    !newScores.length == 0
  ) {
    return (
      <div className="d-flex my-1">
        <div>No scores yet</div>
        <RecalculateButton
          calculateScore={calculateScore}
          loading={loading}
          product={product}
          productType={productType}
        />
      </div>
    );
  }

  return (
    <div className="d-flex my-1">
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip>
            Base & Logistics
            {`(${newScores.base_and_logistics_score || product.base_and_logistics_score}%)`}
          </Tooltip>
        }
      >
        <div className={classNames(css(styles.mark), css(baseScoreBackground))}>
          {baseScoreLetter}
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip>
            Image & Marketing
            {`(${newScores.image_and_marketing_score || product.image_and_marketing_score}%)`}
          </Tooltip>
        }
      >
        <div className={classNames(css(styles.mark), css(imageScoreBackground))}>
          {imageScoreLetter}
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip>
            Nutrition {`(${newScores.nutrition_score || product.nutrition_score}%)`}
          </Tooltip>
        }
      >
        <div className={classNames(css(styles.mark), css(nutritionScoreBackground))}>
          {nutritionScoreLetter}
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip>
            Top-Off & Claims
            {`(${newScores.top_off_and_claims_score || product.top_off_and_claims_score}%)`}
          </Tooltip>
        }
      >
        <div className={classNames(css(styles.mark), css(topOffScoreBackground))}>
          {topOffScoreLetter}
        </div>
      </OverlayTrigger>

      <RecalculateButton
        calculateScore={calculateScore}
        loading={loading}
        product={product}
        productType={productType}
      />

      {!loading && jsonLogs && (
        <div className="ml-2">
          <span className={css(styles.logs_link)} onClick={() => setShowLogsModal(true)}>
            View Logs
          </span>
        </div>
      )}

      <Modal size="lg" show={showLogsModal} onHide={() => setShowLogsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>UPB Score Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classNames(css(styles.logsModalContent))}>
          <FontAwesomeIcon
            icon={faCopy}
            size="lg"
            className={classNames(pointerOnHover, 'float-right', 'mr-2', 'mt-2')}
            onClick={handleCopyClick}
          />
          <div className="d-flex justify-content-center">
            <pre>
              <code ref={jsonRef}>{JSON.stringify(jsonLogs, null, 2)}</code>
            </pre>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function RecalculateButton({ calculateScore, loading, product, productType }) {
  return (
    <OverlayTrigger
      placement="top-start"
      overlay={<Tooltip>Re-calculate upb product scores</Tooltip>}
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={faSync}
          className={classNames(pointerOnHover, 'ml-2', loading && 'fa-spin')}
          onClick={() => calculateScore({ product_type: productType, product_id: product.id })}
        />
      </div>
    </OverlayTrigger>
  );
}

const styles = StyleSheet.create({
  mark: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: '#fff',
    padding: '3px 5px',
    borderRadius: '5px',
    marginRight: '10px',
    minWidth: '30px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  logs_link: {
    textDecoration: 'underline',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  logsModalContent: {
    height: '80vh',
    overflow: 'scroll',
  },
});

RecalculateButton.propTypes = {
  calculateScore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  productType: PropTypes.string,
};

ProductScoreCard.propTypes = {
  product: PropTypes.shape({
    base_and_logistics_score: PropTypes.number,
    image_and_marketing_score: PropTypes.number,
    nutrition_score: PropTypes.number,
    top_off_and_claims_score: PropTypes.number,
  }).isRequired,
  productType: PropTypes.string,
};

export default ProductScoreCard;
