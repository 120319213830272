import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, DropdownButton } from 'react-bootstrap';

function GlobalProductConflictResolveButton({
  conflict,
  onClickUpdateGtinUpc,
  onClickMergeDuplicate,
  onClickNewGlobalProduct,
}) {
  return (
    <DropdownButton id="dropdown-basic-button" title="Resolve" size="sm">
      {conflict.reason.includes('mismatch') ? (
        <Dropdown.Item onClick={() => onClickUpdateGtinUpc(conflict)}>
          Update UPC/GTIN
        </Dropdown.Item>
      ) : null}
      {conflict.reason.includes('duplicate') ? (
        <Dropdown.Item onClick={() => onClickMergeDuplicate(conflict)}>
          Merge Duplicate
        </Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={() => onClickNewGlobalProduct(conflict)}>
          Create new Global Product
        </Dropdown.Item>
      )}
    </DropdownButton>
  );
}

GlobalProductConflictResolveButton.propTypes = {
  conflict: PropTypes.object,
  onClickMergeDuplicate: PropTypes.func,
  onClickNewGlobalProduct: PropTypes.func,
  onClickUpdateGtinUpc: PropTypes.func,
};

export default GlobalProductConflictResolveButton;
