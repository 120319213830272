// update along with app/lib/enums/product_status.py
const ProductStatus = {
  UNCATEGORIZED: 0,
  CATEGORIZED: 1,
  LEGACY: 2,
  MIGRATION_FAILED: 3,
  LEGACY_UNCLASSIFIED: 4,
  MIGRATION_FAILED_UNCLASSIFIED: 5,
  CATEGORY_PREDICTED: 10,
};

export const PRODUCT_STATUS_OPTIONS = [
  { value: ProductStatus.UNCATEGORIZED, label: 'Uncategorized' },
  { value: ProductStatus.CATEGORIZED, label: 'Categorized' },
  { value: ProductStatus.LEGACY, label: 'Uncategorized: Legacy' },
  { value: ProductStatus.MIGRATION_FAILED, label: 'Uncategorized: Rosetta Migration' },
  { value: ProductStatus.LEGACY_UNCLASSIFIED, label: 'Uncategorized: Imported After Legacy' },
  {
    value: ProductStatus.MIGRATION_FAILED_UNCLASSIFIED,
    label: 'Uncategorized: Imported After Rosetta Migration',
  },
  {
    value: ProductStatus.CATEGORY_PREDICTED,
    label: 'Uncategorized: Category Predicted',
  },
];

export const ProductStatusOfGlobalAttribute = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
};

export const SELECT_ALL_ON_PAGE = 'page_select';

export default ProductStatus;
