import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { getURL, RESOLVE_CONFLICT_BY_NEW_GLOBAL_PRODUCT } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function ResolveByNewGlobalProductModal({ conflict, show, onHide, refetch }) {
  const [upc, setUpc] = useState(conflict.upc);
  const [gtin, setGtin] = useState(conflict.gtin);
  const [name, setName] = useState(conflict.name);

  const { postData: resolveConflictByNewGlobalProduct, loading } = usePost(
    getURL(RESOLVE_CONFLICT_BY_NEW_GLOBAL_PRODUCT, { conflictId: conflict.id }),
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created a new global product',
        title: 'Success',
        position: 'center',
      }).then(() => {
        refetch();
        onHide();
      });
    },
    error => {
      const res = error?.response?.data;
      if (res?.product_id) {
        Swal.fire({
          icon: 'error',
          html: `
            <div>
              <div>${res.message}</div>
              <a
                href='/global-products/${res.product_id}'
                rel='noopener noreferrer'
                target='_blank'
              >
                View Product
              </a>
            </div>`,
          title: 'Error',
          position: 'center',
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while resolving the conflicts',
          title: 'Error',
          position: 'center',
        });
      }
    },
  );

  const onSubmit = () => {
    let warningMessage;
    if (!gtin && !upc) {
      warningMessage = 'Are you sure you want to submit without a UPC and a GTIN value?';
    } else if (!gtin) {
      warningMessage = 'Are you sure you want to submit without a GTIN value?';
    } else if (!upc) {
      warningMessage = 'Are you sure you want to submit without a UPC value?';
    }
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: warningMessage,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(res => {
      if (res.isConfirmed) {
        resolveConflictByNewGlobalProduct({ name, gtin, upc });
      }
    });
  };

  return (
    <Modal centered show={show} onHide={onHide} animation={false}>
      <Modal.Header>
        <h4>Resolve Conflict by Creating a new Global Product</h4>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={e => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control onChange={e => setName(e.target.value)} value={name || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>UPC</Form.Label>
            <Form.Control onChange={e => setUpc(e.target.value)} value={upc || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>GTIN</Form.Label>
            <Form.Control onChange={e => setGtin(e.target.value)} value={gtin || ''} />
          </Form.Group>
          <Button block type="submit" disabled={loading}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

ResolveByNewGlobalProductModal.propTypes = {
  conflict: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default ResolveByNewGlobalProductModal;
