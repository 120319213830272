import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';

import CreateNewCategoryModal from 'components/manage_taxonomy/CreateNewCategoryModal';
import Container from 'components/shared/Container';
import { DEFAULT_CATEGORY_VIEW_PAGE_SIZE } from 'lib/constants';
import { removeEmpty } from 'lib/utils/objectUtils';
import { TAXONOMY_EXPORT, TAXONOMY_GET } from 'lib/networking/endpoints';
import TaxonomyTable from 'components/manage_taxonomy/TaxonomyTable';
import useGet from 'lib/hooks/useGet';

function ManageTaxonomyView({ onClassify, manageTaxonomy = false }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [l0Search, setL0Search] = useState('');
  const [l1Search, setL1Search] = useState('');
  const [l2Search, setL2Search] = useState('');
  const [l3Search, setL3Search] = useState('');
  const [l4Search, setL4Search] = useState('');
  const [rowsToRender, setRowsToRender] = useState([]);
  const [createModalOptions, setCreateModalOptions] = useState({ show: false });
  const [sortConfig, setSortConfig] = useState({ field: '', direction: '' });
  const [categoryModified, setCategoryModified] = useState(false);

  const {
    data: { categories: l0Categories, count } = {},
    loading,
    error,
    refetch,
  } = useGet(
    TAXONOMY_GET,
    removeEmpty({
      manage_taxonomy: manageTaxonomy,
      limit: DEFAULT_CATEGORY_VIEW_PAGE_SIZE,
      offset: DEFAULT_CATEGORY_VIEW_PAGE_SIZE * (currentPage - 1),
      l0_category: l0Search,
      l1_category: l1Search,
      l2_category: l2Search,
      l3_category: l3Search,
      l4_category: l4Search,
      sort_by: sortConfig.field,
      sort_direction: sortConfig.direction,
    }),
  );

  const { loading: exportLoading, refetch: exportTaxonomy } = useGet(
    TAXONOMY_EXPORT,
    {},
    false,
    true,
  );

  const exportPopup = () =>
    Swal.fire({
      icon: 'success',
      html: `
      <div>
        The taxonomy csv file should be ready soon. 
        You can download the generated file from\n
        <a
          href="/file-exports"
          rel="noopener noreferrer"
          target="_blank"
        >
          File Exports Page
        </a>
      </div>`,
      title: 'Success',
      position: 'center',
    });

  useEffect(() => {
    if (categoryModified) {
      refetch();
      setCategoryModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryModified]);

  if (error || !l0Categories) {
    return null;
  }

  return (
    <Container>
      {manageTaxonomy && (
        <div className="d-flex justify-content-between">
          <h3 className="font-weight-bold">Manage Taxonomy</h3>
          <div>
            <Button
              className="px-4"
              variant="outline-primary"
              onClick={() => exportTaxonomy(exportPopup())}
              disabled={exportLoading}
            >
              Export
            </Button>
          </div>
        </div>
      )}
      <div className={css(styles.scrollableContainer)}>
        <TaxonomyTable
          l0Categories={l0Categories}
          loading={loading}
          l0Search={l0Search}
          l1Search={l1Search}
          l2Search={l2Search}
          l3Search={l3Search}
          l4Search={l4Search}
          setL0Search={setL0Search}
          setL1Search={setL1Search}
          setL2Search={setL2Search}
          setL3Search={setL3Search}
          setL4Search={setL4Search}
          onClassify={onClassify}
          setCurrentPage={setCurrentPage}
          manageTaxonomy={manageTaxonomy}
          currentPage={currentPage}
          setCreateModalOptions={setCreateModalOptions}
          rowsToRender={rowsToRender}
          setRowsToRender={setRowsToRender}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          count={count}
          setCategoryModified={setCategoryModified}
        />
      </div>
      {createModalOptions.show ? (
        <CreateNewCategoryModal
          modalOptions={createModalOptions}
          onHide={() => setCreateModalOptions({ show: false })}
          refetch={refetch}
          setCurrentPage={setCurrentPage}
          setCategoryModified={setCategoryModified}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '90vh',
  },
});

ManageTaxonomyView.propTypes = {
  onClassify: PropTypes.func,
  manageTaxonomy: PropTypes.bool,
};

export default ManageTaxonomyView;
