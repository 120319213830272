import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import pointerOnHover from 'lib/css/pointerOnHover';

function GlobalProductWiseConflictsTable({ globalProducts }) {
  const history = useHistory();

  return (
    <Table striped bordered hover responsive className={css(styles.tableText)}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>GTIN</th>
          <th>UPC</th>
        </tr>
      </thead>
      <tbody>
        {globalProducts.length ? (
          globalProducts.map(product => (
            <tr
              key={product.id}
              className={pointerOnHover}
              onClick={() => history.push(`/global-products/${product.id}`)}
            >
              <td>{product.id}</td>
              <td className={css(styles.productName)}>{product.name}</td>
              <td>{product.gtin}</td>
              <td>{product.upc}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="100%" className="text-center font-weight-bold">
              No Global Products Found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.65rem',
  },
  productName: {
    minWidth: 300,
  },
});

GlobalProductWiseConflictsTable.propTypes = {
  globalProducts: PropTypes.array,
};

export default GlobalProductWiseConflictsTable;
