import React from 'react';
import PropTypes from 'prop-types';

function ProductDescriptionSection({ attributes }) {
  const romanceCopy =
    attributes?.find(attribute => attribute.attribute_name === 'romance_copy_long')
      ?.attribute_value || '';
  const featuresAndBenefits =
    attributes?.find(attribute => attribute.attribute_name === 'features_and_benefits_extended')
      ?.attribute_value || '';

  const features = (featuresAndBenefits.split('\n') || []).filter(Boolean);

  if (!romanceCopy && !features.length) {
    return null;
  }

  return (
    <div className="my-4">
      <div>
        <div>{romanceCopy}</div>
        <div>
          {features.length > 0 && (
            <ul className="pl-3 mt-2">
              {features.map((value, index) => (
                <li key={index}>{value}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

ProductDescriptionSection.propTypes = {
  attributes: PropTypes.array,
};

export default ProductDescriptionSection;
