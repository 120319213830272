const P0SyncAttributes = {
  manufacturer_name: 'manufacturer_name',
  manufacturer_cnd_id: 'manufacturer_cnd_id',
  thumbnail: 'thumbnail',
  brand_cnd_id: 'brand_cnd_id',
  brand_name: 'brand_name',
  pack_size: 'pack_size',
  vendor_category: 'vendor_category',
  vendor_subcategory: 'vendor_subcategory',
  catch_or_variable_weight: 'catch_or_variable_weight',
  p1_score: 'p1_score',
  sku: 'SKU',
  name: 'name',
  storage_temperature: 'storage_temperature',
  trade_item_key_words_comma_separated: 'trade_item_key_words_comma-separated',
};

const P0SyncAttributeOptions = [
  { value: P0SyncAttributes.manufacturer_name, label: 'manufacturer_name' },
  { value: P0SyncAttributes.manufacturer_cnd_id, label: 'manufacturer_cnd_id' },
  { value: P0SyncAttributes.thumbnail, label: 'thumbnail' },
  { value: P0SyncAttributes.brand_cnd_id, label: 'brand_cnd_id' },
  { value: P0SyncAttributes.brand_name, label: 'brand_name' },
  { value: P0SyncAttributes.pack_size, label: 'pack_size' },
  { value: P0SyncAttributes.vendor_category, label: 'vendor_category' },
  { value: P0SyncAttributes.vendor_subcategory, label: 'vendor_subcategory' },
  { value: P0SyncAttributes.catch_or_variable_weight, label: 'catch_or_variable_weight' },
  { value: P0SyncAttributes.p1_score, label: 'p1_score' },
];

export { P0SyncAttributes, P0SyncAttributeOptions };
