import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

function CatalogAttributeFilterValue({ catalogFilterAttributeValue }) {
  const attribute = catalogFilterAttributeValue.attribute_value?.attributes;
  if (!attribute) {
    return null;
  }

  return (
    <div>
      <div className="mt-3">
        <Form.Group as={Row} className="w-100">
          <Form.Label
            as={Col}
            type="text"
            placeholder="Filter Name"
            className="d-flex justify-content-end align-items-center"
          >
            {attribute.attribute_name}
          </Form.Label>
          <Col>
            <Select
              options={[]}
              placeholder="Select value"
              value={{
                label: catalogFilterAttributeValue.attribute_value.attribute_value,
                value: catalogFilterAttributeValue.attribute_value.id,
              }}
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Display Name"
              value={catalogFilterAttributeValue.display_name || ''}
            />
          </Col>
        </Form.Group>
      </div>
    </div>
  );
}

CatalogAttributeFilterValue.propTypes = {
  catalogFilterAttributeValue: PropTypes.object,
};

export default CatalogAttributeFilterValue;
