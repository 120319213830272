import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { ATTRIBUTE_VALUES_BY_ATTRIBUTE_NAME } from 'lib/networking/endpoints';
import ProductListFilter from 'lib/enums/ProductListFilter';
import useGet from 'lib/hooks/useGet';

const ATTRIBUTE_NAME_FOR_STATUS = 'Status';

function StatusFilter({ selectedValues, updateFilters }) {
  const { data: { attribute_values = [] } = [] } = useGet(ATTRIBUTE_VALUES_BY_ATTRIBUTE_NAME, {
    attribute_name: ATTRIBUTE_NAME_FOR_STATUS,
  });

  const options = attribute_values.map(val => ({
    label: val.attribute_value,
    value: val.attribute_value,
  }));

  return (
    <Form.Group>
      <Form.Label>Select Status</Form.Label>
      <Select
        isMulti
        isClearable
        options={options}
        value={selectedValues.map(opt => ({ label: opt, value: opt }))}
        onChange={opt =>
          updateFilters(
            ProductListFilter.STATUS,
            opt.map(option => option.value),
          )
        }
      />
    </Form.Group>
  );
}

StatusFilter.propTypes = {
  selectedValues: PropTypes.array,
  updateFilters: PropTypes.func,
};

export default StatusFilter;
