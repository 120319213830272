import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { toSafeInteger } from 'lodash';
import { useDebounce } from 'use-debounce';

import { backgroundGrey5 } from 'lib/css/colors';
import CatalogSidePanel from 'components/upb_viewer/CatalogSidePanel';
import Container from 'components/shared/Container';
import Cookies from 'lib/enums/Cookies';
import { DEFAULT_UPB_PAGE_SIZE } from 'lib/constants';
import { getURL, GLOBAL_PRODUCT_MANUFACTURERS_BY_SEARCH } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import MixpanelEvents from 'lib/enums/MixpanelEvents';
import PaginationBar from 'components/shared/PaginationBar';
import SearchBar from 'components/shared/SearchBar';
import UPBCatalogView from 'components/upb_viewer/UPBCatalogView';
import useGet from 'lib/hooks/useGet';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import useMixpanel from 'lib/hooks/useMixpanel';

const DEFAULT_ALL_CATEGORY_OPTION = { label: 'All', value: null };

function UPBViewer() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([DEFAULT_ALL_CATEGORY_OPTION]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [selectedL1CategoryId, setSelectedL1CategoryId] = useState(null);
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [productsToShow, setProductsToShow] = useState([]);
  const [verifiedManufacturerProductsOnly, setVerifiedManufacturerProductsOnly] = useLocalStorage(
    Cookies.VERIFIED_MANUFACTURERS_ONLY_UPB,
  );

  const mixpanel = useMixpanel();

  useEffect(() => {
    mixpanel.track(MixpanelEvents.UPB_PAGE_VIEWED);
  }, [mixpanel]);

  const page = useMemo(() => Math.max(toSafeInteger(currentPage) - 1, 0), [currentPage]);
  const offset = useMemo(() => {
    return page * DEFAULT_UPB_PAGE_SIZE || 0;
  }, [page]);

  useEffect(() => {
    if (selectedCategories) {
      const selectedCatValue = selectedCategories[0].value;
      setSelectedL1CategoryId(selectedCatValue);
      // If not the all category option, reset search query
      if (selectedCatValue) {
        mixpanel.track(MixpanelEvents.UPB_CATEGORY_SELECTED, {
          category_id: selectedCatValue,
          category_name: selectedCategories[0].label,
        });
        setSearchQuery('');
      }

      if (selectedCatValue !== null) {
        setSelectedBrands([]);
        setSelectedManufacturers([]);
      }
    }
  }, [mixpanel, selectedCategories]);

  const onSearchQueryChange = query => {
    setSearchQuery(query);
    setProductsToShow([]);
  };

  const selectedBrandIds = useMemo(() => {
    if (!selectedBrands?.length) {
      return null;
    }
    mixpanel.track(MixpanelEvents.UPB_BRAND_SELECTED, {
      brand_names: selectedBrands.map(brand => brand.label),
    });
    setSelectedCategories([DEFAULT_ALL_CATEGORY_OPTION]);
    return selectedBrands.map(brand => brand.value);
  }, [mixpanel, selectedBrands]);

  const selectedManufacturerIds = useMemo(() => {
    if (!selectedManufacturers?.length) {
      return null;
    }
    mixpanel.track(MixpanelEvents.UPB_MANUFACTURER_SELECTED, {
      manufacturer_names: selectedManufacturers.map(manufacturer => manufacturer.label),
    });
    setSelectedCategories([DEFAULT_ALL_CATEGORY_OPTION]);
    return selectedManufacturers.map(manufacturer => manufacturer.value);
  }, [mixpanel, selectedManufacturers]);

  const {
    data: { data: products = [], count = 0 } = {},
    loading,
    error,
  } = useGet(getURL(GLOBAL_PRODUCT_MANUFACTURERS_BY_SEARCH, {}), {
    offset,
    limit: DEFAULT_UPB_PAGE_SIZE,
    search_query: debouncedQuery,
    l1_category_id: debouncedQuery ? null : selectedL1CategoryId,
    brand_ids: debouncedQuery ? null : selectedBrandIds,
    manufacturer_ids: debouncedQuery ? null : selectedManufacturerIds,
    verified_manufacturer_products_only: verifiedManufacturerProductsOnly,
  });

  useEffect(() => {
    if (debouncedQuery) {
      mixpanel.track(MixpanelEvents.UPB_SEARCH_QUERY_ENTERED, {
        search_query: debouncedQuery,
      });
    }
  }, [debouncedQuery, mixpanel]);

  useEffect(() => {
    if (products.length) {
      // when there is a debounced query, reset categories to all
      if (debouncedQuery) {
        setSelectedCategories([DEFAULT_ALL_CATEGORY_OPTION]);
      }

      setProductsToShow(products);
      if (count < DEFAULT_UPB_PAGE_SIZE) {
        setCurrentPage(1);
      }
    }
  }, [count, debouncedQuery, products]);

  const totalPages = useMemo(() => Math.ceil(count / DEFAULT_UPB_PAGE_SIZE), [count]);

  if (error) {
    return null;
  }

  return (
    <Container>
      <Row className={classNames('mb-4 ml-4', css(styles.titleContainer))}>
        <Col>
          <h3 className="font-weight-bold">UPB View</h3>
        </Col>
        <Col>
          <div className="d-flex justify-content-end flex-row align-items-center">
            <div className="w-50 text-right mr-2 text-muted">
              {`${offset}-${offset + DEFAULT_UPB_PAGE_SIZE} of ${count}`} Items
            </div>
            <SearchBar
              value={searchQuery}
              setValue={onSearchQueryChange}
              placeholder="Search by name, gtin"
              disabled={false}
            />
          </div>
        </Col>
      </Row>
      <Row className={css(styles.catalogContainer)}>
        <Col md={2} className={css(styles.sidePanel)}>
          <CatalogSidePanel
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            selectedManufacturers={selectedManufacturers}
            setSelectedManufacturers={setSelectedManufacturers}
            verifiedManufacturerProductsOnly={verifiedManufacturerProductsOnly}
            setVerifiedManufacturerProductsOnly={setVerifiedManufacturerProductsOnly}
          />
        </Col>
        <Col md={10} className={css(styles.productSection)}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="d-flex justify-content-center flex-column align-items-center">
              <UPBCatalogView products={productsToShow} productCount={count} />
              <div className="my-4">
                <PaginationBar
                  totalPages={totalPages}
                  currentPageNumber={page}
                  onPageChange={newPage => setCurrentPage(newPage + 1)}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    position: 'sticky',
    top: 50,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 40px 0px 20px',
  },
  catalogContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 150px)',
  },
  sidePanel: {
    position: 'sticky',
    top: 20,
    height: 'calc(100vh - 150px)',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 150px)',
  },
  productSection: {
    background: backgroundGrey5,
  },
});

export { DEFAULT_ALL_CATEGORY_OPTION };
export default UPBViewer;
