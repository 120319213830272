import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Button, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import {
  NUTRITIONAL_PANELS,
  NUTRITIONAL_PANELS_SEND_PREPROCESSED_FILE,
} from 'lib/networking/endpoints';
import NutritionalPanelUploadModal from 'components/nutritional_panels/NutritionalPanelUploadModal';
import { OCRStatus, OCRStatusLabels } from 'lib/enums/OCRStatus';
import PaginationComponent from 'components/shared/PaginationComponent';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function NutritionalPanelUploadView() {
  const history = useHistory();
  const [showPanelUploadModal, setShowPanelUploadModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const {
    data: { nutritional_panels, count } = {},
    loading,
    refetch,
  } = useGet(NUTRITIONAL_PANELS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  const { postData: sendPreprocessedFile, loading: sendingPreprocessedFile } = usePost(
    NUTRITIONAL_PANELS_SEND_PREPROCESSED_FILE,
    () => {
      SwalDialog(
        'success',
        'Successfully sent preprocessed file for data import',
        'Success',
        'center',
      );
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred while sending the file: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Upload Nutritional Panels</h3>
        <Button
          className="mr-3 px-4"
          variant="outline-primary"
          onClick={() => setShowPanelUploadModal(true)}
        >
          Upload
        </Button>
      </div>
      <Row>
        <div className="w-100 d-flex justify-content-end px-3 py-3">
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disabled={loading}
          />
        </div>
      </Row>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Name</th>
            <th>Entity Type</th>
            <th>Entity Name</th>
            <th>Input Type</th>
            <th>Upload Status</th>
            <th>OCR Status</th>
            <th># Nutritional Panels</th>
            <th>Date Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {nutritional_panels?.length ? (
            nutritional_panels.map((nutritional_panel, index) => (
              <tr key={index}>
                <td>{nutritional_panel.name}</td>
                <td>{nutritional_panel.entity_type}</td>
                <td>
                  {nutritional_panel.global_manufacturer?.name ||
                    nutritional_panel.global_vendor?.name}
                </td>
                <td>{nutritional_panel.input_type}</td>
                <td>{nutritional_panel.status}</td>
                <td>{OCRStatusLabels[nutritional_panel.ocr_status]}</td>
                <td>{nutritional_panel.panel_count}</td>
                <td>{nutritional_panel.date_created}</td>
                <td>
                  <Button
                    variant="link"
                    className={pointerOnHover}
                    onClick={() =>
                      history.push(`/nutritional-panel-uploads/${nutritional_panel.id}`)
                    }
                  >
                    View
                  </Button>
                  <Button
                    variant="link"
                    className={pointerOnHover}
                    onClick={() =>
                      (window.location.href = nutritional_panel.ocr_processed_file_url)
                    }
                    disabled={!nutritional_panel.ocr_processed_file_url}
                  >
                    {nutritional_panel.ocr_processed_file_url ? 'OCR file' : 'Not Available'}
                  </Button>
                  <Button
                    variant="link"
                    className={pointerOnHover}
                    onClick={() => {
                      sendPreprocessedFile({ upload_id: nutritional_panel.id });
                    }}
                    disabled={sendingPreprocessedFile}
                  >
                    {nutritional_panel.ocr_status === OCRStatus.COMPLETED
                      ? 'Process data import'
                      : null}
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No nutritional panel uploads found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {showPanelUploadModal ? (
        <NutritionalPanelUploadModal
          onHide={() => setShowPanelUploadModal(false)}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky-top',
    zIndex: 1,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

export default NutritionalPanelUploadView;
