import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import usePost from 'lib/hooks/usePost';
import { EXPORT_P0_CD } from 'lib/networking/endpoints';

function P0SyncModal({ onHide }) {
  const { productListId } = useParams();
  const [selectedHours, setSelectedHours] = useState(null);

  const { postData: syncP0 } = usePost(
    EXPORT_P0_CD,
    () =>
      Swal.fire({
        icon: 'success',
        text: 'P0 Attributes Sync Started Successfully',
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  return (
    <Modal animation={false} show={true} onHide={onHide} centered>
      <Modal.Header className="py-2 mb-0" closeButton>
        <h5>P0 Sync With C+D</h5>
      </Modal.Header>
      <Modal.Body className={css(styles.modalText)}>
        <Form.Group>
          <Form.Label>Enter a value to look behind in hours and sync</Form.Label>
          <Form.Control
            value={selectedHours}
            onChange={e => setSelectedHours(e.target.value)}
            placeholder="Example: 1"
          />
        </Form.Group>
        <div className="w-100 text-right">
          <Button
            onClick={() => {
              syncP0({
                product_list_id: productListId,
                look_behind: selectedHours,
              });
              onHide();
            }}
          >
            sync
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalText: {
    fontSize: '0.8rem',
  },
});

P0SyncModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

export default P0SyncModal;
