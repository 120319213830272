import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq, without } from 'lodash';

import { backgroundGrey2, blue, danger, green } from 'lib/css/colors';
import { fsaSettings } from 'images/icons/navbar';
import RadioButton from 'components/shared/RadioButton';

function ManageAttributesViewTableRow({ attribute, selectedIds, setSelectedIds, getUniqueKey }) {
  const attributeName = attribute.global_attribute_name || attribute.attribute_name;

  const toggleCheck = key => {
    const isChecked = selectedIds.includes(key);
    if (isChecked) {
      setSelectedIds([...without(selectedIds, key)]);
    } else {
      setSelectedIds([...uniq([...selectedIds, key])]);
    }
  };

  const handleClick = () => {
    // since some attributes contains `/`
    const encodedAttributeName = encodeURIComponent(attributeName);
    const url = `/manage-attributes/${encodedAttributeName}/${
      attribute.is_global ? 'global-attribute' : 'attribute'
    }/${attribute.id}`;
    window.open(url, '_blank');
  };

  return (
    <tr className="d-flex" key={getUniqueKey(attribute)}>
      <td
        className={classNames(
          'global_attribute_name' in attribute && css(styles.globalRow),
          'col-2 text-break',
        )}
      >
        <div onClick={e => e?.stopPropagation()} className="float-left">
          <RadioButton
            box
            className="align-middle"
            small={true}
            checked={selectedIds.includes(getUniqueKey(attribute))}
            onClick={() => toggleCheck(getUniqueKey(attribute))}
          />
        </div>
        {attribute.is_system_attribute ? (
          <FontAwesomeIcon icon={fsaSettings} className="mr-2" />
        ) : null}
        {`${attributeName} ${
          attribute.is_system_attribute
            ? '(SYSTEM)'
            : attribute.global_attribute_name
            ? '(GLOBAL)'
            : ''
        }`}
      </td>
      <td className="col-1 text-break">{attribute.rosetta_attribute_id}</td>
      <td className="col-1 text-break">
        {attribute.attribute_sub_section.attribute_section.attribute_section_name}
      </td>
      <td className="col-2 text-break">
        {attribute.attribute_sub_section.attribute_sub_section_name}
      </td>
      <td className="col-2 text-break">{attribute.attribute_display_name}</td>
      <td className="col-1 text-break">{attribute.input_type}</td>
      <td className="col-1 text-break">{attribute.mandatory ? 'true' : 'false'}</td>
      <td
        className={classNames(
          'col-1 text-break',
          attribute.visibility ? css(styles.visibilityTrue) : css(styles.visibilityFalse),
        )}
      >
        {attribute.visibility ? 'true' : 'false'}
      </td>
      <td className="justify-content-center col-1 text-break">
        <Button variant="link" className="mr-1" onClick={handleClick}>
          View
        </Button>
      </td>
    </tr>
  );
}

const styles = StyleSheet.create({
  selectedRow: {
    backgroundColor: backgroundGrey2,
  },
  globalRow: {
    color: blue,
  },
  visibilityTrue: {
    color: green,
  },
  visibilityFalse: {
    color: danger,
  },
});

ManageAttributesViewTableRow.propTypes = {
  attribute: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  getUniqueKey: PropTypes.func.isRequired,
};

export default ManageAttributesViewTableRow;
