import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import { Table } from 'react-bootstrap';

import { CONFLICT_TYPES, CUT_DRY_NODE_URL } from 'lib/constants';
import GlobalProductConflictResolveButton from 'components/global_products_conflicts/GlobalProductConflictResolveButton';
import GlobalProductConflictResolveModals from 'components/global_products_conflicts/conflict_resolve_modals/GlobalProductConflictResolveModals';
import TableHeader from 'components/shared/TableHeader';

const searchableColumns = {
  cnd_id: 'Cut+Dry ID',
  name: 'Product Name',
  upc: 'UPC',
  gtin: 'GTIN',
  global_product_id: 'Global Product ID',
};

function GlobalProductsConflictsListTable({
  conflicts,
  onColumnSearch,
  columnSearchTexts,
  refetchConflicts,
}) {
  const [showResolveByUpdateUpcGtinModal, setShowResolveByUpdateUpcGtinModal] = useState(false);
  const [showResolveByMergeModal, setShowResolveByMergeModal] = useState(false);
  const [showResolveByNewGlobalProductModal, setShowResolveByNewGlobalProductModal] =
    useState(false);
  const [selectedConflict, setSelectedConflict] = useState();

  const onClickUpdateGtinUpc = conflict => {
    setSelectedConflict(conflict);
    setShowResolveByUpdateUpcGtinModal(true);
  };

  const onClickMergeDuplicate = conflict => {
    setSelectedConflict(conflict);
    setShowResolveByMergeModal(true);
  };

  const onClickNewGlobalProduct = conflict => {
    setSelectedConflict(conflict);
    setShowResolveByNewGlobalProductModal(true);
  };

  const customStyles = {
    control: (provided, _state) => ({
      ...provided,
      height: '25px',
      minHeight: '20px',
      fontWeight: 'normal',
    }),

    valueContainer: (provided, _state) => ({
      ...provided,
      padding: '0 5px',
    }),

    indicatorsContainer: (provided, _state) => ({
      ...provided,
      height: '25px',
    }),

    dropdownIndicator: (provided, _state) => ({
      ...provided,
      padding: '0',
      width: '15px',
    }),

    clearIndicator: (provided, _state) => ({
      ...provided,
      padding: '0',
      width: '15px',
    }),

    option: (provided, _state) => ({
      ...provided,
      fontWeight: 'normal',
      padding: '5px',
    }),
  };

  return (
    <>
      <Table striped bordered hover responsive className={css(styles.tableText)}>
        <thead>
          <tr>
            {Object.keys(searchableColumns).map(key => (
              <TableHeader
                title={searchableColumns[key]}
                key={key}
                onSearch={searchText => onColumnSearch(key, searchText)}
                value={columnSearchTexts[key] || ''}
              />
            ))}
            <th className="p-1">Global Product Name</th>
            <th className="p-1">Global Product UPC</th>
            <th className="p-1">Global Product GTIN</th>
            <th className="p-1">
              <div className="px-0">
                <div>Reason</div>
                <Select
                  isClearable
                  options={Object.values(CONFLICT_TYPES).map(option => ({
                    value: option,
                    label: option,
                  }))}
                  value={{ value: columnSearchTexts['reason'], label: columnSearchTexts['reason'] }}
                  onChange={option => onColumnSearch('reason', option?.value || null)}
                  styles={customStyles}
                />
              </div>
            </th>
            <th className="p-1">Parent ID</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {conflicts.length ? (
            conflicts.map(conflict => (
              <tr key={conflict.id}>
                <td className="p-1">
                  <a href={CUT_DRY_NODE_URL + conflict.cnd_id} target="_blank" rel="noreferrer">
                    {conflict.cnd_id}
                  </a>
                </td>
                <td className="p-1">{conflict.name}</td>
                <td className={classNames(css(styles.numbersText), 'p-1')}>{conflict.upc}</td>
                <td className={classNames(css(styles.numbersText), 'p-1')}>{conflict.gtin}</td>
                <td className="p-1">
                  <a href={`/global-products/${conflict.global_product.id}`}>
                    {conflict.global_product.id}
                  </a>
                </td>
                <td className="p-1">{conflict.global_product.name}</td>
                <td className={classNames(css(styles.numbersText), 'p-1')}>
                  {conflict.global_product.upc}
                </td>
                <td className={classNames(css(styles.numbersText), 'p-1')}>
                  {conflict.global_product.gtin}
                </td>
                <td className="p-1">{conflict.reason}</td>
                <td className="p-1">
                  <a
                    href={CUT_DRY_NODE_URL + conflict.parent_cnd_id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {conflict.parent_cnd_id}
                  </a>
                </td>
                <td className="p-1">
                  <GlobalProductConflictResolveButton
                    conflict={conflict}
                    onClickMergeDuplicate={onClickMergeDuplicate}
                    onClickNewGlobalProduct={onClickNewGlobalProduct}
                    onClickUpdateGtinUpc={onClickUpdateGtinUpc}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center font-weight-bold">
                No Global Product Conflicts Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {selectedConflict ? (
        <GlobalProductConflictResolveModals
          conflict={selectedConflict}
          refetchConflicts={refetchConflicts}
          showResolveByUpdateUpcGtinModal={showResolveByUpdateUpcGtinModal}
          showResolveByMergeModal={showResolveByMergeModal}
          showResolveByNewGlobalProductModal={showResolveByNewGlobalProductModal}
          setShowResolveByUpdateUpcGtinModal={setShowResolveByUpdateUpcGtinModal}
          setShowResolveByMergeModal={setShowResolveByMergeModal}
          setShowResolveByNewGlobalProductModal={setShowResolveByNewGlobalProductModal}
        />
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.65rem',
  },
  numbersText: {
    maxWidth: 80,
  },
});

GlobalProductsConflictsListTable.propTypes = {
  conflicts: PropTypes.array,
  onColumnSearch: PropTypes.func,
  columnSearchTexts: PropTypes.object,
  refetchConflicts: PropTypes.func,
};

export default GlobalProductsConflictsListTable;
