import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { Table } from 'react-bootstrap';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import pointerOnHover from 'lib/css/pointerOnHover';

import DataSourceRunURLField from 'components/data_sources/single_data_source_view/DataSourceRunURLField';

function SingleDataSourceRunDataTable({ dataSourceRuns }) {
  return (
    <Container>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Date Modified</th>
            <th>Rows Processed</th>
            <th>Rows Rejected</th>
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {dataSourceRuns?.length ? (
            dataSourceRuns?.map((dataSourceRun, index) => (
              <tr key={index}>
                <td>{dataSourceRun.date_modified}</td>
                <td>{dataSourceRun.run_metadata?.processed_count}</td>
                <td>{dataSourceRun.run_metadata?.error_count}</td>
                <td>
                  <Button
                    variant="link"
                    key={dataSourceRun?.id}
                    className={pointerOnHover}
                    onClick={() => (window.location.href = dataSourceRun.error_report_url)}
                    disabled={!dataSourceRun.error_report_url}
                  >
                    {dataSourceRun.error_report_url ? 'Download Error File' : 'Not Available'}
                  </Button>
                  <DataSourceRunURLField dataSourceRunId={dataSourceRun.id} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No previous data source runs found for this data source.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

SingleDataSourceRunDataTable.propTypes = {
  dataSourceRuns: PropTypes.array,
};

export default SingleDataSourceRunDataTable;
