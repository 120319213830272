import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { squidInk } from 'lib/css/colors';
import { PRODUCT_DETAIL_VIEW_TABS } from 'lib/constants';
import { ProductType } from 'lib/enums/ProductType';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function ProductHeaderSection({ product, productType }) {
  const hasUserPermissions = useUserPermissions();
  // eslint-disable-next-line max-len
  const productEditLink = `/global-product-detail/${product.global_product_id}/${product.id}/${productType}`;
  const editLinkWithTab = productEditLink + `?tab=${PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES}`;
  const canEditProduct = useMemo(
    () => hasUserPermissions([UserPermission.EDIT_PRODUCT]),
    [hasUserPermissions],
  );
  const SKU = product.manufacturer_sku || product.vendor_sku;

  // Conditionally include SKU in subtitleText only if productType is not "global"
  const subtitleTextParts = [product.pack_size];
  if (productType !== ProductType.GLOBAL && SKU) {
    subtitleTextParts.push(`SKU# ${SKU}`);
  }

  const subtitleText = subtitleTextParts.filter(item => item).join(' | ');

  return (
    <>
      <div className={css(styles.productName)}>{product.name}</div>
      <div className="text-muted">
        {product.brand_name}
        {product.manufacturer_name && <span className="ml-1">by {product.manufacturer_name}</span>}
      </div>
      <div className="text-muted  mb-2">{subtitleText}</div>
      {canEditProduct && productType !== ProductType.GLOBAL && (
        <div>
          <Button
            variant="outline-primary"
            target="_blank"
            className={css(styles.editButton)}
            href={editLinkWithTab}
          >
            <FontAwesomeIcon icon={faEdit} className={css(styles.editIcon)} />
            Edit
          </Button>
        </div>
      )}
    </>
  );
}

ProductHeaderSection.propTypes = {
  product: PropTypes.object,
  productType: PropTypes.string,
};

const styles = StyleSheet.create({
  productName: {
    fontSize: 28,
    color: squidInk,
    fontWeight: 800,
  },
  editButton: {
    borderColor: squidInk,
    color: squidInk,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 24,
    paddingLeft: 30,
    paddingRight: 30,
  },
  editIcon: {
    marginRight: 8,
  },
});

export default ProductHeaderSection;
