import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { compact, uniq } from 'lodash';
import Select from 'react-select/creatable';
import Swal from 'sweetalert2';

import { CONFLICT_TYPES } from 'lib/constants';
import {
  getURL,
  GLOBAL_PRODUCTS_BY_IDS,
  GLOBAL_PRODUCT_MERGE_DUPLICATES,
  RESOLVE_CONFLICT_BY_MERGE,
} from 'lib/networking/endpoints';
import UpcGtinExistErrorHandleModal from 'components/global_products_conflicts/conflict_resolve_modals/UpcGtinExistErrorHandleModal';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const optionMapper = option => ({ label: option, value: option });

function MergeGlobalProductsModal({
  conflict,
  globalProduct,
  duplicateGlobalProductIDs,
  show,
  onHide,
  refetch,
}) {
  const [upc, setUpc] = useState();
  const [gtin, setGtin] = useState();
  const [name, setName] = useState(globalProduct.name);
  const [duplicateIds, setDuplicateIds] = useState(duplicateGlobalProductIDs);
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [errorResponse, setErrorResponse] = useState();

  const { data: { data: duplicateGlobalProducts = [] } = {} } = useGet(
    GLOBAL_PRODUCTS_BY_IDS +
      '?' +
      new URLSearchParams({ global_product_ids: duplicateIds }).toString(),
    {},
    !duplicateIds.length,
  );

  const possibleNames = compact([
    conflict?.name,
    globalProduct.name,
    ...duplicateGlobalProducts.map(item => item.name),
  ]);

  const possibleUPCs = compact([
    conflict?.upc,
    globalProduct.upc,
    ...duplicateGlobalProducts.map(item => item.upc),
  ]);
  const possibleGTINs = compact([
    conflict?.gtin,
    globalProduct.gtin,
    ...duplicateGlobalProducts.map(item => item.gtin),
  ]);

  useEffect(() => {
    if (!conflict) {
      return;
    }
    if (conflict.reason.includes(CONFLICT_TYPES.upcDuplicate)) {
      setUpc(conflict.upc);
    } else if (conflict.reason.includes(CONFLICT_TYPES.gtinDuplicate)) {
      setGtin(conflict.gtin);
    }
  }, [conflict]);

  const onSuccess = () => {
    Swal.fire({
      icon: 'success',
      text: 'Successfully merged the global products',
      title: 'Success',
      position: 'center',
    }).then(() => {
      refetch();
      onHide();
    });
  };

  const onError = error => {
    const res = error?.response?.data;
    if (res?.product_id) {
      setErrorResponse(res);
      setShowDialogModal(true);
    } else {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while resolving the conflicts',
        title: 'Error',
        position: 'center',
      });
    }
  };

  const { postData: resolveConflictByMerge, loadingResolveByMerge } = usePost(
    getURL(RESOLVE_CONFLICT_BY_MERGE, { conflictId: conflict?.id }),
    onSuccess,
    onError,
  );

  const { postData: mergeDuplicates, loadingMergeDuplicates } = usePost(
    getURL(GLOBAL_PRODUCT_MERGE_DUPLICATES, { productId: globalProduct.id }),
    onSuccess,
    onError,
  );

  const onSubmit = () => {
    let warningMessage;
    if (!gtin && !upc) {
      warningMessage = 'Are you sure you want to submit without a UPC and a GTIN value?';
    } else if (!gtin) {
      warningMessage = 'Are you sure you want to submit without a GTIN value?';
    } else if (!upc) {
      warningMessage = 'Are you sure you want to submit without a UPC value?';
    }
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: warningMessage,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(res => {
      if (res.isConfirmed) {
        if (conflict) {
          resolveConflictByMerge({
            name,
            gtin,
            upc,
            duplicate_global_product_ids: duplicateIds,
          });
        } else {
          mergeDuplicates({
            name,
            gtin,
            upc,
            duplicate_ids: duplicateIds,
          });
        }
      }
    });
  };

  return (
    <>
      <Modal centered show={show && !showDialogModal} onHide={onHide} animation={false}>
        <Modal.Header>
          <h4>Resolve Conflict by Merging Global Products</h4>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Form.Group>
              <Form.Label className="pr-3">Global Product ID</Form.Label>
              <a href={`/global-products/${globalProduct.id}`}>{globalProduct.id}</a>
            </Form.Group>
            <Form.Group>
              <Form.Label className="pr-3">Duplicate Global Product IDs</Form.Label>
              {duplicateGlobalProductIDs.map(id => (
                <a href={`/global-products/${id}`} key={id}>
                  {id}
                </a>
              ))}
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Select
                value={optionMapper(name)}
                options={uniq(possibleNames).map(optionMapper)}
                onChange={option => {
                  let v;
                  if (option.__isNew__) {
                    v = option.value;
                  } else {
                    v = possibleNames.find(v => v === option.value);
                  }
                  setName(v);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>UPC</Form.Label>
              <Select
                isDisabled={conflict && conflict.reason.includes(CONFLICT_TYPES.upcDuplicate)}
                value={optionMapper(upc)}
                options={uniq(possibleUPCs).map(optionMapper)}
                onChange={option => {
                  let v;
                  if (option.__isNew__) {
                    v = option.value;
                  } else {
                    v = possibleUPCs.find(v => v === option.value);
                  }
                  setUpc(v);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>GTIN</Form.Label>
              <Select
                isDisabled={conflict && conflict.reason.includes(CONFLICT_TYPES.gtinDuplicate)}
                value={optionMapper(gtin)}
                options={uniq(possibleGTINs).map(optionMapper)}
                onChange={option => {
                  let v;
                  if (option.__isNew__) {
                    v = option.value;
                  } else {
                    v = possibleGTINs.find(v => v === option.value);
                  }
                  setGtin(v);
                }}
              />
            </Form.Group>
            <Button block type="submit" disabled={loadingResolveByMerge || loadingMergeDuplicates}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {errorResponse ? (
        <UpcGtinExistErrorHandleModal
          show={showDialogModal}
          onHide={() => setShowDialogModal(false)}
          errorResponse={errorResponse}
          onClickMerge={() => {
            setDuplicateIds(duplicateIds => [...duplicateIds, errorResponse.product_id.toString()]);
            setShowDialogModal(false);
          }}
        />
      ) : null}
    </>
  );
}

MergeGlobalProductsModal.propTypes = {
  conflict: PropTypes.object,
  globalProduct: PropTypes.object.isRequired,
  duplicateGlobalProductIDs: PropTypes.array,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default MergeGlobalProductsModal;
