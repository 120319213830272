import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from 'use-debounce';

import { BRANDS_FOR_DROPDOWN, getURL } from 'lib/networking/endpoints';
import CheckboxFiltersGroup from 'components/upb_viewer/CheckboxFiltersGroup';
import useGet from 'lib/hooks/useGet';

const BRAND_LOAD_LIMIT = 50;

function BrandFilterGroup({ selectedBrands, setSelectedBrands }) {
  const [searchText, setSearchText] = useState('');
  const [debouncedQuery] = useDebounce(searchText, 600);
  const { data: { data: brands = [] } = {}, loading } = useGet(getURL(BRANDS_FOR_DROPDOWN, {}), {
    search: debouncedQuery,
    limit: BRAND_LOAD_LIMIT,
    verified_only: true,
  });

  const brandOptions = useMemo(() => {
    if (!brands?.length) {
      return [];
    }
    const brandOptions = brands
      .map(brand => ({
        label: brand.name,
        value: brand.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    // append selected brands to the top of the list
    const selectedBrandIds = selectedBrands.map(brand => brand.value);
    const selectedBrandsOptions = brandOptions.filter(option =>
      selectedBrandIds.includes(option.value),
    );
    const unselectedBrandsOptions = brandOptions.filter(
      option => !selectedBrandIds.includes(option.value),
    );
    brandOptions.length = 0;
    brandOptions.push(...selectedBrandsOptions, ...unselectedBrandsOptions);
    return brandOptions;
  }, [brands, selectedBrands]);

  return (
    <CheckboxFiltersGroup
      options={brandOptions}
      selectedOptions={selectedBrands}
      setSelectedOptions={setSelectedBrands}
      searchText={searchText}
      setSearchText={setSearchText}
      searchable
      placeholder={'Search Brands...'}
      isLoading={loading}
    />
  );
}

BrandFilterGroup.propTypes = {
  selectedBrands: PropTypes.arrayOf(PropTypes.object),
  setSelectedBrands: PropTypes.func.isRequired,
};

export default BrandFilterGroup;
