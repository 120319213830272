import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import RadioButton from 'components/shared/RadioButton';
import { grape } from 'lib/css/colors';

function SelectedAttributesSplitViewRow({ eventHandler, objectValue, handleUpdate }) {
  return (
    <Row>
      <Col sm="5">
        <RadioButton
          box
          className="ml-2 mt-1"
          checked={true}
          small
          onClick={() => eventHandler(objectValue)}
          label={objectValue.attributeName}
        />
      </Col>
      <Col sm="5">
        <Form.Control
          placeholder="canonical_products"
          type="text"
          value={objectValue.columnName}
          onChange={e => handleUpdate(objectValue, e.target.value, objectValue.isRequired)}
        />
      </Col>
      <Col sm="2">
        <ToggleSwitch
          checked={objectValue.isRequired}
          onColor={grape}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() =>
            handleUpdate(objectValue, objectValue.columnName, !objectValue.isRequired)
          }
          className="ml-3"
        />
      </Col>
    </Row>
  );
}

SelectedAttributesSplitViewRow.propTypes = {
  eventHandler: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  objectValue: PropTypes.object.isRequired,
};

export default SelectedAttributesSplitViewRow;
