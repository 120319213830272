import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet } from 'aphrodite';

import CheckboxFilterItem from 'components/upb_viewer/CheckboxFilterItem';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SearchBar from 'components/shared/SearchBar';

const getOptionIdsSet = (options, maxItems) => {
  if (!options) return;
  const optionIds = options.map(option => option.value);
  return new Set(maxItems ? optionIds.splice(0, maxItems) : optionIds);
};

function CheckboxFiltersGroup({
  className = '',
  options,
  selectedOptions,
  setSelectedOptions,
  searchText,
  setSearchText,
  placeholder = 'Search...',
  searchable = false,
  isLoading = false,
}) {
  const checkedOptionIds = useMemo(() => getOptionIdsSet(selectedOptions), [selectedOptions]);

  const filteredOptions = useMemo(() => {
    if (searchable && searchText) {
      return options.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase()),
      );
    }
    return options;
  }, [options, searchable, searchText]);

  return (
    <div className={className}>
      {searchable && (
        <SearchBar value={searchText} setValue={setSearchText} placeholder={placeholder} />
      )}
      <>
        {isLoading && <LoadingSpinner short />}
        {searchable && filteredOptions.length === 0 && !isLoading && 'No results found'}
        {!isLoading &&
          filteredOptions.map(option => (
            <div key={option.value}>
              <CheckboxFilterItem
                title={option.label}
                checked={checkedOptionIds?.has(option.value)}
                handleOnCheck={checked => {
                  const newSelectedOptions = selectedOptions?.filter(
                    op => op.value !== option.value,
                  );
                  if (newSelectedOptions && checked) {
                    newSelectedOptions.push(option);
                  }
                  setSelectedOptions(newSelectedOptions || []);
                }}
              />
            </div>
          ))}
      </>
    </div>
  );
}

const styles = StyleSheet.create({
  checkBoxes: {
    paddingRight: 5,
    paddingLeft: 10,
  },
});

CheckboxFiltersGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  setSelectedOptions: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default CheckboxFiltersGroup;
