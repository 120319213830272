import React from 'react';
import PropTypes from 'prop-types';

import { Col, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { useHistory } from 'react-router-dom';

import { categoryStyles } from 'lib/css/categoryTexts';
import pointerOnHover from 'lib/css/pointerOnHover';

function GlobalProductsCategoryConflictsTable({ globalProducts }) {
  const history = useHistory();

  return (
    <Table striped bordered hover responsive className={css(styles.tableText)}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Assigned Category</th>
        </tr>
      </thead>
      <tbody>
        {globalProducts.length ? (
          globalProducts.map(product => (
            <tr
              key={product.id}
              className={pointerOnHover}
              onClick={() => history.push(`/global-products-category-conflicts/${product.id}`)}
            >
              <td>{product.id}</td>
              <td className={css(styles.productName)}>{product.name}</td>
              <td className="d-flex px-0">
                <Col className={css(categoryStyles.l1_category)}>
                  {product.category?.l1_category}
                </Col>
                <Col className={css(categoryStyles.l2_category)}>
                  {product.category?.l2_category}
                </Col>
                <Col className={css(categoryStyles.l3_category)}>
                  {product.category?.l3_category}
                </Col>
                <Col className={css(categoryStyles.l4_category)}>
                  {product.category?.l4_category}
                </Col>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="100%" className="text-center font-weight-bold">
              No Global Products Found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.75rem',
  },
  productName: {
    minWidth: 300,
  },
});

GlobalProductsCategoryConflictsTable.propTypes = {
  globalProducts: PropTypes.array,
};

export default GlobalProductsCategoryConflictsTable;
