import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { anchovy, grape } from 'lib/css/colors';
import { convertToTitleCase } from 'lib/utils/stringUtils';
import EditableProperty from 'components/manage_vendors/vendor_details/EditableProperty';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import { GLOBAL_VENDORS_UPDATE } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function VendorRow({ label, title, vendor, refetch }) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedVendor, setUpdatedVendor] = useState({ id: vendor.id });

  const { postData: updateVendor, loading } = usePost(
    GLOBAL_VENDORS_UPDATE,
    () => {
      SwalDialog('success', 'Successfully updated vendor', 'Success', 'center', refetch());
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while updating the vendor: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <div className={css(styles.container)}>
      <p className={css(styles.label)}>{title}</p>
      {[
        'cnd_id',
        'name',
        'vendor_url',
        'excluded_p0_sync_attributes',
        'excluded_cnd_sync_fields',
        'rejected_brand_names',
        'chains',
        'vendor_category',
      ].includes(label) ? (
        isEditing ? (
          <Form onBlur={() => setIsEditing(false)} className={css(styles.row)}>
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faSquareCheck}
              onMouseDown={() => {
                updateVendor(updatedVendor);
                setIsEditing(false);
              }}
              size="lg"
            />
            <EditableProperty
              label={label}
              vendor={vendor}
              setIsEditing={setIsEditing}
              setUpdatedVendor={setUpdatedVendor}
            />
          </Form>
        ) : (
          <div className={css(styles.row)}>
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faPen}
              onClick={() => {
                setIsEditing(true);
              }}
            />
            {label === 'excluded_p0_sync_attributes' ? (
              <div>
                {vendor[label] ? (
                  vendor[label]
                    .split(',')
                    .map((phrase, index) => <p key={index}>&bull; {phrase}</p>)
                ) : (
                  <p>-</p>
                )}
              </div>
            ) : label === 'rejected_brand_names' ? (
              <div>
                {vendor[label] ? (
                  vendor[label].map((phrase, index) => <p key={index}>&bull; {phrase}</p>)
                ) : (
                  <p>-</p>
                )}
              </div>
            ) : label === 'chains' ? (
              <div>
                {vendor[label] ? (
                  vendor[label].map((phrase, index) => <p key={index}>&bull; {phrase.name}</p>)
                ) : (
                  <p>-</p>
                )}
              </div>
            ) : label === 'vendor_category' ? (
              <div>
                <p>{convertToTitleCase(vendor[label]) || '-'}</p>
              </div>
            ) : (
              <p>{vendor[label] || '-'}</p>
            )}
          </div>
        )
      ) : (
        <div>
          <ToggleSwitch
            onColor={grape}
            checked={vendor[label]}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() =>
              updateVendor({
                id: vendor.id,
                [label]: !vendor[label],
              })
            }
          />
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  editIcon: {
    marginRight: '0.75rem',
  },
});

VendorRow.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  vendor: PropTypes.object,
  refetch: PropTypes.func,
};

export default VendorRow;
