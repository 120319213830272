import React from 'react';
import PropTypes from 'prop-types';

import NestedFilterItem from 'components/upb_viewer/NestedFilterItem';

function NestedFiltersGroup({
  options,
  selectedOptions,
  setSelectedOptions,
  autoCollapseWhenInactive = false,
}) {
  return (
    <>
      {options?.map(option => (
        <NestedFilterItem
          key={option.value}
          option={option}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          isRootNode
          autoCollapseWhenInactive={autoCollapseWhenInactive}
        />
      ))}
    </>
  );
}

NestedFiltersGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  setSelectedOptions: PropTypes.func.isRequired,
  autoCollapseWhenInactive: PropTypes.bool,
};

export default NestedFiltersGroup;
