import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';

import { anchovy, colorStyles } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { PUBLIC_UPLOAD_FILE, UPLOAD_FILE } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function DropZone({
  text,
  onUploadSuccess,
  filePath,
  fileUrls = [],
  onFilesDrop = () => {},
  acceptedFileTypes,
  isImage = false,
  isMulti = true,
  customResponse = false,
  isPublic = false,
  returnFileName = false,
}) {
  const [loadingImage, setLoadingImage] = useState(false);
  const { postData: uploadFile } = usePost(isPublic ? PUBLIC_UPLOAD_FILE : UPLOAD_FILE);
  const onDrop = useCallback(
    acceptedFiles => {
      onFilesDrop(acceptedFiles);
      setLoadingImage(true);
      let num_files = acceptedFiles.length;
      let upload_tasks = acceptedFiles.map(file => {
        const newFile = new File([file], escape(file.name), { type: file.type });
        const formData = new FormData();
        formData.append('file', newFile);
        if (filePath) {
          formData.append('file_path', filePath);
        }

        return uploadFile(formData).then(response => {
          num_files--;
          if (num_files === 0) {
            setLoadingImage(false);
          }
          if (isPublic) {
            if (returnFileName) {
              return { [response.data.filename]: response.data.url };
            }
            return response.data.url;
          } else {
            return customResponse ? response : response.data;
          }
        });
      });
      Promise.all(upload_tasks).then(file_data => {
        // {"presigned_url: "https://s3.amazonaws.com/...", "s3_path": "/bucket/s3/path"}
        // or {"url: " "https://s3.amazonaws.com/..."} if using public facing bucket
        onUploadSuccess(file_data);
      });
    },
    [uploadFile, onFilesDrop, onUploadSuccess, customResponse, isPublic, filePath, returnFileName],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes ? acceptedFileTypes : '',
    multiple: isMulti,
  });

  if (loadingImage) {
    return <LoadingSpinner short={true} />;
  }

  return (
    <div {...getRootProps()} className={classNames(css(styles.dropzoneBox), 'rounded text-center')}>
      <input {...getInputProps()} />
      <div>
        {isImage && fileUrls.length > 0 ? (
          fileUrls.map((fileUrl, index) => {
            const url = typeof fileUrl === 'object' ? Object.values(fileUrl)[0] : fileUrl;
            return (
              <div key={index} className={css(styles.imageContainer)}>
                <img
                  src={url}
                  className={classNames('m-sm', css(styles.image))}
                  alt="uploaded thumbnail"
                />
              </div>
            );
          })
        ) : fileUrls.length > 0 ? (
          <div>
            <FontAwesomeIcon color={anchovy} icon={faCloudUploadAlt} />
            <span>{`${fileUrls.length} file${fileUrls.length === 1 ? '' : 's'} uploaded`}</span>
          </div>
        ) : (
          <div>
            <div>
              <FontAwesomeIcon color={anchovy} icon={faCloudUploadAlt} />
            </div>
            <span className={css(colorStyles.anchovy)}>{text}</span>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  dropzoneBox: {
    background: 'white',
    border: `2px dashed ${anchovy}`,
    cursor: 'pointer',
    minHeight: '150px',
    padding: '40px 0px',
  },
  image: {
    height: '250px',
    marginRight: '30px',
  },
  imageContainer: {
    display: 'inline-block',
  },
});

DropZone.propTypes = {
  text: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  fileUrls: PropTypes.array,
  base64Form: PropTypes.bool,
  ajaxController: PropTypes.string,
  onFilesDrop: PropTypes.func,
  acceptedFileTypes: PropTypes.string,
  isImage: PropTypes.bool,
  isMulti: PropTypes.bool,
  customResponse: PropTypes.bool,
  isPublic: PropTypes.bool,
  filePath: PropTypes.string,
  returnFileName: PropTypes.bool,
};

export default DropZone;
