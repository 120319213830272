import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getURL, BRANDS_GET_BY_NAME, BRANDS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function BrandFilter({ brandName, setAttributeValue, isGlobalProductAttribute }) {
  const [initOption, setInitOption] = useState({});
  // initial load
  useEffect(() => {
    if (brandName) {
      const url = getURL(BRANDS_GET_BY_NAME, { brand_name: brandName });
      httpGet(url).then(res => {
        if (res.status === 200) {
          const brand = res.data?.brand;
          if (brand) {
            setInitOption({ label: brand.name, value: brand.id });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableScrollableSelect
      disabled={isGlobalProductAttribute}
      initOption={initOption}
      onChange={option => setAttributeValue(option?.label)}
      fetchUrl={BRANDS_FOR_DROPDOWN}
      optionMapper={brand => ({ label: brand.name, value: brand.id })}
    />
  );
}

BrandFilter.propTypes = {
  brandName: PropTypes.string,
  setAttributeValue: PropTypes.func,
  isGlobalProductAttribute: PropTypes.bool,
};

export default BrandFilter;
