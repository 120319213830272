// update along with app/lib/enums/product_list_filter.py
const ProductListFilter = {
  MANUFACTURER_IDS: 'manufacturer_ids',
  DISTRIBUTOR_IDS: 'distributor_ids',
  BRAND_IDS: 'brand_ids',
  STATUS: 'status',
  CND_SYNC_STATUS: 'cnd_sync_status',
};

export default ProductListFilter;
