import React from 'react';
import PropTypes from 'prop-types';

import { coolGrey } from 'lib/css/colors';
import { css, StyleSheet } from 'aphrodite';

import ProductNutritionFactsView from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';

function NutritionFactsSection({ nutritionAttributes }) {
  if (
    nutritionAttributes === undefined ||
    nutritionAttributes === null ||
    nutritionAttributes.length === 0
  ) {
    return null;
  }

  return (
    <div className="my-4">
      <div className={css(styles.header)}>Nutrition Facts</div>
      <div className={css(styles.content)}>
        <ProductNutritionFactsView nutritionAttributes={nutritionAttributes} />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    fontSize: 21,
    color: coolGrey,
    fontWeight: 700,
    marginBottom: 10,
  },
  content: {
    maxWidth: 400,
  },
});

NutritionFactsSection.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default NutritionFactsSection;
