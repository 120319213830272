import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { auroMetalSaurus, backgroundDarkGrey } from 'lib/css/colors';

function TableViewSection({ attributes = [] }) {
  if (attributes.length === 0) {
    return null;
  }
  return (
    <div className="my-4">
      <div className="py-3">
        {attributes.map((attribute, index) => (
          <Row
            key={attribute.display_name + index}
            className={css(styles.rowStyle, index % 2 === 0 && styles.greyBackground)}
          >
            <Col className={classNames('col-5', css(styles.auroMetalSaurus))}>
              <div className="d-flex align-self-center">{attribute.display_name}</div>
            </Col>
            <Col>
              <div className="d-flex align-self-center flex-column">
                {attribute.attribute_value}
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  rowStyle: {
    marginLeft: -6,
    marginRight: 0,
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '6px',
  },
  auroMetalSaurus: {
    color: auroMetalSaurus,
  },
  greyBackground: {
    backgroundColor: backgroundDarkGrey,
  },
});

TableViewSection.propTypes = {
  attributes: PropTypes.array,
};

export default TableViewSection;
