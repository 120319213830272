import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import CaloriesSection from 'components/shared/nutrition_facts/CaloriesSection';
import CarbohydratesSection from 'components/shared/nutrition_facts/CarbohydratesSection';
import FatSection from 'components/shared/nutrition_facts/FatSection';
import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';
import MainNutritionRow from 'components/shared/nutrition_facts/MainNutritionRow';
import Separator, {
  MEDIUM_SEPARATOR_THICKNESS,
  LARGE_SEPARATOR_THICKNESS,
} from 'components/shared/nutrition_facts/Separator';
import ServingsSection from 'components/shared/nutrition_facts/ServingsSection';
import SubNutritionRow from 'components/shared/nutrition_facts/SubNutritionRow';
import useFindAttributeValueByName from 'lib/hooks/product/useFindAttributeValueByName';

function ProductNutritionFactsView({ nutritionAttributes }) {
  const servingSizeQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'serving_size_quantity',
  );
  const servingSizeUom = useFindAttributeValueByName(nutritionAttributes, 'serving_size_uom');
  const servingSizeDescription = useFindAttributeValueByName(
    nutritionAttributes,
    'serving_size_description',
  );
  const servingsPerPackage = useFindAttributeValueByName(
    nutritionAttributes,
    'number_of_servings_per_package',
  );
  const calories = useFindAttributeValueByName(nutritionAttributes, 'calories_quantitycontained');

  const cholesterolQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'cholesterol_quantitycontained',
  );
  const cholesterolUom = useFindAttributeValueByName(nutritionAttributes, 'cholesterol_uom');
  const cholesterolDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'cholesterol_rdi_dailyvalueintakepercent',
  );

  const sodiumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'sodium_quantitycontained',
  );
  const sodiumUom = useFindAttributeValueByName(nutritionAttributes, 'sodium_uom');
  const sodiumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'protein_quantitycontained',
  );

  const proteinQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'protein_quantitycontained',
  );
  const proteinUom = useFindAttributeValueByName(nutritionAttributes, 'protein_uom');
  const proteinDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'protein_rdi_dailyvalueintakepercent',
  );
  const vitaminDQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_d_quantitycontained',
  );
  const vitaminDUom = useFindAttributeValueByName(nutritionAttributes, 'vitamin_d_uom');
  const vitaminDDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_d_rdi_dailyvalueintakepercent',
  );

  const calciumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'calcium_quantitycontained',
  );
  const calciumUom = useFindAttributeValueByName(nutritionAttributes, 'calcium_uom');
  const calciumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'calcium_rdi_dailyvalueintakepercent',
  );

  const ironQuantity = useFindAttributeValueByName(nutritionAttributes, 'iron_quantitycontained');
  const ironUom = useFindAttributeValueByName(nutritionAttributes, 'iron_uom');
  const ironDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'iron_rdi_dailyvalueintakepercent',
  );

  const potassiumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'potassium_quantitycontained',
  );
  const potassiumUom = useFindAttributeValueByName(nutritionAttributes, 'potassium_uom');
  const potassiumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'potassium_rdi_dailyvalueintakepercent',
  );

  const vitaminAQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_a_quantitycontained',
  );
  const vitaminAUom = useFindAttributeValueByName(nutritionAttributes, 'vitamin_a_uom');
  const vitaminADailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_a_rdi_dailyvalueintakepercent',
  );

  const vitaminCQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_c_quantitycontained',
  );
  const vitaminCUom = useFindAttributeValueByName(nutritionAttributes, 'vitamin_c_uom');
  const vitaminCDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_c_rdi_dailyvalueintakepercent',
  );

  const thiaminQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'thiamin_quantitycontained',
  );
  const thiaminUom = useFindAttributeValueByName(nutritionAttributes, 'thiamin_uom');
  const thiaminDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'thiamin_rdi_dailyvalueintakepercent',
  );

  const riboflavinQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'riboflavin_b2_quantitycontained',
  );
  const riboflavinUom = useFindAttributeValueByName(nutritionAttributes, 'riboflavin_b2_uom');
  const riboflavinDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'riboflavin_rdi_dailyvalueintakepercent',
  );

  const niacinQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'niacin_quantitycontained',
  );
  const niacinUom = useFindAttributeValueByName(nutritionAttributes, 'niacin_uom');
  const niacinDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'niacin_rdi_dailyvalueintakepercent',
  );

  const vitaminB6Quantity = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_b6_quantitycontained',
  );
  const vitaminB6Uom = useFindAttributeValueByName(nutritionAttributes, 'vitamin_b6_uom');
  const vitaminB6DailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_b6_rdi_dailyvalueintakepercent',
  );

  const folateQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'folate_quantitycontained',
  );
  const folateUom = useFindAttributeValueByName(nutritionAttributes, 'folate_uom');
  const folateDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'total_folate_dv_rdi_dailyvalueintakepercent',
  );

  const vitaminB12Quantity = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_b12_quantitycontained',
  );
  const vitaminB12Uom = useFindAttributeValueByName(nutritionAttributes, 'vitamin_b12_uom');
  const vitaminB12DailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'vitamin_b12_rdi_dailyvalueintakepercent',
  );

  const phosphorousQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'phosphorous_quantitycontained',
  );
  const phosphorousUom = useFindAttributeValueByName(nutritionAttributes, 'phosphorous_uom');
  const phosphorousDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'phosphorous_rdi_dailyvalueintakepercent',
  );

  const magnesiumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'magnesium_quantitycontained',
  );
  const magnesiumUom = useFindAttributeValueByName(nutritionAttributes, 'magnesium_uom');
  const magnesiumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'magnesium_dv_rdi_dailyvalueintakepercent',
  );

  const zincQuantity = useFindAttributeValueByName(nutritionAttributes, 'zinc_quantitycontained');
  const zincUom = useFindAttributeValueByName(nutritionAttributes, 'zinc_uom');
  const zincDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'zinc_dv_rdi_dailyvalueintakepercent',
  );

  if (!nutritionAttributes?.length) {
    return null;
  }

  return (
    <div className="border border-dark p-2">
      <div className={fontStyles('44px', true, true)}>Nutrition Facts</div>
      <Separator />

      <ServingsSection
        servingsPerPackage={servingsPerPackage}
        description={servingSizeDescription}
        quantity={servingSizeQuantity}
        uom={servingSizeUom}
      />
      <Separator thickness={LARGE_SEPARATOR_THICKNESS} />

      <CaloriesSection calories={calories} />
      <Separator thickness={MEDIUM_SEPARATOR_THICKNESS} />

      <div className={classNames('w-100 text-right', fontStyles('12px', true))}>% Daily Value*</div>
      <Separator />

      <FatSection nutritionAttributes={nutritionAttributes} />

      <MainNutritionRow
        title="Cholesterol"
        quantity={cholesterolQuantity}
        uom={cholesterolUom}
        percentage={cholesterolDailyValue}
      />

      <MainNutritionRow
        title="Sodium"
        quantity={sodiumQuantity}
        uom={sodiumUom}
        percentage={sodiumDailyValue}
      />

      <CarbohydratesSection nutritionAttributes={nutritionAttributes} />

      <MainNutritionRow
        title="Protein"
        quantity={proteinQuantity}
        uom={proteinUom}
        percentage={proteinDailyValue}
      />

      <Separator thickness={LARGE_SEPARATOR_THICKNESS} />

      <SubNutritionRow
        title="Vitamin D"
        quantity={vitaminDQuantity}
        uom={vitaminDUom}
        percentage={vitaminDDailyValue}
      />
      <SubNutritionRow
        title="Calcium"
        quantity={calciumQuantity}
        uom={calciumUom}
        percentage={calciumDailyValue}
      />
      <SubNutritionRow
        title="Iron"
        quantity={ironQuantity}
        uom={ironUom}
        percentage={ironDailyValue}
      />
      <SubNutritionRow
        title="Potassium"
        quantity={potassiumQuantity}
        uom={potassiumUom}
        percentage={potassiumDailyValue}
      />
      <SubNutritionRow
        title="Vitamin A"
        quantity={vitaminAQuantity}
        uom={vitaminAUom}
        percentage={vitaminADailyValue}
      />
      <SubNutritionRow
        title="Vitamin C"
        quantity={vitaminCQuantity}
        uom={vitaminCUom}
        percentage={vitaminCDailyValue}
      />
      <SubNutritionRow
        title="Thiamin"
        quantity={thiaminQuantity}
        uom={thiaminUom}
        percentage={thiaminDailyValue}
      />
      <SubNutritionRow
        title="Riboflavin"
        quantity={riboflavinQuantity}
        uom={riboflavinUom}
        percentage={riboflavinDailyValue}
      />
      <SubNutritionRow
        title="Niacin"
        quantity={niacinQuantity}
        uom={niacinUom}
        percentage={niacinDailyValue}
      />
      <SubNutritionRow
        title={
          <span>
            Vitamin B<sub>6</sub>
          </span>
        }
        quantity={vitaminB6Quantity}
        uom={vitaminB6Uom}
        percentage={vitaminB6DailyValue}
      />
      <SubNutritionRow
        title="Folate"
        quantity={folateQuantity}
        uom={folateUom}
        percentage={folateDailyValue}
      />
      <SubNutritionRow
        title={
          <span>
            Vitamin B<sub>12</sub>
          </span>
        }
        quantity={vitaminB12Quantity}
        uom={vitaminB12Uom}
        percentage={vitaminB12DailyValue}
      />
      <SubNutritionRow
        title="Phosphorous"
        quantity={phosphorousQuantity}
        uom={phosphorousUom}
        percentage={phosphorousDailyValue}
      />
      <SubNutritionRow
        title="Magnesium"
        quantity={magnesiumQuantity}
        uom={magnesiumUom}
        percentage={magnesiumDailyValue}
      />
      <SubNutritionRow
        title="Zinc"
        quantity={zincQuantity}
        uom={zincUom}
        percentage={zincDailyValue}
        skipSeparator
      />
      <Separator thickness={MEDIUM_SEPARATOR_THICKNESS} />

      <div className={classNames(fontStyles('12px'), 'd-flex justify-content-between')}>
        <div className="pr-1">*</div>
        <div>
          The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a
          daily diet. 2,000 calories a day is used for general nutrition advice.
        </div>
      </div>
    </div>
  );
}

ProductNutritionFactsView.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default ProductNutritionFactsView;
