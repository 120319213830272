import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import Select from 'react-select/creatable';

import AttributeInputType from 'lib/enums/AttributeInputType';
import BrandFilter from 'components/global_products/detail_view/BrandFilter';
import ManufacturerFilter from 'components/global_products/detail_view/ManufacturerFilter';

function EditableAttribute({
  attribute,
  setIsEditing,
  setAttributeValue,
  isGlobalProductAttribute = false,
}) {
  if (attribute.attribute_name === 'brand_name') {
    return (
      <BrandFilter
        brandName={attribute.attribute_value}
        setAttributeValue={setAttributeValue}
        isGlobalProductAttribute={isGlobalProductAttribute}
      />
    );
  }

  if (attribute.attribute_name === 'manufacturer_name') {
    return (
      <ManufacturerFilter
        manufacturerName={attribute.attribute_value}
        setAttributeValue={setAttributeValue}
        isGlobalProductAttribute={isGlobalProductAttribute}
      />
    );
  }

  return attribute.input_type === AttributeInputType.LIST ? (
    <Select
      autoFocus
      isDisabled={isGlobalProductAttribute}
      className={css(styles.select)}
      defaultValue={{
        label: attribute.attribute_value,
        value: attribute.attribute_value,
      }}
      options={attribute.attribute_options.map(option => {
        return { label: option, value: option };
      })}
      onChange={e => setAttributeValue(e?.value)}
      isClearable
    />
  ) : attribute.input_type === AttributeInputType.BOOLEAN ? (
    <Select
      autoFocus
      isDisabled={isGlobalProductAttribute}
      className={css(styles.select)}
      defaultValue={{
        label:
          attribute.attribute_value !== null
            ? attribute.attribute_value === '1'
              ? 'true'
              : 'false'
            : null,
        value:
          attribute.attribute_value !== null
            ? attribute.attribute_value === '1'
              ? 'true'
              : 'false'
            : null,
      }}
      options={[
        { label: 'true', value: true },
        { label: 'false', value: false },
      ]}
      onChange={e => {
        setAttributeValue(e.value);
      }}
    />
  ) : (
    <Form.Control
      autoFocus
      type="text"
      disabled={isGlobalProductAttribute}
      defaultValue={attribute.attribute_value}
      onChange={e => setAttributeValue(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          setIsEditing(false);
        }
      }}
    />
  );
}

const styles = StyleSheet.create({
  select: {
    flexGrow: 1,
  },
});

EditableAttribute.propTypes = {
  attribute: PropTypes.object,
  setIsEditing: PropTypes.func,
  setAttributeValue: PropTypes.func,
  isGlobalProductAttribute: PropTypes.bool,
};

export default EditableAttribute;
