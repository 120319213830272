import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import {
  getURL,
  GLOBAL_PRODUCT_RESOLVE_CONFLICTS_BY_UPC_GTIN_UPDATE,
} from 'lib/networking/endpoints';
import MergeGlobalProductsModal from 'components/global_products_conflicts/conflict_resolve_modals/MergeGlobalProductsModal';
import UpcGtinExistErrorHandleModal from 'components/global_products_conflicts/conflict_resolve_modals/UpcGtinExistErrorHandleModal';
import usePost from 'lib/hooks/usePost';

function ResolveByUpdateUpcGtinModal({ product, conflict, show, onHide, refetch }) {
  const [upc, setUpc] = useState(product.upc || '');
  const [gtin, setGtin] = useState(product.gtin || '');
  const [showResolvebyMergeModal, setShowResolvebyMergeModal] = useState(false);
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [errorResponse, setErrorResponse] = useState();

  const { postData: resolveConflicts, loading } = usePost(
    getURL(GLOBAL_PRODUCT_RESOLVE_CONFLICTS_BY_UPC_GTIN_UPDATE, { productId: product.id }),
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully resolved the selected conflicts',
        title: 'Success',
        position: 'center',
      }).then(() => {
        refetch();
        onHide();
      });
    },
    error => {
      const res = error?.response?.data;
      if (res?.product_id) {
        setErrorResponse(res);
        setShowDialogModal(true);
      } else {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while resolving the conflicts',
          title: 'Error',
          position: 'center',
        });
      }
    },
  );

  const onSubmit = () => {
    let warningMessage;
    if (!gtin && !upc) {
      warningMessage = 'Are you sure you want to submit without a UPC and a GTIN value?';
    } else if (!gtin) {
      warningMessage = 'Are you sure you want to submit without a GTIN value?';
    } else if (!upc) {
      warningMessage = 'Are you sure you want to submit without a UPC value?';
    }
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: warningMessage,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(res => {
      if (res.isConfirmed) {
        resolveConflicts({ conflict_id: conflict.id, gtin, upc });
      }
    });
  };

  return (
    <>
      <Modal centered show={show && !showDialogModal} onHide={onHide} animation={false}>
        <Modal.Header>
          <h4>Resolve Conflicts</h4>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Form.Group>
              <Form.Label>UPC</Form.Label>
              <Form.Control onChange={e => setUpc(e.target.value)} value={upc || ''} />
            </Form.Group>
            <Form.Group>
              <Form.Label>GTIN</Form.Label>
              <Form.Control onChange={e => setGtin(e.target.value)} value={gtin || ''} />
            </Form.Group>
            <Button block type="submit" disabled={loading}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {errorResponse ? (
        <>
          <UpcGtinExistErrorHandleModal
            show={showDialogModal}
            onHide={() => setShowDialogModal(false)}
            errorResponse={errorResponse}
            onClickMerge={() => {
              setShowDialogModal(false);
              setShowResolvebyMergeModal(true);
            }}
          />
          <MergeGlobalProductsModal
            conflict={conflict}
            globalProduct={product}
            show={showResolvebyMergeModal}
            duplicateGlobalProductIDs={[errorResponse.product_id]}
            onHide={() => setShowResolvebyMergeModal(false)}
          />
        </>
      ) : null}
    </>
  );
}

ResolveByUpdateUpcGtinModal.propTypes = {
  product: PropTypes.object.isRequired,
  conflict: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default ResolveByUpdateUpcGtinModal;
