import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import AttributesMapTabView from 'components/data_streams/attribute_map_tab/AttributesMapTabView';
import Container from 'components/shared/Container';
import { DATA_STREAM_GET_BY_ID, getURL } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import { SingleDataStreamViewContext } from 'components/data_streams/SingleDataStreamViewContainer';
import useGet from 'lib/hooks/useGet';

const DATA_STREAM_TABS = {
  ATTRIBUTES_MAP: 'Attributes Map',
  SETTINGS: 'Settings',
};

function SingleDataStreamView() {
  const { record_id } = useParams();
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useState(DATA_STREAM_TABS.ATTRIBUTES_MAP);
  const { setSelectedAttributes } = useContext(SingleDataStreamViewContext);

  const {
    data: dataStream,
    loading,
    error,
  } = useGet(getURL(DATA_STREAM_GET_BY_ID, { data_stream_id: record_id }));

  useEffect(() => {
    if (dataStream?.attribute_map) {
      const arr = [];
      for (let key in dataStream.attribute_map) {
        const attr = dataStream.attribute_map[key];
        arr.push({
          uniqKey: `${attr.attribute_id}_${attr.attribute_name}_${attr.is_global_attribute}`,
          id: attr.attribute_id,
          isGlobalAttribute: attr.is_global_attribute,
          attributeName: attr.attribute_name,
          columnName: attr.attribute_name,
          isRequired: attr.required,
        });
      }
      setSelectedAttributes(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStream]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  if (!dataStream) {
    return <h3 className="my-5 text-center">Data Stream not found</h3>;
  }

  return (
    <Container>
      <Row className="mr-4">
        <Col>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
              onClick={() => history.push('/data-streams')}
            />
            {dataStream.stream_name}
          </h3>
        </Col>
      </Row>
      <Tabs
        className="mt-5 border-top-0"
        defaultActiveKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
        transition={false}
        mountOnEnter={true}
      >
        <Tab eventKey={DATA_STREAM_TABS.ATTRIBUTES_MAP} title={DATA_STREAM_TABS.ATTRIBUTES_MAP}>
          <AttributesMapTabView />
        </Tab>
        <Tab eventKey={DATA_STREAM_TABS.SETTINGS} title={DATA_STREAM_TABS.SETTINGS}></Tab>
      </Tabs>
    </Container>
  );
}

SingleDataStreamView.propTypes = {
  dataSource: PropTypes.object,
};

export default SingleDataStreamView;
