import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { getURL, PRODUCT_LIST_UPB_SCORE } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ProductListScoreCard from 'components/product_lists/product_list/ProductListScoreCard';
import useGet from 'lib/hooks/useGet';

function ProductListScoreView({ productListId }) {
  const {
    data: {
      base_and_logistics_score,
      image_and_marketing_score,
      nutrition_score,
      top_off_and_claims_score,
    } = {},
    loading,
  } = useGet(
    getURL(PRODUCT_LIST_UPB_SCORE, {
      product_list_id: productListId,
    }),
    {},
    !productListId,
  );

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <div className={classNames('d-flex mx-2 p-2', css(styles.cardContainer))}>
      <ProductListScoreCard score={base_and_logistics_score} name="Base & Logistics" />
      <ProductListScoreCard score={image_and_marketing_score} name="Image & Marketing" />
      <ProductListScoreCard score={nutrition_score} name="Nutrition" />
      <ProductListScoreCard score={top_off_and_claims_score} name="Top-Off & Claims" />
    </div>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',
  },
});

ProductListScoreView.propTypes = {
  productListId: PropTypes.string,
};

export default ProductListScoreView;
