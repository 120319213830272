import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import Swal from 'sweetalert2';

import DropZone from 'components/shared/Dropzone';
import EntityType, { ENTITY_TYPE_OPTIONS } from 'lib/enums/EntityType';
import {
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_VENDORS_FOR_DROPDOWN,
  NUTRITIONAL_PANELS_UPLOAD,
} from 'lib/networking/endpoints';
import NUTRITIONAL_PANEL_INPUT_TYPES, {
  NUTRITIONAL_PANEL_INPUT_TYPE_OPTIONS,
} from 'lib/enums/NutritionalPanelInputTypes';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const NUTRITIONAL_PANEL_FOLDER = 'nutritional_panels';

function NutritionalPanelUploadModal({ onHide, refetch }) {
  const [name, setName] = useState('');
  const [entityType, setEntityType] = useState({});
  const [inputType, setInputType] = useState(
    NUTRITIONAL_PANEL_INPUT_TYPE_OPTIONS.find(
      op => op.value === NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES,
    ),
  );
  const [entity, setEntity] = useState({});
  const [imageDetails, setImageDetails] = useState([]);
  const [file, setFile] = useState();

  const { postData: uploadNutritionalPanels, loading } = usePost(
    NUTRITIONAL_PANELS_UPLOAD,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Nutritional panel upload created successfully',
      }).then(() => onHide());
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while creating the nutritional panel ` +
          `upload: ${error?.response?.data?.message}`,
      }),
  );

  const { data: { data: globalVendors } = {} } = useGet(GLOBAL_VENDORS_FOR_DROPDOWN);

  const entityNames = useMemo(() => {
    if (entityType.value === EntityType.DISTRIBUTOR && globalVendors) {
      return globalVendors.map(vendor => ({
        value: vendor.id,
        label: vendor.name,
      }));
    }
    return [];
  }, [entityType, globalVendors]);

  const displayTextMap = {
    [NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES]: 'Drag and drop images here, or click to select images',
    [NUTRITIONAL_PANEL_INPUT_TYPES.CSV]: 'Drag and drop the csv file, or click to select the file',
  };

  const imageUploadPath = `${NUTRITIONAL_PANEL_FOLDER}/${entityType.label?.toLowerCase()}/${
    entity.value
  }`;

  const filePathMap = {
    [NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES]: imageUploadPath,
    [NUTRITIONAL_PANEL_INPUT_TYPES.CSV]: NUTRITIONAL_PANEL_FOLDER,
  };

  const onUploadSuccessMap = {
    [NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES]: newUrls => setImageDetails(newUrls),
    [NUTRITIONAL_PANEL_INPUT_TYPES.CSV]: newUrls => setFile(newUrls?.[0]),
  };

  const fileUrlsMap = {
    [NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES]: imageDetails,
    [NUTRITIONAL_PANEL_INPUT_TYPES.CSV]: file ? [file.presigned_url] : [],
  };

  const displayText = displayTextMap[inputType?.value] || '';
  const filePath = filePathMap[inputType?.value];
  const onUploadSuccess = onUploadSuccessMap[inputType?.value];
  const fileUrls = fileUrlsMap[inputType?.value];

  const onSubmit = () => {
    const data = {
      name: name,
      input_type: inputType.value,
      entity_type: entityType.value,
      global_manufacturer_id: entityType.value === EntityType.MANUFACTURER ? entity.value : null,
      global_vendor_id: entityType.value === EntityType.DISTRIBUTOR ? entity.value : null,
      image_details: imageDetails,
      upload_location_url: file?.s3_path,
    };
    uploadNutritionalPanels(data);
  };

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">Upload New Nutritional Panels</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Input Type</Form.Label>
          <Creatable
            value={inputType}
            options={NUTRITIONAL_PANEL_INPUT_TYPE_OPTIONS}
            onChange={e => setInputType(e)}
            isSearchable
            isClearable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Entity Type</Form.Label>
          <Select
            value={entityType}
            options={ENTITY_TYPE_OPTIONS}
            onChange={e => setEntityType(e || {})}
            isSearchable
            isClearable
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Entity Name</Form.Label>
          {entityType.value === EntityType.MANUFACTURER ? (
            <SearchableScrollableSelect
              onChange={option => setEntity(option)}
              fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
              optionMapper={manufacturer => ({
                label: manufacturer.name,
                value: manufacturer.id,
              })}
              autoFocus={false}
              placeholder="Select Manufacturer"
            />
          ) : (
            <Select
              value={entity?.name}
              options={entityNames}
              onChange={e => setEntity(e)}
              isSearchable
              isClearable
            />
          )}
        </Form.Group>
        <DropZone
          text={displayText}
          onUploadSuccess={onUploadSuccess}
          fileUrls={fileUrls}
          filePath={filePath}
          isImage={inputType.value === NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES}
          isPublic={inputType.value === NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES}
          returnFileName={inputType?.value === NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES}
        />
        <div className="my-3">
          <Button className="px-5 mr-2" disabled={loading} onClick={onSubmit}>
            Upload
          </Button>
          {inputType?.value === NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES && (
            <Button variant="outline-primary mx-2" onClick={() => setImageDetails([])}>
              Clear Images
            </Button>
          )}
          <Button
            variant="outline-primary"
            onClick={() => {
              onHide();
              setImageDetails([]);
              setFile(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

NutritionalPanelUploadModal.propTypes = {
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default NutritionalPanelUploadModal;
