import React from 'react';
import { Route, Switch } from 'react-router-dom';

import GlobalProductsCategoryConflictsVew from 'components/global_products_category_conflicts/GlobalProductsCategoryConflictsVew';
import GlobalProductCategoryConflictDetails from 'components/global_products_category_conflicts/GlobalProductCategoryConflictDetails';

function GlobalProductsCategoryConflictsContainer() {
  return (
    <Switch>
      <Route path="/global-products-category-conflicts" exact>
        <GlobalProductsCategoryConflictsVew />
      </Route>
      <Route path="/global-products-category-conflicts/:globalProductId">
        <GlobalProductCategoryConflictDetails />
      </Route>
    </Switch>
  );
}

export default GlobalProductsCategoryConflictsContainer;
