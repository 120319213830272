import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { Col, Row, Form, Button } from 'react-bootstrap';

import CatalogAttributeFilterValue from 'components/manage_vendors/vendor_details/catalog_settings/CatalogAttributeFilterValue';

function CatalogAttributeFilterView({ catalogFilterAttribute }) {
  return (
    <Row>
      <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
        <Form>
          <Form.Group as={Row}>
            <Col sm={4} className="d-flex">
              <Form.Control
                type="text"
                placeholder="Filter Name"
                value={catalogFilterAttribute.name}
              />
            </Col>

            <Col className="d-flex justify-content-end">
              <Button className={css(styles.button)} variant="outline-primary">
                Save
              </Button>
            </Col>
          </Form.Group>

          <div className="mt-4">
            {catalogFilterAttribute.catalog_filter_attribute_values.map(attribute_value => (
              <CatalogAttributeFilterValue
                key={attribute_value.id}
                catalogFilterAttributeValue={attribute_value}
              />
            ))}
          </div>
        </Form>
      </Col>
    </Row>
  );
}

CatalogAttributeFilterView.propTypes = {
  catalogFilterAttribute: PropTypes.object,
};

const styles = StyleSheet.create({
  filterContainer: {
    border: '1px solid #e5e5e5',
  },
  button: {
    minWidth: '100px',
  },
});

export default CatalogAttributeFilterView;
