import React, { useState } from 'react';

import { Row } from 'react-bootstrap';

import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import { GLOBAL_PRODUCT_CONFLICTS } from 'lib/networking/endpoints';
import GlobalProductsConflictsListTable from 'components/global_products_conflicts/GlobalProductsConflictsListTable';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function GlobalProductsConflictsListView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [searchFilters, setSearchFilters] = useState({});

  const {
    data: { data: { global_product_conflicts: globalProductConflicts = [], count } = {} } = {},
    loading,
    refetch,
  } = useGet(GLOBAL_PRODUCT_CONFLICTS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    ...searchFilters,
  });

  const onColumnSearch = (column, searchText) => {
    setSearchFilters(prevState => ({ ...prevState, [column]: searchText || '' }));
  };

  return (
    <>
      <Row>
        <div className="w-100 d-flex justify-content-end px-3">
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disabled={loading}
          />
        </div>
      </Row>
      <div className="mt-2">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <GlobalProductsConflictsListTable
            conflicts={globalProductConflicts}
            onColumnSearch={onColumnSearch}
            columnSearchTexts={searchFilters}
            refetchConflicts={refetch}
          />
        )}
      </div>
    </>
  );
}

export default GlobalProductsConflictsListView;
