import React, { createContext, useContext, useEffect, useState } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { DEFAULT_CATEGORY_VIEW_PAGE_SIZE, DEFAULT_GLOBAL_PRODUCT_COLUMNS } from 'lib/constants';
import { getEndOfDayTimestamp, getStartOfDayTimestamp } from 'lib/utils/dateUtils';
import { GLOBAL_PRODUCTS, GLOBAL_PRODUCTS_DOWNLOAD_CSV, VIEWS } from 'lib/networking/endpoints';
import GlobalProductAttributeSplitView from 'components/global_products/attribute/GlobalProductAttributeSplitView';
import GlobalProductsTaxonomySplitView from 'components/global_products/classify/GlobalProductsTaxonomySplitView';
import ProtectedRoute from 'components/layout/ProtectedRoute';
import ProductStatus from 'lib/enums/ProductStatus';
import UserPermission from 'lib/enums/UserPermission';

const GlobalProductsContext = createContext({});

const DefaultGlobalProductsView = {
  name: 'Default Global Products View',
  columns: DEFAULT_GLOBAL_PRODUCT_COLUMNS.map(column => ({ name: column, visible: true })),
};

function GlobalProductsContainer() {
  const [allViews, setAllViews] = useState([DefaultGlobalProductsView]);
  const [view, setView] = useState(DefaultGlobalProductsView);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_CATEGORY_VIEW_PAGE_SIZE);
  const [searchFilters, setSearchFilters] = useState({});
  const [searchFiltersId, setSearchFiltersId] = useState({});
  const [selectedStatus, setSelectedStatus] = useState([
    ProductStatus.UNCATEGORIZED,
    ProductStatus.MIGRATION_FAILED,
    ProductStatus.LEGACY_UNCLASSIFIED,
    ProductStatus.MIGRATION_FAILED_UNCLASSIFIED,
    ProductStatus.CATEGORY_PREDICTED,
  ]);
  const [sortConfig, setSortConfig] = useState({ field: '', direction: '' });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedIDs, setSelectedIDs] = useState([]);

  const { homeUrl } = useContext(AuthContext);

  const { data: { data: views } = {}, loading, isLoaded: viewsLoaded } = useGet(VIEWS);
  const { postData: createView, loading: viewCreating } = usePost(
    VIEWS,
    () => {
      SwalDialog('success', 'View created successfully', 'Success', 'center');
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred while creating the view: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  const {
    data: { data: { count: productCount } = {} } = {},
    loading: globalProductsCountLoading,
    error: errorLoadingGlobalProductsCount,
    refetch: refetchCount,
  } = useGet(
    GLOBAL_PRODUCTS,
    {
      filters: JSON.stringify({
        statuses: selectedStatus,
        start_date: getStartOfDayTimestamp(startDate),
        end_date: getEndOfDayTimestamp(endDate),
        ...searchFilters,
        ...searchFiltersId,
      }),
      count_only: true,
    },
    !viewsLoaded || (!endDate && startDate),
  );

  const {
    data: { data: { global_products: products } = {} } = {},
    loading: globalProductsLoading,
    error: errorLoadingGlobalProducts,
    refetch,
  } = useGet(
    GLOBAL_PRODUCTS,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      columns: view?.columns?.map(c => c.name),
      sort_by: sortConfig?.field,
      sort_direction: sortConfig?.direction,
      filters: JSON.stringify({
        statuses: selectedStatus,
        start_date: getStartOfDayTimestamp(startDate),
        end_date: getEndOfDayTimestamp(endDate),
        ...searchFilters,
        ...searchFiltersId,
      }),
    },
    !viewsLoaded || (!endDate && startDate),
  );

  const { postData: exportGlobalProducts, loading: productsExporting } = usePost(
    GLOBAL_PRODUCTS_DOWNLOAD_CSV,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      SwalDialog(
        'error',
        'An error occurred while trying to download the global products',
        'Error',
        'center',
      ),
  );

  const handleExportGlobalProducts = () =>
    exportGlobalProducts({
      columns: view?.columns?.map(c => c.name),
      sort_by: sortConfig?.field,
      sort_direction: sortConfig?.direction,
      filters: {
        statuses: selectedStatus,
        start_date: getStartOfDayTimestamp(startDate),
        end_date: getEndOfDayTimestamp(endDate),
        ...searchFilters,
        ...searchFiltersId,
      },
    });

  const onColumnSearch = (column, searchText, searchId) => {
    setSearchFilters(prevState => ({ ...prevState, [column]: searchText || '' }));
    if (column.includes('category') && !column.includes('vendor')) {
      setSearchFiltersId(prevState => ({ ...prevState, [`${column}_id`]: searchId || '' }));
    }
  };

  const handleSetSelectedStatus = status => {
    setDateRange([null, null]);
    setSelectedStatus(status);
  };

  useEffect(() => {
    if (!views?.length) {
      return;
    }
    const vs = views.map(v => ({
      ...v,
      columns: v.columns.map(c => ({ name: c, visible: true })),
    }));
    setAllViews(vs);
    setView(vs.find(v => v.active));
  }, [views]);

  const viewsLoading = loading || viewCreating;
  return (
    <GlobalProductsContext.Provider
      value={{
        allViews,
        setAllViews,
        view,
        setView,
        createView,
        viewsLoading,
        products,
        productCount,
        globalProductsLoading,
        errorLoadingGlobalProducts,
        globalProductsCountLoading,
        errorLoadingGlobalProductsCount,
        refetch,
        refetchCount,
        onColumnSearch,
        handleSetSelectedStatus,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        searchFilters,
        searchFiltersId,
        selectedStatus,
        sortConfig,
        setSortConfig,
        startDate,
        endDate,
        setDateRange,
        handleExportGlobalProducts,
        productsExporting,
        selectedIDs,
        setSelectedIDs,
      }}
    >
      <Switch>
        <ProtectedRoute
          path="/global-products/classify"
          component={<GlobalProductsTaxonomySplitView />}
          permissions={[UserPermission.CLASSIFY]}
          fallbackUrl={homeUrl}
        />
        <ProtectedRoute
          path="/global-products/attribute"
          component={<GlobalProductAttributeSplitView />}
          permissions={[UserPermission.EDIT_ATTRIBUTES]}
          fallbackUrl={homeUrl}
        />
        <Route path="/global-products">
          <Redirect to="/global-products/classify" />
        </Route>
      </Switch>
    </GlobalProductsContext.Provider>
  );
}

export default GlobalProductsContainer;
export { DefaultGlobalProductsView, GlobalProductsContext };
