import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { Row } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import { DEFAULT_UPB_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { getURL, NUTRITIONAL_PANELS_FOR_VIEW } from 'lib/networking/endpoints';
import NutritionalPanelCatalogView from 'components/nutritional_panels/NutritionalPanelCatalogView';
import PaginationComponent from 'components/shared/PaginationComponent';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function UploadedNutritionalPanelView() {
  const { nutritionalPanelUploadID } = useParams();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_UPB_PAGE_SIZE);
  const { data: { nutritional_panels, panel_count } = {}, loading } = useGet(
    getURL(NUTRITIONAL_PANELS_FOR_VIEW, { nutritional_panel_upload_id: nutritionalPanelUploadID }),
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
  );

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <Container className={css(styles.container)}>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/nutritional-panel-upload')}
          />
          All Nutritional Panel Uploads
        </h3>
      </div>
      <Row>
        <div className="w-100 d-flex justify-content-between px-3 py-3">
          <p>{panel_count} uploaded nutritional panels</p>
          <PaginationComponent
            totalItems={panel_count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disabled={loading}
          />
        </div>
        <NutritionalPanelCatalogView
          nutritionalPanels={nutritional_panels}
          panelCount={panel_count}
        />
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: '1.95rem',
  },
});

export default UploadedNutritionalPanelView;
