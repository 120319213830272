import React, { useState } from 'react';

import { Col, Row, Tab, Tabs } from 'react-bootstrap';

import Container from 'components/shared/Container';
import GlobalProductWiseConflictsVew from 'components/global_products_conflicts/GlobalProductWiseConflictsView';
import GlobalProductsConflictsListView from 'components/global_products_conflicts/GlobalProductsConflictsListView';

const GP_CONFLICTS_VIEW_TABS = {
  GP_WISE_CONFLICTS: 'Global Product Wise Conflicts',
  CONFLICTS_LIST: 'Conflicts List',
};

function GlobalProductsConflictsContainer() {
  const [activeTabKey, setActiveTabKey] = useState(GP_CONFLICTS_VIEW_TABS.CONFLICTS_LIST);

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">Global Products Conflicts</h3>
        </Col>
      </Row>
      <Tabs
        className="mb-2 border-top-0"
        defaultActiveKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
      >
        <Tab
          eventKey={GP_CONFLICTS_VIEW_TABS.CONFLICTS_LIST}
          title={GP_CONFLICTS_VIEW_TABS.CONFLICTS_LIST}
        >
          <GlobalProductsConflictsListView />
        </Tab>
        <Tab
          eventKey={GP_CONFLICTS_VIEW_TABS.GP_WISE_CONFLICTS}
          title={GP_CONFLICTS_VIEW_TABS.GP_WISE_CONFLICTS}
        >
          <GlobalProductWiseConflictsVew />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default GlobalProductsConflictsContainer;
