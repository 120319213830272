import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import dayjs from 'dayjs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { CATEGORY_COLUMNS, DATE_FORMAT_WITH_TIME } from 'lib/constants';
import { categoryStyles } from 'lib/css/categoryTexts';
import ProductStatus from 'lib/enums/ProductStatus';

function formatTextByColumn(product, column, selectedStatus) {
  let text = product[column];
  if (column === 'tagged_interface' && product.l4_category) {
    if (selectedStatus.includes(ProductStatus.UNCATEGORIZED)) {
      return '';
    }
    return text ? 'AI' : 'CP';
  }
  return text;
}

function GlobalProductsTableCell({ product, column, selectedStatus }) {
  const text = formatTextByColumn(product, column, selectedStatus);
  const isCategoryColumn = CATEGORY_COLUMNS.includes(column);
  const isDate = column === 'categorized_at' && text;

  const renderTooltip = props => <Tooltip {...props}>{text}</Tooltip>;
  return text ? (
    <OverlayTrigger placement="top" overlay={renderTooltip} transition={false}>
      <div className={css(styles.textBox, isCategoryColumn && categoryStyles[column])}>
        {isDate ? dayjs(text).format(DATE_FORMAT_WITH_TIME) : text}
      </div>
    </OverlayTrigger>
  ) : null;
}

const styles = StyleSheet.create({
  textBox: {
    maxHeight: 18,
    wordBreak: 'break-word',
  },
});

GlobalProductsTableCell.propTypes = {
  product: PropTypes.object,
  column: PropTypes.string,
  selectedStatus: PropTypes.array,
};

export default GlobalProductsTableCell;
