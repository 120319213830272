import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq, without } from 'lodash';

import { colorStyles } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';

function AttributesSectionHeader({ tab, selectedTabs, setSelectedTabs, customHeader }) {
  const isTabSelected = selectedTabs.includes(tab);
  return (
    <div
      className={classNames(css(colorStyles.coolGrey), 'd-flex', pointerOnHover)}
      onClick={() => {
        setSelectedTabs(isTabSelected ? without(selectedTabs, tab) : uniq([...selectedTabs, tab]));
      }}
    >
      <div className={css(styles.header)}>{customHeader || tab}</div>
      <div className="ml-auto align-self-center mr-2">
        <FontAwesomeIcon icon={isTabSelected ? faChevronUp : faChevronDown} />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    fontSize: 20,
    fontWeight: 700,
  },
});

AttributesSectionHeader.propTypes = {
  tab: PropTypes.string,
  selectedTabs: PropTypes.array,
  setSelectedTabs: PropTypes.func,
  customHeader: PropTypes.string,
};

export default AttributesSectionHeader;
