import React from 'react';
import PropTypes from 'prop-types';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { iconGrey, green } from 'lib/css/colors';
import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';

function VerificationStatusIcon({ verificationStatus }) {
  return (
    <OverlayTrigger overlay={<Tooltip>{verificationStatus}</Tooltip>}>
      <FontAwesomeIcon
        icon={faCheckCircle}
        color={verificationStatus === ProductVerificationStatus.VERIFIED ? green : iconGrey}
        size="lg"
      />
    </OverlayTrigger>
  );
}

VerificationStatusIcon.propTypes = {
  verificationStatus: PropTypes.string,
};

export default VerificationStatusIcon;
