import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import { isEmpty, some } from 'lodash';

import { backgroundGrey2 } from 'lib/css/colors';
import RadioButton from 'components/shared/RadioButton';
import { TAXONOMY_CATEGORY_NAMES } from 'lib/networking/endpoints';
import TaxonomyLevel, { CATEGORY_OPTIONS } from 'lib/enums/TaxonomyLevel';
import usePost from 'lib/hooks/usePost';

function CreateOrUpdateValueGroupModal({
  onHide,
  valueGroupMapDetails = {},
  attributeValues = [],
  createNewAttributeValueMap,
}) {
  const [categoryLevel, setCategoryLevel] = useState(
    !isEmpty(valueGroupMapDetails)
      ? CATEGORY_OPTIONS.find(op => op.value === valueGroupMapDetails.taxonomy_level?.toString())
      : {},
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    !isEmpty(valueGroupMapDetails)
      ? { value: valueGroupMapDetails.category_id, label: valueGroupMapDetails.category_name }
      : {},
  );
  const [selectedValues, setSelectedValues] = useState(valueGroupMapDetails.selected_values || []);

  const { postData: updateProducts } = usePost(TAXONOMY_CATEGORY_NAMES, res => {
    setCategories(res.data.data || []);
    if (!isEmpty(valueGroupMapDetails)) {
      setSelectedCategory({
        value: valueGroupMapDetails.category_id,
        label: valueGroupMapDetails.category_name,
      });
    }
  });
  useEffect(() => {
    if (valueGroupMapDetails?.taxonomy_level) {
      updateProducts({ taxonomy_level: categoryLevel.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6 font-weight-bold" centered>
      <Modal.Header>
        <h5 className="px-2 font-weight-bold">New Value Group</h5>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form.Group>
          <Form.Label>Rosetta Category</Form.Label>
          <Select
            classNamePrefix="themed_select"
            options={CATEGORY_OPTIONS.filter(cat => cat.value !== TaxonomyLevel.GL)}
            isSearchable
            onChange={e => {
              setCategoryLevel(e);
              updateProducts({ taxonomy_level: e.value });
              setCategories([]);
              setSelectedCategory({});
            }}
            value={categoryLevel}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Select
            classNamePrefix="themed_select"
            className="mt-3"
            options={categories.map(cat => ({ label: cat.category_name, value: cat.id }))}
            placeholder="Search Catgories"
            isSearchable
            onChange={e => setSelectedCategory(e)}
            value={selectedCategory || {}}
          />
        </Form.Group>
        <Row className="ml-1 mr-2 my-2">
          <Col sm={11} className={classNames(css(styles.selectAll), 'pl-3')}>
            <RadioButton
              box
              className="align-middle mt-1"
              checked={
                selectedValues.length > 0 && attributeValues?.length === selectedValues.length
              }
              disabled={false}
              onClick={() =>
                selectedValues.length === attributeValues?.length
                  ? setSelectedValues([])
                  : setSelectedValues(
                      attributeValues.map(attr => ({
                        valueName: attr.attribute_value,
                        id: attr.id,
                      })),
                    )
              }
              label="Select All"
              small
            />
          </Col>
        </Row>
        <div className={css(styles.scrollableDiv)}>
          {attributeValues.map(value => (
            <div key={value.id}>
              <Row className="ml-1 pt-1">
                <Col sm={12}>
                  <RadioButton
                    box
                    className="align-middle mt-1"
                    disabled={false}
                    checked={some(selectedValues, val => val.id === value.id)}
                    onClick={() => {
                      if (some(selectedValues, val => val.id === value.id)) {
                        let temp = selectedValues.filter(v => v.id !== value.id);
                        setSelectedValues(temp);
                      } else {
                        setSelectedValues([
                          ...selectedValues,
                          { valueName: value.attribute_value, id: value.id },
                        ]);
                      }
                    }}
                    label={value.attribute_value}
                    small
                  />
                </Col>
              </Row>
              <hr className="my-0 mr-1" />
            </div>
          ))}
        </div>
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button
              block
              disabled={
                isEmpty(categoryLevel) || isEmpty(selectedCategory) || selectedValues?.length === 0
              }
              onClick={() => {
                const selectedAttributeValues = selectedValues.map(val => val.id);
                const previouslySelectedAttributeValues =
                  valueGroupMapDetails?.selected_values?.map(val => val.id);
                createNewAttributeValueMap({
                  newlyAddedAttributeValueIds: previouslySelectedAttributeValues
                    ? selectedAttributeValues.filter(
                        x => !previouslySelectedAttributeValues.includes(x),
                      )
                    : selectedAttributeValues,
                  categoryLevel: categoryLevel,
                  category: selectedCategory,
                  removedAttributeValueIds: previouslySelectedAttributeValues
                    ? previouslySelectedAttributeValues.filter(
                        x => !selectedAttributeValues.includes(x),
                      )
                    : [],
                });
                onHide();
              }}
            >
              Save
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  scrollableDiv: {
    overflow: 'scroll',
    height: '150px',
  },
  selectAll: {
    backgroundColor: backgroundGrey2,
    borderRadius: 4,
  },
});

CreateOrUpdateValueGroupModal.propTypes = {
  onHide: PropTypes.func,
  valueGroupMapDetails: PropTypes.object,
  attributeValues: PropTypes.array,
  createNewAttributeValueMap: PropTypes.func,
};

export default CreateOrUpdateValueGroupModal;
