import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { areYouSure } from 'lib/utils/toast';
import {
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_DROPDOWN_DATA,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_LINK_PRODUCTS,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_REJECT,
} from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import CreateManufacturerProductsModal from 'components/global_products/detail_view/CreateManufacturerProductsModal';
import DuplicateProductTableRow from 'components/duplicate_global_products/DuplicateProductTableRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const ALL_OPTION = { label: 'All', value: '' };

function ManageDuplicateGlobalProductsView() {
  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showCreateManufacturerModal, setShowCreateManufacturerModal] = useState(false);

  const {
    data: { count } = {},
    loading: loadingCount,
    refetch: refetchCount,
  } = useGet(DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS, {
    brand_id: selectedBrand,
    manufacturer_id: selectedManufacturer,
    count_only: true,
  });
  const { data: { manufactures, brands } = {}, loading: loadingDropdowns } = useGet(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_DROPDOWN_DATA,
  );

  const brandOptions = [
    ALL_OPTION,
    ...(brands?.map(brand => ({ label: brand.name, value: brand.id })) || []),
  ];
  const manufacturerOptions = [
    ALL_OPTION,
    ...(manufactures?.map(manufacturer => ({
      label: manufacturer.name,
      value: manufacturer.id,
    })) || []),
  ];

  const {
    data: { 0: product_suggestion } = [],
    loading: loadingData,
    refetch: refetchSuggestions,
  } = useGet(DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS, {
    brand_id: selectedBrand,
    manufacturer_id: selectedManufacturer,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  const { postData: linkProducts, loading: linkingProducts } = usePost(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_LINK_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The selected products have been linked successfully',
        timer: 600,
      }).then(() => {
        refetchCount();
        refetchSuggestions();
        setSelectedProducts([]);
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const { postData: reject, loading: rejecting } = usePost(
    DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_REJECT,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The suggestion has been rejected successfully',
        timer: 600,
      }).then(() => {
        refetchCount();
        refetchSuggestions();
        setSelectedProducts([]);
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const handleLinkProducts = () => {
    areYouSure(
      () =>
        linkProducts({
          product_uuids: selectedProducts,
          duplicate_global_product_suggestion_id: product_suggestion?.id,
        }),
      'Are you sure you want to link the selected products?',
      'Link Products',
    );
  };

  const handleReject = () => {
    areYouSure(
      () =>
        reject({
          duplicate_global_product_suggestion_id: product_suggestion?.id,
        }),
      'Are you sure you want to reject the suggestion?',
      'Reject Suggestion',
    );
  };

  const loading = loadingCount || loadingData;

  const handleSelectAll = () => {
    const allProductUUIDs = product_suggestion.similar_products.map(
      product => `${product.product_type}:${product.id}`,
    );
    setSelectedProducts(allProductUUIDs);
  };

  const handleManufacturerChange = option => {
    setSelectedManufacturer(option?.value);
    setCurrentPage(1);
  };

  const handleBrandChange = option => {
    setSelectedBrand(option?.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setSelectedProducts([]);
  }, [currentPage]);

  const isMultiSelected = selectedProducts.length > 0;
  const hasManufacturerProducts = selectedProducts.some(product =>
    product.startsWith('manufacturer'),
  );

  const selectedDistributorProductIDs = selectedProducts
    .filter(item => item.startsWith('distributor'))
    .map(item => parseInt(item.split(':')[1]));

  const selectedDistributorProducts = product_suggestion?.similar_products.filter(product =>
    selectedDistributorProductIDs.includes(product.id),
  );

  return (
    <Container>
      <div className=" mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Link Duplicate Products</h3>
      </div>
      <Row className="d-flex align-items-center ml-3">
        <Form.Group as={Col} md={3}>
          <Form.Label>Filter by manufacturer</Form.Label>
          <Select
            value={manufacturerOptions?.find(option => option.value === selectedManufacturer)}
            options={manufacturerOptions}
            onChange={handleManufacturerChange}
            isLoading={loadingDropdowns}
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>Filter by brand</Form.Label>
          <Select
            value={brandOptions?.find(option => option.value === selectedBrand)}
            options={brandOptions}
            onChange={handleBrandChange}
            isLoading={loadingDropdowns}
          />
        </Form.Group>

        <Col className="d-flex justify-content-end mr-3">
          <Button disabled={rejecting} variant="primary" onClick={handleReject} className="mr-2">
            Reject Suggestion
          </Button>
          <Button
            disabled={linkingProducts || !selectedProducts?.length || selectedProducts?.length < 2}
            variant="primary"
            onClick={handleLinkProducts}
          >
            Link Selected Products
          </Button>
          <Button
            className="ml-1"
            disabled={!isMultiSelected || hasManufacturerProducts}
            onClick={() => setShowCreateManufacturerModal(true)}
          >
            Create Manufacturer Products
          </Button>
          {selectedProducts.length === product_suggestion?.similar_products?.length ? (
            <Button
              className="ml-1"
              onClick={() => {
                setSelectedProducts([]);
              }}
            >
              Unselect All
            </Button>
          ) : (
            <Button
              className="ml-1"
              onClick={handleSelectAll}
              disabled={loading || !product_suggestion?.similar_products?.length}
            >
              Select All
            </Button>
          )}
        </Col>
      </Row>

      {loading && <LoadingSpinner />}

      {product_suggestion && count > 0 ? (
        <div>
          <Row className="mb-2">
            <Col>
              <Button
                className={css(styles.button)}
                variant="link"
                onClick={() => setCurrentPage(currentPage - 1)}
                size="lg"
                disabled={loading || currentPage === 1}
              >
                &lt; Previous
              </Button>
              <Button
                className={css(styles.button)}
                variant="link"
                onClick={() => setCurrentPage(currentPage + 1)}
                size="lg"
                disabled={loading || currentPage === count}
              >
                Next &gt;
              </Button>
            </Col>
            <Col className="d-flex align-items-center justify-content-end mr-3">
              <h6>
                {currentPage}/{count} total suggestions
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hover>
                <thead>
                  <tr>
                    <th></th> {/* Checkbox */}
                    <th></th> {/* Image */}
                    <th>Global Product</th>
                    <th>Product</th>
                    <th>Entity Type</th>
                    <th>Entity Name</th>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Pack Size</th>
                    <th>Average UPB Score</th>
                    <th>Verification Status</th>
                  </tr>
                </thead>
                <tbody>
                  {product_suggestion.similar_products?.map(similar_product => (
                    <DuplicateProductTableRow
                      key={similar_product.id}
                      product={similar_product}
                      selectedProducts={selectedProducts}
                      setSelectedProducts={setSelectedProducts}
                    />
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="d-flex justify-content-center m-5 text-dark font-weight-bold">
          No duplicate products to show
        </div>
      )}
      {showCreateManufacturerModal && (
        <CreateManufacturerProductsModal
          selectedProductsList={selectedDistributorProducts}
          onHide={() => {
            setShowCreateManufacturerModal(false);
            setSelectedProducts([]);
          }}
          refetch={() => {
            refetchSuggestions();
            refetchCount();
          }}
          clusterId={product_suggestion.id}
        />
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  button: {
    fontSize: '1.2em',
    padding: '0.5em',
    fontWeight: 'bold',
  },
});

export default ManageDuplicateGlobalProductsView;
