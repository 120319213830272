import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { auroMetalSaurus, darkGrey2, white } from 'lib/css/colors';

const NEST_INDENTATION = 20; //px
const ROOT_INDENTATION = 10; //px

const createOptionNode = (value, label, subOptions, data) => ({
  ...(data || {}),
  value,
  label,
  subOptions,
});

const isLeafNode = option => !option.subOptions?.length;

/**
 * If for a node, there is only a single path to reach a leaf and all the labels are similar,
 * no point in showing the children as nested
 * @param option
 * @returns
 */
const shouldHideChildren = option => {
  if (isLeafNode(option)) {
    return true;
  }
  const { subOptions, label } = option;
  return (
    subOptions?.length === 1 &&
    subOptions[0].label.trim() === label.trim() &&
    shouldHideChildren(subOptions[0])
  );
};

const LeafMenuItem = ({ option, selectedOption, setSelectedOptions, isRootNode = false }) => {
  const isActive = option.value === selectedOption?.value;

  return (
    <div
      className={classNames(
        css(
          styles.node,
          isActive ? styles.activeLeaf : styles.inactiveLeaf,
          isRootNode ? styles.rootNode : styles.leafNode,
        ),
      )}
      onClick={() => setSelectedOptions([option])}
    >
      {option.label}
    </div>
  );
};

function NestedFilterItem({
  option,
  selectedOptions,
  setSelectedOptions,
  isRootNode = false,
  autoCollapseWhenInactive = false,
}) {
  const [expanded, setExpanded] = useState(false);

  const { label, value, subOptions } = option;
  // separating selected options for current level and deep levels
  const [selectedOption, ...restSelectedOptions] = selectedOptions || [];

  const isActive = value === selectedOption?.value;

  useEffect(() => {
    if (autoCollapseWhenInactive) {
      setExpanded(value === selectedOption?.value);
    }
  }, [selectedOption?.value, value, setExpanded, autoCollapseWhenInactive]);

  if (isLeafNode(option)) {
    return (
      <LeafMenuItem
        option={option}
        setSelectedOptions={setSelectedOptions}
        isRootNode={isRootNode}
        selectedOption={selectedOptions?.[0]}
      />
    );
  }

  const hideChildren = shouldHideChildren(option);
  const noChildrenSelected = !restSelectedOptions?.length;

  return (
    <>
      <div
        className={classNames(
          'd-flex align-items-center',
          css(isRootNode ? styles.rootNode : styles.leafNode),
          isActive &&
            (noChildrenSelected || hideChildren ? css(styles.activeLeaf) : 'font-weight-bold'),
          css(styles.bottomBorder),
        )}
        onClick={() => {
          setSelectedOptions([option]);
          setExpanded(!isActive || !noChildrenSelected || !expanded);
        }}
      >
        <div className={classNames('w-100', css(styles.node))}>{label}</div>
        {!hideChildren && (
          <div
            className={css(styles.node)}
            onClick={e => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          >
            <FontAwesomeIcon
              className="ml-1"
              icon={expanded ? faChevronUp : faChevronDown}
              size="lg"
              color={isActive && noChildrenSelected ? white : 'grey'}
            />
          </div>
        )}
      </div>

      <div
        className={classNames(
          (hideChildren || !expanded) && 'd-none',
          css(isRootNode ? styles.rootChildren : styles.children),
        )}
      >
        {subOptions?.map(subOption => (
          <NestedFilterItem
            key={subOption.value}
            option={subOption}
            selectedOptions={restSelectedOptions}
            setSelectedOptions={childOptions => setSelectedOptions([option, ...childOptions])}
            autoCollapseWhenInactive={autoCollapseWhenInactive}
          />
        ))}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  children: {
    marginLeft: NEST_INDENTATION,
  },
  bottomBorder: {
    borderBottom: '1px solid ' + darkGrey2,
  },
  rootChildren: {
    marginLeft: ROOT_INDENTATION,
  },
  node: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  rootNode: {
    paddingLeft: ROOT_INDENTATION,
    paddingRight: 5,
  },
  leafNode: {
    paddingLeft: NEST_INDENTATION,
    paddingRight: 5,
  },
  activeLeaf: {
    color: white,
    borderRadius: 5,
    backgroundColor: auroMetalSaurus,
  },
  inactiveLeaf: {
    color: 'grey',
    borderRadius: 5,
    cursor: 'pointer',
  },
});

NestedFilterItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.number || null,
    label: PropTypes.string.isRequired,
    subOptions: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  setSelectedOptions: PropTypes.func.isRequired,
  isRootNode: PropTypes.bool,
  autoCollapseWhenInactive: PropTypes.bool,
};

LeafMenuItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.number || null,
    label: PropTypes.string.isRequired,
  }).isRequired,
  selectedOption: PropTypes.shape({
    value: PropTypes.number || null,
    label: PropTypes.string.isRequired,
  }),
  setSelectedOptions: PropTypes.func.isRequired,
  isRootNode: PropTypes.bool,
};

export { createOptionNode };
export default NestedFilterItem;
