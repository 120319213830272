import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';

import Container from 'components/shared/Container';
import CreateProductListModal from 'components/product_lists/CreateProductListModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { PRODUCT_LISTS } from 'lib/networking/endpoints';
import ProductListTable from 'components/product_lists/product_list/ProductListTable';
import useGet from 'lib/hooks/useGet';

const PRODUCT_LIST_TABS = {
  MANUFACTURER: 'MANUFACTURER',
  DISTRIBUTOR: 'DISTRIBUTOR',
};

function ProductListsView() {
  const [activeTabKey, setActiveTabKey] = useState(PRODUCT_LIST_TABS.DISTRIBUTOR);
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredProductLists, setFilteredProductLists] = useState({
    distributorProductLists: [],
    manufacturerProductLists: [],
  });

  const {
    data: { product_lists: productLists } = {},
    loading,
    error,
    refetch,
  } = useGet(PRODUCT_LISTS);

  const divideProductLists = productLists => {
    const distributorProductLists = productLists.filter(
      pList => pList.filters?.distributor_ids?.length,
    );
    const manufacturerProductLists = productLists.filter(
      pList => pList.filters?.manufacturer_ids?.length,
    );
    setFilteredProductLists({
      distributorProductLists: distributorProductLists,
      manufacturerProductLists: manufacturerProductLists,
    });
  };

  useEffect(() => {
    if (productLists) {
      divideProductLists(productLists);
    }
  }, [productLists]);

  const handleSearch = event => {
    setSearchString(event.target.value);
    if (event.target.value) {
      const filtered = productLists.filter(productList =>
        productList.name.toLowerCase().includes(searchString.toLowerCase()),
      );
      divideProductLists(filtered);
    } else {
      divideProductLists(productLists);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  return (
    <Container>
      <div className="d-flex justify-content-between">
        <h3 className="font-weight-bold">Product Lists</h3>
        <div>
          <Button className="px-4" onClick={() => setShowCreateListModal(true)}>
            Create List
          </Button>
        </div>
      </div>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Control
              onChange={handleSearch}
              type="text"
              placeholder="Search Product List"
              value={searchString}
            />
          </Form.Group>
        </Col>
      </Row>
      <Tabs defaultActiveKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab eventKey={PRODUCT_LIST_TABS.DISTRIBUTOR} title={PRODUCT_LIST_TABS.DISTRIBUTOR}>
          <ProductListTable
            productLists={filteredProductLists.distributorProductLists}
            refetch={refetch}
          />
        </Tab>
        <Tab eventKey={PRODUCT_LIST_TABS.MANUFACTURER} title={PRODUCT_LIST_TABS.MANUFACTURER}>
          <ProductListTable
            productLists={filteredProductLists.manufacturerProductLists}
            refetch={refetch}
          />
        </Tab>
      </Tabs>
      <CreateProductListModal
        show={showCreateListModal}
        onHide={() => setShowCreateListModal(false)}
        refetch={refetch}
      />
    </Container>
  );
}

export default ProductListsView;
