import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';

function UpcGtinExistErrorHandleModal({ show, onHide, errorResponse, onClickMerge }) {
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header className="d-flex justify-content-center">
        <h4>Error</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="py-3 text-center">
          <div>{errorResponse.message}</div>
          <a
            href={'/global-products/' + errorResponse.product_id}
            rel="noopener noreferrer"
            target="_blank"
          >
            View Product
          </a>
        </div>
        <div className="d-flex justify-content-center">
          <Button className="mx-2" onClick={onClickMerge}>
            Merge
          </Button>
          <Button className="mx-2" onClick={onHide}>
            Back
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

UpcGtinExistErrorHandleModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  errorResponse: PropTypes.object,
  onClickMerge: PropTypes.func,
};

export default UpcGtinExistErrorHandleModal;
