import React from 'react';
import PropTypes from 'prop-types';

import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { truncate } from 'lodash';

import { anchovy, bodyText, colorStyles } from 'lib/css/colors';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';

const DISPLAY_NAME_LENGTH = 65;

function UPBProductCatalogCard({ product }) {
  const productLink = `/upb-pdp/${product.id}`;
  const packSizeAndSku = [product?.pack_size, `#${product?.manufacturer_sku || ''}`]
    .filter(Boolean)
    .join(' | ');

  return (
    <Card className={classNames('position-relative', css(styles.card))}>
      <div className={classNames(css(styles.cardContent), 'd-flex flex-column h-100')}>
        <div
          className={classNames(
            css(styles.productImageContainer),
            'align-items-center justify-content-center d-flex',
          )}
        >
          <LinkContainer className={pointerOnHover} to={productLink}>
            <Card.Img
              className={css(styles.productImage)}
              variant="top"
              src={product.propagated_thumbnail_image_url ?? placeholderProductImage}
            />
          </LinkContainer>
        </div>
        <Card.Body className="px-0 pt-2 pb-1 d-flex flex-column">
          <Link to={productLink} target="_blank" className={css(styles.displayName)}>
            <div className="text-capitalize mt-2">
              {truncate(product.name, { length: DISPLAY_NAME_LENGTH, omission: '' })}
              {product.name.length > DISPLAY_NAME_LENGTH && (
                <OverlayTrigger overlay={<Tooltip>{product.name}</Tooltip>}>
                  <span>...</span>
                </OverlayTrigger>
              )}
            </div>
          </Link>
          <div className={classNames(css(colorStyles.anchovy, styles.subText), 'text-nowrap mt-1')}>
            {product.brand && (
              <div className={classNames(css(styles.brandName))}>
                {product.brand.name}
                {product.brand.global_manufacturer && (
                  <span className="ml-1">by {product.brand.global_manufacturer.name}</span>
                )}
              </div>
            )}
            {<div>{packSizeAndSku}</div>}
            {product.gtin && <div>#{product.gtin}</div>}
          </div>
        </Card.Body>
      </div>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    minWidth: 220,
    maxWidth: 220,
    minHeight: 330,
    borderRadius: 5,
    padding: 20,
    margin: '0px 10px 20px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    transition: 'all 0.3s ease 0s',
    ':hover': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      border: '1px solid ' + anchovy,
    },
  },
  cardContent: {
    height: '100%',
  },
  productImageContainer: {
    position: 'relative',
    width: '100%',
    height: 210,
    overflow: 'hidden',
  },
  subText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    fontWeight: 600,
  },
  productImage: {
    ':hover': {
      opacity: 0.6,
    },
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  displayName: {
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: bodyText,
  },
  brandName: {
    lineHeight: '20px',
    textDecoration: 'underline',
  },
});

UPBProductCatalogCard.propTypes = {
  product: PropTypes.object,
};

export default UPBProductCatalogCard;
