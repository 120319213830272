import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Button, Spinner } from 'react-bootstrap';

import { anchovy, greyStroke, squidInk, white } from 'lib/css/colors';
import PercentageChange from 'components/dashboard/PercentageChange';
import pointerOnHover from 'lib/css/pointerOnHover';

function DashboardWidget({
  label,
  currentTotalCount,
  loading,
  percentageChange,
  dateDifference,
  onClick,
}) {
  return (
    <div className={css(styles.container)}>
      <div className="d-flex">
        <div className={css(styles.selectedTitle)}>{label}</div>
        {currentTotalCount > 0 && onClick ? (
          <Button variant="link" onClick={onClick} className={pointerOnHover}>
            (View)
          </Button>
        ) : null}
      </div>
      <h6 className={css(styles.selectedValue)}>
        {loading ? (
          <Spinner animation="border" role="status" size="sm" />
        ) : (
          <strong>{currentTotalCount.toLocaleString()}</strong>
        )}
      </h6>
      <PercentageChange percentageChange={percentageChange} dateDifference={dateDifference} />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    border: `1px solid ${greyStroke}`,
    backgroundColor: white,
    boxShadow: `0 2px 4px ${anchovy}`,
    padding: '10px 10px 20px 20px',
  },
  selectedTitle: {
    color: squidInk,
    fontSize: 16,
    marginBottom: 10,
    paddingTop: 10,
  },
  selectedValue: {
    color: squidInk,
    fontSize: 20,
    marginBottom: 10,
  },
});

DashboardWidget.propTypes = {
  label: PropTypes.string,
  currentTotalCount: PropTypes.number,
  percentageChange: PropTypes.number,
  loading: PropTypes.bool,
  dateDifference: PropTypes.number,
  onClick: PropTypes.func,
};

export default DashboardWidget;
