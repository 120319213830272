import React from 'react';
import PropTypes from 'prop-types';

function GeneralSection({ content }) {
  return (
    <div className="my-4">
      <div>{content}</div>
    </div>
  );
}

GeneralSection.propTypes = {
  content: PropTypes.string,
};

export default GeneralSection;
