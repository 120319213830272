import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { blackberry, white } from 'lib/css/colors';
import { Button, Form, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';

import { BRAND_HARVEST, BRANDS_HARVEST } from 'lib/networking/endpoints';
import DropZone from 'components/shared/Dropzone';
import {
  BrandHarvestingScrapingCompletedOption,
  BrandHarvestingScrapedFileRejectedOption,
  BrandHarvestingStatusOptions,
  BrandHarvestingQualityOptions,
  BrandHarvestingPriorityOptions,
} from 'lib/enums/BrandHarvestingEnums';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

const SCRAPER_FILE_FOLDER = 'scraper-files';

const BOOLEAN_OPTIONS = [
  { label: 'Available', value: true },
  { label: 'Not Available', value: false },
];

function BrandHarvestingEditModal({
  onHide,
  brandToHarvest,
  setBrandDetails,
  selectedIds,
  isMultiBrandEdit = false,
}) {
  const [updatedData, setUpdatedData] = useState({
    mpn: { label: '', value: '' },
    gtin: { label: '', value: '' },
    url: '',
    dataQuality: { label: '', value: '' },
    harvestStatus: { label: '', value: '' },
    priority: { label: '', value: '' },
    comment: { label: '', value: '' },
    scraperFileUrl: '',
  });

  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    if (brandToHarvest) {
      setUpdatedData({
        mpn: BOOLEAN_OPTIONS.find(
          op => op.value === brandToHarvest.manufacturer_product_number_exists,
        ),
        gtin: BOOLEAN_OPTIONS.find(op => op.value === brandToHarvest.gtin_exists),
        url: brandToHarvest.harvest_url,
        dataQuality: BrandHarvestingQualityOptions.find(
          op => op.value === brandToHarvest.data_quality,
        ),
        harvestStatus: BrandHarvestingStatusOptions.find(
          op => op.value === brandToHarvest.harvest_status,
        ),
        priority: BrandHarvestingPriorityOptions.find(op => op.value === brandToHarvest.priority),
        scraperFileUrl: brandToHarvest.scraper_file_url,
      });
    }
    // eslint-disable-next-line
  }, [brandToHarvest]);

  const { postData: updateBrand } = usePost(
    BRAND_HARVEST,
    data => {
      setBrandDetails(data.data.brand);
      SwalDialog('success', 'Updated successfully', 'Success', 'center');
      onHide();
      window.location.reload();
    },
    () => {
      SwalDialog('error', 'An error occurred while updating', 'Error', 'center');
    },
  );

  const { postData: updateMultiBrand } = usePost(
    BRANDS_HARVEST,
    () => {
      SwalDialog('success', 'Updated successfully', 'Success', 'center');
      onHide();
      window.location.reload();
    },
    () => {
      SwalDialog('error', 'An error occurred while updating', 'Error', 'center');
    },
  );

  function handleUpdate() {
    const harvestData = {
      manufacturer_product_number_exists: updatedData.mpn.value || null,
      gtin_exists: updatedData.gtin.value || null,
      data_quality: updatedData.dataQuality.value,
      harvest_url: updatedData.url,
      harvest_status: updatedData.harvestStatus.value,
      comment: updatedData.comment || null,
      priority: updatedData.priority?.value || null,
      scraper_file_url: updatedData.scraperFileUrl || null,
    };
    if (isMultiBrandEdit) {
      updateMultiBrand({
        brand_ids: typeof selectedIds === 'string' ? selectedIds.split(',') : selectedIds,
        ...harvestData,
      });
    } else {
      updateBrand({
        brand_id: brandToHarvest.id,
        ...harvestData,
      });
    }
  }
  const handleUpload = newUrls => {
    setUpdatedData({ ...updatedData, scraperFileUrl: newUrls });
    setUploadSuccess(true);
  };
  return (
    <Modal show={true} onHide={onHide} size="md" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>
          {isMultiBrandEdit ? 'Edit Selected Brands' : `Edit Brand - ${brandToHarvest.name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Manufacturer Product Number Exists</Form.Label>
          <Select
            value={updatedData.mpn}
            onChange={op => setUpdatedData({ ...updatedData, mpn: op })}
            options={BOOLEAN_OPTIONS}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>GTIN Exists</Form.Label>
          <Select
            value={updatedData.gtin}
            onChange={op => setUpdatedData({ ...updatedData, gtin: op })}
            options={BOOLEAN_OPTIONS}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>URL</Form.Label>
          <Form.Control
            value={updatedData.url}
            type="text"
            onChange={e => setUpdatedData({ ...updatedData, url: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Data Quality</Form.Label>
          <Select
            value={updatedData.dataQuality}
            onChange={option => setUpdatedData({ ...updatedData, dataQuality: option })}
            options={BrandHarvestingQualityOptions}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Harvest Status</Form.Label>
          <Select
            value={updatedData.harvestStatus}
            onChange={option => setUpdatedData({ ...updatedData, harvestStatus: option })}
            options={BrandHarvestingStatusOptions}
          />
        </Form.Group>
        {updatedData.harvestStatus.value === BrandHarvestingScrapedFileRejectedOption ? (
          <Form.Group>
            <Form.Label>Comments on Rejection</Form.Label>
            <Form.Control
              as="textarea"
              rows="6"
              value={updatedData.comment || ''}
              onChange={e => setUpdatedData({ ...updatedData, comment: e.target.value })}
            />
          </Form.Group>
        ) : null}
        {updatedData.harvestStatus.value === BrandHarvestingScrapingCompletedOption &&
        !uploadSuccess ? (
          <Form.Group>
            <Form.Label>Upload the Scraper file</Form.Label>
            <DropZone
              text="Drag and drop the Scraped File here, or click to select the file"
              onUploadSuccess={newUrls => {
                handleUpload(newUrls);
              }}
              fileUrls={updatedData.scraperFileUrl || ''}
              isPublic={true}
              filePath={SCRAPER_FILE_FOLDER}
              customResponse={true}
            />
          </Form.Group>
        ) : uploadSuccess ? (
          <Form.Group>
            <Form.Label>Upload the Scraper file</Form.Label>
            <div className="mt-3 d-flex justify-content-between align-items-center w-100">
              <div style={{ color: 'green' }}>Scraper File Uploaded Successfully!</div>
              <Button
                className="px-20 mt-1 float-right"
                variant="danger"
                onClick={() => {
                  setUpdatedData({ ...updatedData, scraperFileUrl: '' });
                  setUploadSuccess(false);
                }}
              >
                Remove Uploaded File
              </Button>
            </div>
          </Form.Group>
        ) : null}
        <Form.Group>
          <Form.Label>Priority</Form.Label>
          <Select
            value={updatedData.priority}
            onChange={option => setUpdatedData({ ...updatedData, priority: option })}
            options={BrandHarvestingPriorityOptions}
          />
        </Form.Group>
        <div className="d-flex mt-3 justify-content-left">
          <Button className="px-20 mt-1 float-right" onClick={handleUpdate}>
            Update
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  updateButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
});

BrandHarvestingEditModal.propTypes = {
  onHide: PropTypes.func,
  brandToHarvest: PropTypes.object,
  selectedIds: PropTypes.array,
  setBrandDetails: PropTypes.func,
  isMultiBrandEdit: PropTypes.bool,
};

export default BrandHarvestingEditModal;
