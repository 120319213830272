import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import ManageAttributesView from 'components/manage_attribute_value/ManageAttributesView';
import ManageAttributeValueDetailsView from 'components/manage_attribute_value/ManageAttributeValueDetailsView';

const ManageAttributeValueContext = createContext({});

function ManageAttributeValueContainer() {
  const [attributes, setAttributes] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState();

  return (
    <ManageAttributeValueContext.Provider
      value={{
        attributes,
        setAttributes,
        filteredAttributes,
        setFilteredAttributes,
        selectedAttribute,
        setSelectedAttribute,
      }}
    >
      <Route path="/manage-attributes" exact>
        <ManageAttributesView />
      </Route>
      <Route path="/manage-attributes/:attribute_name/:attribute_type/:attribute_id" exact>
        <ManageAttributeValueDetailsView />
      </Route>
    </ManageAttributeValueContext.Provider>
  );
}

export default ManageAttributeValueContainer;
export { ManageAttributeValueContext };
