import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { auroMetalSaurus } from 'lib/css/colors';

function CheckboxFilterItem({ title, checked, handleOnCheck }) {
  return (
    <div
      className={classNames('d-flex  align-items-center', css(styles.item))}
      onClick={() => handleOnCheck(!checked)}
    >
      <div className="w-100 pr-1">{title}</div>
      <FontAwesomeIcon
        className={classNames(css(styles.checkBox), 'ml-1')}
        color={auroMetalSaurus}
        icon={checked ? faCheckSquare : faSquare}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  checkBox: {
    fontSize: 22.76,
  },
  item: {
    paddingTop: 7,
    paddingBottom: 7,
    cursor: 'pointer',
  },
});

CheckboxFilterItem.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleOnCheck: PropTypes.func.isRequired,
};

export default CheckboxFilterItem;
