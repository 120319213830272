import React from 'react';
import PropTypes from 'prop-types';

import MainNutritionRow from 'components/shared/nutrition_facts/MainNutritionRow';
import SubNutritionRow from 'components/shared/nutrition_facts/SubNutritionRow';
import useFindAttributeValueByName from 'lib/hooks/product/useFindAttributeValueByName';

function FatSection({ nutritionAttributes }) {
  const totalFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'total_fat_quantitycontained',
  );
  const totalFatUom = useFindAttributeValueByName(nutritionAttributes, 'total_fat_uom');
  const totalFatDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'total_fat_rdi_dailyvalueintakepercent',
  );
  const saturatedFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'saturated_fat_quantitycontained',
  );
  const saturatedFatUom = useFindAttributeValueByName(nutritionAttributes, 'saturated_fat_uom');
  const saturatedFatDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'saturated_fat_rdi_dailyvalueintakepercent',
  );
  const transFattyAcidsQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'transfatty_acids_quantitycontained',
  );
  const transFattyAcidsUom = useFindAttributeValueByName(
    nutritionAttributes,
    'transfatty_acids_uom',
  );
  const polyUnsaturatedFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'polyunsaturated_fat_quantitycontained',
  );
  const polyUnsaturatedFatUom = useFindAttributeValueByName(
    nutritionAttributes,
    'polyunsaturated_fat_uom',
  );
  const monoUnsaturatedFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'monounsaturated_fat_quantitycontained',
  );
  const monoUnsaturatedFatUom = useFindAttributeValueByName(
    nutritionAttributes,
    'monounsaturated_fat_uom',
  );
  if (!totalFatQuantity) {
    return null;
  }
  return (
    <>
      <MainNutritionRow
        title="Total Fat"
        quantity={totalFatQuantity}
        uom={totalFatUom}
        percentage={totalFatDailyValue}
      />
      <SubNutritionRow
        title="Saturated Fat"
        quantity={saturatedFatQuantity}
        uom={saturatedFatUom}
        percentage={saturatedFatDailyValue}
        offset={1}
        boldPercentage
      />
      <SubNutritionRow
        title={
          <span>
            <span className="font-italic">Trans </span>
            Fat
          </span>
        }
        quantity={transFattyAcidsQuantity}
        uom={transFattyAcidsUom}
        offset={1}
      />
      <SubNutritionRow
        title="Polyunsaturated Fat"
        quantity={polyUnsaturatedFatQuantity}
        uom={polyUnsaturatedFatUom}
        offset={1}
      />
      <SubNutritionRow
        title="Monounsaturated Fat"
        quantity={monoUnsaturatedFatQuantity}
        uom={monoUnsaturatedFatUom}
        offset={1}
      />
    </>
  );
}

FatSection.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default FatSection;
