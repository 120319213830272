import React from 'react';
import PropTypes from 'prop-types';

import AddedSugarsRow from 'components/shared/nutrition_facts/AddedSugarsRow';
import MainNutritionRow from 'components/shared/nutrition_facts/MainNutritionRow';
import SubNutritionRow from 'components/shared/nutrition_facts/SubNutritionRow';
import useFindAttributeValueByName from 'lib/hooks/product/useFindAttributeValueByName';

function CarbohydratesSection({ nutritionAttributes }) {
  const carbohydratesQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'carbohydrates_quantitycontained',
  );
  const carbohydratesUom = useFindAttributeValueByName(nutritionAttributes, 'carbohydrates_uom');
  const carbohydratesDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'carbohydrates_rdi_dailyvalueintakepercent',
  );
  const totalDietFiberQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'total_dietary_fiber_quantitycontained',
  );
  const totalDietFiberUom = useFindAttributeValueByName(
    nutritionAttributes,
    'total_dietary_fiber_uom',
  );
  const totalDietFiberDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'total_dietary_fiber_rdi_dailyvalueintakepercent',
  );
  const solubleFiberQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'soluble_fiber_quantitycontained',
  );
  const solubleFiberUom = useFindAttributeValueByName(nutritionAttributes, 'soluble_fiber_uom');
  const insolubleFiberQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'insoluble_fiber_quantitycontained',
  );
  const insolubleFiberUom = useFindAttributeValueByName(nutritionAttributes, 'insoluble_fiber_uom');
  const totalSugarQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'total_sugar_quantitycontained',
  );
  const totalSugarUom = useFindAttributeValueByName(nutritionAttributes, 'total_sugar_uom');
  const addedSugarQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    'added_sugar_quantitycontained',
  );
  const addedSugarUom = useFindAttributeValueByName(nutritionAttributes, 'added_sugar_uom');
  const addedSugarDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    'added_sugar_rdi_dailyvalueintakepercent',
  );
  if (!carbohydratesQuantity) {
    return null;
  }
  return (
    <>
      <MainNutritionRow
        title="Total Carbohydrates"
        quantity={carbohydratesQuantity}
        uom={carbohydratesUom}
        percentage={carbohydratesDailyValue}
      />
      <SubNutritionRow
        title="Dietary Fiber"
        quantity={totalDietFiberQuantity}
        uom={totalDietFiberUom}
        percentage={totalDietFiberDailyValue}
        offset={1}
        boldPercentage
      />
      <SubNutritionRow
        title="Soluble Fiber"
        quantity={solubleFiberQuantity}
        uom={solubleFiberUom}
        offset={2}
      />
      <SubNutritionRow
        title="Insoluble Fiber"
        quantity={insolubleFiberQuantity}
        uom={insolubleFiberUom}
        offset={2}
      />
      <SubNutritionRow
        title="Total Sugars"
        quantity={totalSugarQuantity}
        uom={totalSugarUom}
        offset={1}
        separatorOffset={addedSugarQuantity ? 2 : 0}
      />
      <AddedSugarsRow
        quantity={addedSugarQuantity}
        uom={addedSugarUom}
        percentage={addedSugarDailyValue}
      />
    </>
  );
}

CarbohydratesSection.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default CarbohydratesSection;
