import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Collapse, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import AttributesGroupedTagView from 'components/upb_viewer/pdp_components/AttributesGroupedTagView';
import AttributesSectionHeader from 'components/upb_viewer/pdp_components/AttributesSectionHeader';
import AttributesTagView from 'components/upb_viewer/pdp_components/AttributesTagView';
import { backgroundColorStyles } from 'lib/css/colors';
import CustomContainer from 'components/shared/Container';
import { GLOBAL_PRODUCT_PDP } from 'lib/networking/endpoints';
import GeneralSection from 'components/upb_viewer/pdp_components/GeneralSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import NutritionFactsSection from 'components/upb_viewer/pdp_components/NutritionFactsSection';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductDescriptionSection from 'components/upb_viewer/pdp_components/ProductDescriptionSection';
import ProductImagesSection from 'components/upb_viewer/pdp_components/images_section/ProductImagesSection';
import ProductHeaderSection from 'components/upb_viewer/pdp_components/ProductHeaderSection';
import ProductOverviewSection from 'components/upb_viewer/pdp_components/ProductOverviewSection';
import TableViewSection from 'components/upb_viewer/pdp_components/TableViewSection';
import useGet from 'lib/hooks/useGet';

const DESCRIPTION = 'Description';
const CLAIMS = 'Claims';
const INGREDIENTS = 'Ingredients';
const ALLERGENS = 'Allergens';
const SPECIFICATIONS = 'Specifications';
const WEIGHT_DIMENSIONS = 'Weight & Dimensions';
const TIPS_HANDLING = 'Tips & Handling';

function UPBProductDetailsPage({ propProductId, productType, hideHeader = false }) {
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [attributeSections, setAttributeSections] = useState([]);
  const { productId: urlProductId } = useParams();
  const productId = propProductId || urlProductId;
  const history = useHistory();

  const {
    data: { data: product = {} } = {},
    loading,
    error,
  } = useGet(GLOBAL_PRODUCT_PDP, { product_id: productId, product_type: productType }, !productId);

  const overviewSectionData = useMemo(
    () => product.sections?.find(section => section.name === 'Overview'),
    [product.sections],
  );

  const descriptionSectionData = useMemo(
    () => product.sections?.find(section => section.name === 'Description'),
    [product.sections],
  );

  const ingredients = useMemo(() => {
    const ingredientSection = product.sections?.find(section => section.name === 'Ingredients');
    if (!ingredientSection) {
      return null;
    }
    return ingredientSection.attributes?.find(
      attribute => attribute.attribute_name === 'ingredients_statement',
    )?.attribute_value;
  }, [product.sections]);

  const specifications = useMemo(() => {
    const specificationsSection = product.sections?.find(
      section => section.name === 'Specifications',
    );
    if (specificationsSection) {
      return specificationsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const weightAndDimensions = useMemo(() => {
    const weightAndDimensionsSection = product.sections?.find(
      section => section.name === 'Weight & Dimensions',
    );
    if (weightAndDimensionsSection) {
      return weightAndDimensionsSection.attributes;
    }
    return [];
  }, [product.sections]);

  const nutritionAttributes = useMemo(
    () => product.sections?.find(section => section.name === 'Nutrition Facts'),
    [product.sections],
  );

  const claims = useMemo(() => {
    const claimsSection = product.sections?.find(section => section.name === 'Claims');
    if (claimsSection) {
      return claimsSection.attributes.map(attr => attr.attribute_value);
    }
    return [];
  }, [product.sections]);

  const allergens = useMemo(() => {
    const allergensSection = product.sections?.find(section => section.name === 'Allergens');
    if (allergensSection) {
      return allergensSection.attributes;
    }
    return [];
  }, [product.sections]);

  const preparationInstructions = useMemo(() => {
    const ingredientSection = product.sections?.find(section => section.name === 'Tips & Handling');
    if (!ingredientSection) {
      return null;
    }
    return ingredientSection.attributes?.find(
      attribute => attribute.attribute_name === 'preparation_instructions',
    )?.attribute_value;
  }, [product.sections]);

  useEffect(() => {
    const sections = [
      DESCRIPTION,
      ...(ingredients ? [INGREDIENTS] : []),
      ...(claims.length > 0 ? [CLAIMS] : []),
      ...(allergens.length > 0 ? [ALLERGENS] : []),
      ...(weightAndDimensions.length > 0 ? [WEIGHT_DIMENSIONS] : []),
      ...(specifications.length > 0 ? [SPECIFICATIONS] : []),
      ...(preparationInstructions ? [TIPS_HANDLING] : []),
    ];
    setAttributeSections(sections);
  }, [
    claims,
    ingredients,
    allergens,
    specifications,
    weightAndDimensions,
    preparationInstructions,
  ]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !product) {
    return null;
  }

  return (
    <CustomContainer>
      {!hideHeader && (
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/upb-viewer')}
          />
          UPB View
        </h3>
      )}

      <div className="px-5 py-3 container-fluid">
        <Row className={css(styles.detailedContainer)}>
          <Col md={5}>
            <div className={classNames(css(styles.imageContainer), 'pr-5')}>
              <Image src={product.propagated_thumbnail_image_url} fluid />
              <ProductImagesSection images={product.product_images} />
            </div>
          </Col>
          <Col md={7}>
            <div className={classNames(css(styles.contentContainer), 'pl-3')}>
              <ProductHeaderSection product={product} productType={productType} />
              <ProductOverviewSection attributes={overviewSectionData?.attributes} />
              {attributeSections.map(sectionName => (
                <>
                  <AttributesSectionHeader
                    tab={sectionName}
                    selectedTabs={selectedTabs}
                    setSelectedTabs={setSelectedTabs}
                    customHeader={sectionName}
                  />
                  <Collapse in={!selectedTabs.includes(sectionName)}>
                    <div>
                      {sectionName === DESCRIPTION && (
                        <ProductDescriptionSection
                          attributes={descriptionSectionData?.attributes}
                        />
                      )}
                      {sectionName === INGREDIENTS && ingredients && (
                        <GeneralSection content={ingredients} />
                      )}
                      {sectionName === CLAIMS && <AttributesTagView tags={claims} />}
                      {sectionName === ALLERGENS && (
                        <AttributesGroupedTagView attributes={allergens} />
                      )}
                      {sectionName === WEIGHT_DIMENSIONS && (
                        <TableViewSection attributes={weightAndDimensions} />
                      )}
                      {sectionName === SPECIFICATIONS && (
                        <TableViewSection attributes={specifications} />
                      )}
                      {sectionName === TIPS_HANDLING && preparationInstructions && (
                        <GeneralSection content={preparationInstructions} />
                      )}
                    </div>
                  </Collapse>
                  <hr
                    className={classNames(
                      css(backgroundColorStyles.greyStroke, styles.hLine),
                      'mt-0',
                    )}
                  />
                </>
              ))}
              <NutritionFactsSection nutritionAttributes={nutritionAttributes?.attributes} />
            </div>
          </Col>
        </Row>
      </div>
    </CustomContainer>
  );
}

UPBProductDetailsPage.propTypes = {
  propProductId: PropTypes.string,
  productType: PropTypes.string,
  hideHeader: PropTypes.bool,
};

const styles = StyleSheet.create({
  detailedContainer: {
    display: 'flex',
  },
  imageContainer: {
    flex: '0 0 auto',
    position: 'sticky',
    top: 50,
  },
  contentContainer: {
    background: '#fff',
    minHeight: '50vh',
    flex: 1,
    overflowY: 'auto',
  },
  hLine: {
    height: 1,
    border: 0,
  },
});

export default UPBProductDetailsPage;
