import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import BrandHarvestGroupedBrandsViewModal from 'components/manage_brands/harvest_brands/harvest_brands_grouped/BrandHarvestGroupedBrandsViewModal';
import BrandHarvestingEditModal from 'components/manage_brands/harvest_brands/BrandHarvestingEditModal';
import { BrandHarvestingPriority } from 'lib/enums/BrandHarvestingEnums';
import { BRANDS_BY_IDS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function BrandHarvestGroupedViewTableRow({ groupedRow }) {
  const [showGroupedBrandsModal, setShowGroupedBrandsModal] = useState(false);
  const [showBrandHarvestingEditModal, setShowBrandHarvestingEditModal] = useState(false);
  const [singleBrandId, setSingleBrandId] = useState(null);

  // in-order to populate data on the edit modal we will obtain data on a single brand
  const { data: { data = [] } = {} } = useGet(
    BRANDS_BY_IDS,
    { ids: singleBrandId, with_harvesting_details: 'true' },
    !singleBrandId,
  );

  return (
    <tr>
      <td>
        {groupedRow.harvest_url_domain?.length > 50
          ? groupedRow.harvest_url_domain.substring(0, 50) + '...'
          : groupedRow.harvest_url_domain}
      </td>
      <td>{groupedRow.manufacturer_name}</td>
      <td>{groupedRow.brand_count}</td>
      <td>{groupedRow.total_product_count}</td>
      <td>{BrandHarvestingPriority[groupedRow.priority]}</td>
      <td className="d-flex justify-content-center">
        <Button variant="link" onClick={() => setShowGroupedBrandsModal(true)}>
          View brands
        </Button>
        <Button
          variant="link"
          onClick={() => (window.location.href = groupedRow.scraper_file_url)}
          disabled={!groupedRow.scraper_file_url}
        >
          Download
        </Button>
        <Button
          variant="link"
          onClick={() => {
            setSingleBrandId(groupedRow.brand_ids.split(',')[0]);
            setShowBrandHarvestingEditModal(true);
          }}
        >
          Edit
        </Button>
      </td>
      {showGroupedBrandsModal ? (
        <BrandHarvestGroupedBrandsViewModal
          onHide={() => setShowGroupedBrandsModal(false)}
          brandIds={groupedRow.brand_ids}
        />
      ) : null}
      {showBrandHarvestingEditModal && data.length > 0 && (
        <BrandHarvestingEditModal
          onHide={() => setShowBrandHarvestingEditModal(false)}
          isMultiBrandEdit={true}
          selectedIds={groupedRow.brand_ids}
          brandToHarvest={{
            gtin_exists: data[0].gtin_exists,
            harvest_status: data[0].harvest_status,
            harvest_url: data[0].harvest_url,
            manufacturer_product_number_exists: data[0].manufacturer_product_number_exists,
            data_quality: data[0].data_quality,
            priority: data[0].priority,
          }}
        />
      )}
    </tr>
  );
}

BrandHarvestGroupedViewTableRow.propTypes = {
  brands: PropTypes.array,
  groupedRow: PropTypes.object,
};

export default BrandHarvestGroupedViewTableRow;
