import React, { useState } from 'react';

import { Row } from 'react-bootstrap';

import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import { GLOBAL_PRODUCT_WISE_CONFLICTS } from 'lib/networking/endpoints';
import GlobalProductWiseConflictsTable from 'components/global_products_conflicts/GlobalProductWiseConflictsTable';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function GlobalProductWiseConflictsVew() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const { data: { data: { global_products: globalProducts = [], count } = {} } = {}, loading } =
    useGet(GLOBAL_PRODUCT_WISE_CONFLICTS, {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    });

  return (
    <>
      <Row>
        <div className="w-100 d-flex justify-content-end px-3">
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disabled={loading}
          />
        </div>
      </Row>
      <div className="mt-3">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <GlobalProductWiseConflictsTable globalProducts={globalProducts} />
        )}
      </div>
    </>
  );
}

export default GlobalProductWiseConflictsVew;
