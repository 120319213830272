import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import { GLOBAL_PRODUCTS_CATEGORY_CONFLICTS } from 'lib/networking/endpoints';
import GlobalProductsCategoryConflictsTable from 'components/global_products_category_conflicts/GlobalProductsCategoryConflictsTable';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function GlobalProductsCategoryConflictsVew() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const { data: { data: { global_products: globalProducts = [], count } = {} } = {}, loading } =
    useGet(GLOBAL_PRODUCTS_CATEGORY_CONFLICTS, {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    });

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">Global Products Category Conflicts</h3>
        </Col>
      </Row>
      <Row>
        <div className="w-100 d-flex justify-content-end px-3">
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disabled={loading}
          />
        </div>
      </Row>
      <div className="mt-3">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <GlobalProductsCategoryConflictsTable globalProducts={globalProducts} />
        )}
      </div>
    </Container>
  );
}

export default GlobalProductsCategoryConflictsVew;
