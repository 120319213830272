import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { areYouSure } from 'lib/utils/toast';
import { SKU_MAPPINGS_UNLINK_PRODUCTS } from 'lib/networking/endpoints';
import { SwalToast } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function ProductUnlinkButton({
  globalProductId,
  manufacturerProductIds = [],
  vendorProductIds = [],
  buttonText = 'Unlink',
  refetch,
  disabled = false,
  showProductUnlinkModal = false,
  setShowProductUnlinkModal,
}) {
  const { postData: unlinkProducts, loading } = usePost(
    SKU_MAPPINGS_UNLINK_PRODUCTS,
    () => {
      SwalToast('success', `Unlinked Product`);
      refetch();
    },
    () => SwalToast('error', `Could Not Unlink The Product`),
  );

  return (
    <Button
      className="mr-3 px-4"
      disabled={disabled || loading}
      variant="outline-danger"
      onClick={
        showProductUnlinkModal
          ? () => setShowProductUnlinkModal(true)
          : () =>
              areYouSure(
                () =>
                  unlinkProducts({
                    global_product_id: globalProductId,
                    manufacturer_product_ids: manufacturerProductIds,
                    vendor_product_ids: vendorProductIds,
                    link_to_similar_gtin: false,
                  }),
                'Are you sure you want to unlink this product?',
              )
      }
    >
      {buttonText}
    </Button>
  );
}

ProductUnlinkButton.propTypes = {
  globalProductId: PropTypes.number,
  manufacturerProductIds: PropTypes.array,
  vendorProductIds: PropTypes.array,
  buttonText: PropTypes.string,
  refetch: PropTypes.func,
  disabled: PropTypes.bool,
  showProductUnlinkModal: PropTypes.bool,
  setShowProductUnlinkModal: PropTypes.func,
};

export default ProductUnlinkButton;
