import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';

import {
  ADD_IMAGES_TO_PRODUCT,
  PRODUCT_IMAGES_DELETE_MULTI,
  PRODUCT_IMAGE_MAKE_THUMBNAIL,
} from 'lib/networking/endpoints';
import { areYouSure } from 'lib/utils/toast';
import AssetRow from 'components/global_products/detail_view/AssetRow';
import { blackberry, salmon } from 'lib/css/colors';
import ImageUploadModal from 'components/shared/ImageUploadModal';
import { GLOBAL_PRODUCT_TYPE } from 'lib/enums/ProductType';
import { GlobalProductsDetailContext } from 'components/global_products/detail_view/GlobalProductsDetailContainer';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import usePost from 'lib/hooks/usePost';

function GlobalProductAssetView({ refetch, selectedProductOption }) {
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const { otherImages, thumbnailImage, loading } = useContext(GlobalProductsDetailContext);

  const { postData: createThumbnail, loading: thumbCreateLoading } = usePost(
    PRODUCT_IMAGE_MAKE_THUMBNAIL,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Thumbnail created successfully',
        timer: 500,
      });
      refetch();
    },
    error => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error while creating a thumbnail: ${error?.response?.data?.message}`,
        timer: 2000,
      });
    },
  );

  const { postData: deleteMultiImages, loading: deleting } = usePost(
    PRODUCT_IMAGES_DELETE_MULTI,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Successfully deleted',
        timer: 500,
      });
      refetch();
    },
    error => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error while deleting images: ${error?.response?.data?.message}`,
        timer: 2000,
      });
    },
  );

  const { postData: addImages, loading: uploading } = usePost(
    ADD_IMAGES_TO_PRODUCT,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Images added to the product successfully',
        title: 'Success',
        timer: 500,
      }).then(() => {
        setShowImageUploadModal(false);
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while assigning images: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  const addImagesToTheProduct = () => {
    addImages({
      product_id: selectedProductOption.productId,
      product_type: selectedProductOption?.productType,
      uploaded_images: imageUrls,
    });
  };

  if (thumbCreateLoading || loading || uploading) {
    return <LoadingSpinner short />;
  }

  return (
    <>
      <Row className="mt-3">
        {selectedAssets.length ? (
          <Col>
            <Button
              variant="link"
              className={classNames(css(styles.deleteButton), 'float-right mr-3')}
              disabled={!selectedAssets.length || deleting}
              onClick={() =>
                areYouSure(
                  () => deleteMultiImages({ product_image_ids: selectedAssets }),
                  'Are you sure you want to delete these images?',
                )
              }
            >
              Delete
            </Button>
            <Button
              variant="link"
              className={classNames(css(styles.thumbnailButton), 'float-right mr-2')}
              disabled={!selectedAssets.length || selectedAssets.length > 1 || thumbCreateLoading}
              onClick={() => createThumbnail({ product_image_id: selectedAssets[0] })}
            >
              Make Thumbnail
            </Button>
          </Col>
        ) : null}
      </Row>
      <div className="my-4">
        <Row>
          <Col>
            <Button
              disabled={selectedProductOption === GLOBAL_PRODUCT_TYPE}
              className="float-right"
              onClick={() => setShowImageUploadModal(true)}
            >
              Upload Images
            </Button>
          </Col>
        </Row>
        {otherImages?.length || thumbnailImage.url ? (
          <Table hover className="mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Image</th>
                <th>Type</th>
                <th>State</th>
                <th>Facing</th>
                <th>Image Source</th>
                <th>Uploaded By</th>
                <th>Date Uploaded</th>
                <th>Last Modified</th>
              </tr>
            </thead>
            <tbody>
              {thumbnailImage.id || thumbnailImage.url ? (
                <AssetRow
                  key={thumbnailImage.id + '_' + thumbnailImage.url}
                  asset={thumbnailImage}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  isPropagated={thumbnailImage.is_propagated}
                />
              ) : null}
              {otherImages.map(asset => (
                <AssetRow
                  key={asset.id}
                  asset={asset}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  isPropagated={asset.is_propagated}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="m-3">This product has no assets yet</div>
        )}
        {showImageUploadModal ? (
          <ImageUploadModal
            onHide={() => setShowImageUploadModal(false)}
            onSubmit={addImagesToTheProduct}
            header="Upload images"
            imageUrls={imageUrls}
            setImageUrls={setImageUrls}
          />
        ) : null}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  thumbnailButton: {
    color: blackberry,
  },
  deleteButton: {
    color: salmon,
  },
});

GlobalProductAssetView.propTypes = {
  selectedProductOption: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

export default GlobalProductAssetView;
