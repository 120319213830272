import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

function ManufacturersSection({ manufacturerData }) {
  return (
    <div className="my-4">
      <div className="d-flex justify-content-between">
        <h4>Manufacturer Products</h4>
        <div className="text-secondary">{manufacturerData.length} Products</div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cut+Dry ID</th>
            <th>Manufacturer</th>
            <th>Product</th>
            <th>SKU</th>
          </tr>
        </thead>
        <tbody>
          {manufacturerData.map(mfrProduct => (
            <tr key={mfrProduct.id}>
              <td>{mfrProduct.cnd_id}</td>
              <td>{mfrProduct.name}</td>
              <td>{mfrProduct.global_manufacturer.name}</td>
              <td>{mfrProduct.manufacturer_sku}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

ManufacturersSection.propTypes = {
  manufacturerData: PropTypes.array,
};

export default ManufacturersSection;
