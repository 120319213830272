import React from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import AttributeViewSection from 'components/manage_attribute_value/value_section/AttributeViewSection';
import Container from 'components/shared/Container';
import GlobalAttributeViewSection from 'components/manage_attribute_value/value_section/GlobalAttributeViewSection';
import pointerOnHover from 'lib/css/pointerOnHover';

function ManageAttributeValueDetailsView() {
  const { attribute_name, attribute_type } = useParams();
  const history = useHistory();

  let decodedAttributeName;
  try {
    decodedAttributeName = decodeURIComponent(attribute_name);
  } catch (error) {
    // having a `%` on the name will throw an error when decoding
    decodedAttributeName = attribute_name;
  }
  return (
    <Container className="mx-3">
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/manage-attributes')}
        />
        {decodedAttributeName}
      </h3>
      {attribute_type === 'attribute' ? <AttributeViewSection /> : <GlobalAttributeViewSection />}
    </Container>
  );
}

export default ManageAttributeValueDetailsView;
