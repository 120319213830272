import React, { useContext, useState } from 'react';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import dayjs from 'dayjs';
import Emoji from 'react-emoji-render';

import { AuthContext } from 'components/providers/AuthContextProvider';
import BrandHarvestingWidgetView from 'components/dashboard/brand_harvesting/BrandHarvestingWidgetView';
import { DASHBOARD_WIDGETS } from 'lib/constants';
import { DASHBOARD_BRAND_HARVESTING } from 'lib/networking/endpoints';
import DashboardWidgetTile from 'components/dashboard/DashboardWidgetTile';
import DashboardWidgetType from 'lib/enums/DashboardWidgetTypes';
import { BrandHarvestingStatus } from 'lib/enums/BrandHarvestingEnums';
import { DashboardWidgetTypeLabels, WidgetClickHandlers } from 'lib/enums/DashboardWidgetTypes';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import WidgetSelectionModal from './WidgetSelectionModal';
import useGet from 'lib/hooks/useGet';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

function DashboardView() {
  const { authUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [activeWidgets, setActiveWidgets] = useLocalStorage(DASHBOARD_WIDGETS, [
    DashboardWidgetType.VERIFIED_VENDOR_PRODUCTS,
    DashboardWidgetType.VERIFIED_BRANDS,
    DashboardWidgetType.VERIFIED_MANUFACTURER_PRODUCTS,
    DashboardWidgetType.ENRICHED_BRANDS,
    DashboardWidgetType.VERIFIED_MANUFACTURERS,
    DashboardWidgetType.TAGGED_IMAGES,
    DashboardWidgetType.VERIFICATION_PENDING_BRANDS,
    DashboardWidgetType.VERIFICATION_PENDING_MANUFACTURERS,
  ]);
  const startDate = dayjs().subtract(6, 'day').startOf('day').toDate();
  const endDate = dayjs().endOf('day').toDate();
  const differenceInMilliseconds = dayjs(endDate).diff(dayjs(startDate));
  const dateDifference = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  const activeBrandHarvestingWidgets = Object.keys(BrandHarvestingStatus).filter(widget =>
    activeWidgets.includes(widget),
  );

  const activeBrandHarvestingWidgetsString = activeBrandHarvestingWidgets.join(',');

  const { data: { harvesting_dashboard_data = [] } = {}, loading } = useGet(
    DASHBOARD_BRAND_HARVESTING,
    {
      active_widgets: activeBrandHarvestingWidgetsString,
      start_date: dayjs(startDate).format(DATE_TIME_FORMAT),
      end_date: dayjs(endDate).format(DATE_TIME_FORMAT),
    },
  );

  const widgetOptions = [
    {
      category: 'Default Widgets',
      options: Object.values(DashboardWidgetType).map(widgetType => ({
        value: widgetType,
        label: DashboardWidgetTypeLabels[widgetType],
      })),
    },
    {
      category: 'Brand Harvesting Widgets',
      options: Object.keys(BrandHarvestingStatus).map(widgetType => ({
        value: widgetType,
        label: BrandHarvestingStatus[widgetType],
      })),
    },
  ];

  return (
    <div className="m-3">
      <div className="d-flex align-items-center justify-content-between mr-2">
        <h2 className={css(styles.mainHeader)}>
          Hello {authUser.name}
          <Emoji text="👋" className={css(styles.wave)} />
        </h2>
        <div>
          <Button onClick={() => setShowModal(true)} className={css(styles.addButton)}>
            Add/Remove Widgets
          </Button>
        </div>
      </div>
      <div className={css(styles.gridContainer)}>
        {activeWidgets.map(
          widgetType =>
            Object.values(DashboardWidgetType).includes(widgetType) && (
              <div key={widgetType} className={css(styles.widgetContainer)}>
                <DashboardWidgetTile
                  widgetType={widgetType}
                  label={DashboardWidgetTypeLabels[widgetType]}
                  onClick={WidgetClickHandlers[widgetType]}
                />
              </div>
            ),
        )}
      </div>
      <div>
        {activeBrandHarvestingWidgets.length > 0 && (
          <BrandHarvestingWidgetView
            activeBrandHarvestingWidgets={activeBrandHarvestingWidgets}
            harvestDashboardData={harvesting_dashboard_data}
            loading={loading}
            dateDifference={dateDifference}
          />
        )}
      </div>
      {showModal ? (
        <WidgetSelectionModal
          show={showModal}
          onHide={() => setShowModal(false)}
          widgetOptions={widgetOptions}
          activeWidgets={activeWidgets}
          setActiveWidgets={setActiveWidgets}
        />
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 1fr))',
    gap: '20px',
  },
  widgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainHeader: {
    margin: 30,
  },
  wave: {
    animationName: {
      '0%': { transform: 'rotate(0deg)' },
      '10%': { transform: 'rotate(14deg)' },
      '20%': { transform: 'rotate(-8deg)' },
      '30%': { transform: 'rotate(14deg)' },
      '40%': { transform: 'rotate(-4deg)' },
      '50%': { transform: 'rotate(10deg)' },
      '60%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(0deg)' },
    },
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    transformOrigin: '60% 60%',
    display: 'inline-block',
  },
});

export default DashboardView;
