import React from 'react';
import PropTypes from 'prop-types';

import MergeGlobalProductsModal from 'components/global_products_conflicts/conflict_resolve_modals/MergeGlobalProductsModal';
import ResolveByNewGlobalProductModal from 'components/global_products_conflicts/conflict_resolve_modals/ResolveByNewGlobalProductModal';
import ResolveByUpdateUpcGtinModal from 'components/global_products_conflicts/conflict_resolve_modals/ResolveByUpdateUpcGtinModal';

function GlobalProductConflictResolveModals({
  conflict,
  globalProduct,
  refetchConflicts,
  showResolveByUpdateUpcGtinModal,
  showResolveByMergeModal,
  showResolveByNewGlobalProductModal,
  setShowResolveByUpdateUpcGtinModal,
  setShowResolveByMergeModal,
  setShowResolveByNewGlobalProductModal,
}) {
  return (
    <>
      <ResolveByUpdateUpcGtinModal
        product={globalProduct ? globalProduct : conflict.global_product}
        conflict={conflict}
        show={showResolveByUpdateUpcGtinModal}
        onHide={() => setShowResolveByUpdateUpcGtinModal(false)}
        refetch={refetchConflicts}
      />
      {conflict.conflict_id_list?.length ? (
        <MergeGlobalProductsModal
          conflict={conflict}
          globalProduct={globalProduct ? globalProduct : conflict.global_product}
          duplicateGlobalProductIDs={conflict.conflict_id_list}
          show={showResolveByMergeModal}
          onHide={() => setShowResolveByMergeModal(false)}
          refetch={refetchConflicts}
        />
      ) : null}
      <ResolveByNewGlobalProductModal
        conflict={conflict}
        show={showResolveByNewGlobalProductModal}
        onHide={() => setShowResolveByNewGlobalProductModal(false)}
        refetch={refetchConflicts}
      />
    </>
  );
}

GlobalProductConflictResolveModals.propTypes = {
  conflict: PropTypes.object,
  globalProduct: PropTypes.object,
  refetchConflicts: PropTypes.func,
  showResolveByUpdateUpcGtinModal: PropTypes.bool,
  showResolveByMergeModal: PropTypes.bool,
  showResolveByNewGlobalProductModal: PropTypes.bool,
  setShowResolveByUpdateUpcGtinModal: PropTypes.func,
  setShowResolveByMergeModal: PropTypes.func,
  setShowResolveByNewGlobalProductModal: PropTypes.func,
};

export default GlobalProductConflictResolveModals;
