import React from 'react';

import { useParams } from 'react-router-dom';

import {
  ATTRIBUTE,
  ATTRIBUTE_VALUE,
  ATTRIBUTE_VALUES,
  ATTRIBUTE_VALUE_DETAILS,
  ATTRIBUTE_VALUE_GROUP_MAP,
  ATTRIBUTE_VALUE_GROUP_MAPS_BY_ATTRIBUTE_ID,
} from 'lib/networking/endpoints';
import AttributeInputType from 'lib/enums/AttributeInputType';
import AttributeValueGroupSection from 'components/manage_attribute_value/shared/AttributeValueGroupSection';
import AttributeValueSection from 'components/manage_attribute_value/shared/AttributeValueSection';
import DetailsSection from 'components/manage_attribute_value/shared/DetailsSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import useDelete from 'lib/hooks/useDelete';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';

function AttributeViewSection() {
  const { attribute_id } = useParams();

  const {
    data: { attribute } = {},
    loading,
    refetch,
  } = useGet(ATTRIBUTE, { attribute_id: attribute_id });

  const {
    data: { value_group_records: valueGroupMapRecords } = [],
    loading: valueGroupMapsLoading,
    refetch: refetchValueGroupMaps,
  } = useGet(ATTRIBUTE_VALUE_GROUP_MAPS_BY_ATTRIBUTE_ID, { attribute_id: attribute_id });

  const {
    data: { attribute_values } = {},
    loading: attributeValuesLoading,
    refetch: refetchValues,
  } = useGet(ATTRIBUTE_VALUES, { attribute_id: attribute_id });

  const { putData: updateAttributeDetails } = usePut(
    ATTRIBUTE,
    () => {
      refetch();
      refetchValueGroupMaps();
      refetchValues();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while updating attribute details',
        'center',
      );
    },
  );

  const { putData: updateAttributeValue, loading: valueUpdating } = usePut(
    ATTRIBUTE_VALUE,
    () => {
      SwalDialog('success', 'Updating the values', 'Success', 'center');
      refetchValues();
      refetchValueGroupMaps();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while updating attribute values',
        'center',
      );
    },
  );

  const { postData: createNewAttributeValues, loading: addingANew } = usePost(
    ATTRIBUTE_VALUES,
    () => {
      SwalDialog('success', 'Created successfully', 'Success', 'center');
      refetchValues();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while updating attribute values',
        'center',
      );
    },
  );

  const { postData: createNewAttributeValueMap, loading: newValueMap } = usePost(
    ATTRIBUTE_VALUE_GROUP_MAP,
    () => {
      SwalDialog('success', 'Created successfully', 'Success', 'center');
      refetchValueGroupMaps();
    },
    () => SwalDialog('error', 'An error occurred while creating', 'Error', 'center'),
  );

  const { deleteData: deleteAttributeValueRecord } = useDelete(
    ATTRIBUTE_VALUE_DETAILS,
    false,
    () => refetchValues(),
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  const updateAttribute = data => {
    updateAttributeDetails({
      attribute_id: data.id,
      attribute_name: data.attributeName,
      attribute_display_name: data.attributeDisplayName,
      attribute_sub_section_id: data.subSection.id,
      attribute_sub_section_name: data.subSection.attribute_sub_section_name,
      attribute_section_id: data.section.id,
      attribute_section_name: data.section.attribute_section_name,
      input_type: data.inputType ? data.inputType.value : null,
      universal_specific_field: data.universalSpecificField,
      use_display_value: data.useDisplayValue,
      fsa_attribute_id: data.fsaAttributeId,
      usda_code: data.USDACode,
      mandatory: data.mandatory,
      visibility: data.visibility,
      is_filterable: data.isFilterable,
      is_indexed: data.isIndexed,
    });
  };

  const editAttributeValue = data => {
    updateAttributeValue({
      attribute_value_id: data.attributeValueId,
      value: data.attributeValue,
      fsa_attribute_value_id: data.fsaAttributeValueId,
      usda_code: data.usdaCode,
      attribute_id: attribute_id,
    });
  };

  const createNewValue = data => {
    createNewAttributeValues({
      attribute_values: data.attributeValues,
      attribute_value_ids: data.attributeValueIds,
      usda_codes: data.usdaCodes,
      attribute_id: attribute_id,
    });
  };

  const createNewValueGroupMap = data => {
    createNewAttributeValueMap({
      category_id: data.category.value,
      category_name: data.category.label,
      category_level: Object.keys(TaxonomyLevel).find(
        key => TaxonomyLevel[key] === data.categoryLevel.value,
      ),
      attribute_id: attribute_id,
      attribute_value_ids: data.newlyAddedAttributeValueIds,
      removed_attribute_value_ids: data.removedAttributeValueIds,
      attach_to_category: true, //create attribute maps when creating value groups
    });
  };

  const deleteValueRecord = data => {
    deleteAttributeValueRecord({ attribute_value_id: data.attributeValueId });
  };

  if (
    loading ||
    addingANew ||
    attributeValuesLoading ||
    valueGroupMapsLoading ||
    newValueMap ||
    valueUpdating
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {attribute ? (
        <DetailsSection
          attributeDetails={{
            attributeName: attribute.attribute_name,
            attributeDisplayName: attribute.attribute_display_name,
            id: attribute.id,
            inputType: { label: attribute.input_type || '', value: attribute.input_type || '' },
            universalSpecificField: attribute.universal_specific_field,
            subSection: attribute.attribute_sub_section,
            section: attribute.attribute_sub_section.attribute_section,
            useDisplayValue: attribute.use_display_value,
            fsaAttributeId: attribute.attribute_id,
            USDACode: attribute.usda_code,
            mandatory: attribute.mandatory,
            visibility: attribute.visibility,
            isFilterable: attribute.is_filterable,
            isIndexed: attribute.is_indexed,
          }}
          updateAttribute={updateAttribute}
          showToggleButtons={true}
          showVisibilityStatusOnly={true}
        />
      ) : null}
      {attribute?.input_type === AttributeInputType.LIST ? (
        <>
          <AttributeValueSection
            createNewAttributeValue={createNewValue}
            editAttributeValue={createNewValue}
            attribute_values={attribute_values}
            editRecord={editAttributeValue}
            deleteRecord={deleteValueRecord}
            subSection={attribute.attribute_sub_section}
            section={attribute.attribute_sub_section.attribute_section}
            attributeId={attribute.attribute_id}
          />
          <AttributeValueGroupSection
            attributeValues={attribute_values}
            attributeValueGroupMaps={valueGroupMapRecords}
            createNewAttributeValueMap={createNewValueGroupMap}
            refetchAll={refetchValueGroupMaps}
          />
        </>
      ) : null}
    </>
  );
}

export default AttributeViewSection;
