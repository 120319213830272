// update along with app/lib/enums/task.py
const TaskStatus = {
  QUEUED: 'QUEUED',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
  KILLED: 'KILLED',
};

const TaskStatusOptions = [
  { value: TaskStatus.QUEUED, label: 'QUEUED' },
  { value: TaskStatus.PROCESSING, label: 'PROCESSING' },
  { value: TaskStatus.DONE, label: 'DONE' },
  { value: TaskStatus.ERROR, label: 'ERROR' },
  { value: TaskStatus.KILLED, label: 'KILLED' },
];

const TaskType = {
  ATTRIBUTE_ASSIGNMENT: 'ATTRIBUTE_ASSIGNMENT',
  ATTRIBUTE_EXPORT_FOR_PRODUCT_LIST: 'ATTRIBUTE_EXPORT_FOR_PRODUCT_LIST',
  ATTRIBUTE_EXPORT_TO_SNOWFLAKE_S3: 'ATTRIBUTE_EXPORT_TO_SNOWFLAKE_S3',
  ATTRIBUTE_MIGRATION: 'ATTRIBUTE_MIGRATION',
  ATTRIBUTE_SCHEMA_ASSIGNMENT: 'ATTRIBUTE_SCHEMA_ASSIGNMENT',
  ASSIGN_THUMBNAILS: 'ASSIGN_THUMBNAILS',
  BATCH_ATTRIBUTE_PROPAGATION_REQUEST: 'BATCH_ATTRIBUTE_PROPAGATION_REQUEST',
  BROKEN_IMAGE_DELETE: 'BROKEN_IMAGE_DELETE',
  CACHE_CLEAR_FOR_BRAND_STATUS_UPDATE: 'CACHE_CLEAR_FOR_BRAND_STATUS_UPDATE',
  CACHE_CLEAR_FOR_VENDOR_OVERRIDE_FROM_MFR_CHANGED:
    'CACHE_CLEAR_FOR_VENDOR_OVERRIDE_FROM_MFR_CHANGED',
  CACHE_CLEAR_FOR_VENDOR_TRUSTED_SOURCE_UPDATE: 'CACHE_CLEAR_FOR_VENDOR_TRUSTED_SOURCE_UPDATE',
  CD_DATA_IMPORT: 'CD_DATA_IMPORT',
  DATA_EXPORT_TO_C_N_D: 'DATA_EXPORT_TO_C_N_D',
  DATA_SOURCE_PREPROCESSED_EXPORT: 'DATA_SOURCE_PREPROCESSED_EXPORT',
  DATA_SOURCE_SYNC: 'DATA_SOURCE_SYNC',
  DATA_SOURCE_RECURRING_SYNC: 'DATA_SOURCE_RECURRING_SYNC',
  DEMO_CATALOG_CREATION: 'DEMO_CATALOG_CREATION',
  INTEGRATION_EXPORT: 'INTEGRATION_EXPORT',
  IMAGE_CLEANUP: 'IMAGE_CLEANUP',
  NUTRITION_PANEL_UPLOAD: 'NUTRITION_PANEL_UPLOAD',
  P0_SYNC_TO_C_AND_D: 'P0_SYNC_TO_C_AND_D',
  PRODUCT_LIST_METRIC_COUNT: 'PRODUCT_LIST_METRIC_COUNT',
  PRODUCT_STATUS_UPDATE: 'PRODUCT_STATUS_UPDATE',
  REFRESH_CACHE_FOR_PRODUCT_LIST: 'REFRESH_CACHE_FOR_PRODUCT_LIST',
  STATS_CALCULATION: 'STATS_CALCULATION',
  UPB_PRODUCT_SCORE_UPDATE: 'UPB_PRODUCT_SCORE_UPDATE',
  INDEXING_ATTRIBUTE_VALUES: 'INDEXING_ATTRIBUTE_VALUES',
  ASSIGNING_UPB_SKUS: 'ASSIGNING_UPB_SKUS',
};

const TaskTypeOptions = [
  {
    value: TaskType.ATTRIBUTE_ASSIGNMENT,
    label: 'ATTRIBUTE_ASSIGNMENT',
  },
  { value: TaskType.ATTRIBUTE_EXPORT_FOR_PRODUCT_LIST, label: 'ATTRIBUTE_EXPORT_FOR_PRODUCT_LIST' },
  { value: TaskType.ATTRIBUTE_EXPORT_TO_SNOWFLAKE_S3, label: 'ATTRIBUTE_EXPORT_TO_SNOWFLAKE_S3' },
  { value: TaskType.ATTRIBUTE_MIGRATION, label: 'ATTRIBUTE_MIGRATION' },
  { value: TaskType.ATTRIBUTE_SCHEMA_ASSIGNMENT, label: 'ATTRIBUTE_SCHEMA_ASSIGNMENT' },
  { value: TaskType.ASSIGN_THUMBNAILS, label: 'ASSIGN_THUMBNAILS' },
  {
    value: TaskType.BATCH_ATTRIBUTE_PROPAGATION_REQUEST,
    label: 'BATCH_ATTRIBUTE_PROPAGATION_REQUEST',
  },
  { value: TaskType.BROKEN_IMAGE_DELETE, label: 'BROKEN_IMAGE_DELETE' },
  {
    value: TaskType.CACHE_CLEAR_FOR_BRAND_STATUS_UPDATE,
    label: 'CACHE_CLEAR_FOR_BRAND_STATUS_UPDATE',
  },
  {
    value: TaskType.CACHE_CLEAR_FOR_VENDOR_OVERRIDE_FROM_MFR_CHANGED,
    label: 'CACHE_CLEAR_FOR_VENDOR_OVERRIDE_FROM_MFR_CHANGED',
  },
  {
    value: TaskType.CACHE_CLEAR_FOR_VENDOR_TRUSTED_SOURCE_UPDATE,
    label: 'CACHE_CLEAR_FOR_VENDOR_TRUSTED_SOURCE_UPDATE',
  },
  {
    value: TaskType.CD_DATA_IMPORT,
    label: 'CD_DATA_IMPORT',
  },
  { value: TaskType.DATA_EXPORT_TO_C_N_D, label: 'DATA_EXPORT_TO_C_N_D' },
  { value: TaskType.DATA_SOURCE_PREPROCESSED_EXPORT, label: 'DATA_SOURCE_PREPROCESSED_EXPORT' },
  { value: TaskType.DATA_SOURCE_SYNC, label: 'DATA_SOURCE_SYNC' },
  { value: TaskType.DATA_SOURCE_RECURRING_SYNC, label: 'DATA_SOURCE_RECURRING_SYNC' },
  { value: TaskType.DEMO_CATALOG_CREATION, label: 'DEMO_CATALOG_CREATION' },
  { value: TaskType.INTEGRATION_EXPORT, label: 'INTEGRATION_EXPORT' },
  { value: TaskType.IMAGE_CLEANUP, label: 'IMAGE_CLEANUP' },
  { value: TaskType.P0_SYNC_TO_C_AND_D, label: 'P0_SYNC_TO_C_AND_D' },
  { value: TaskType.NUTRITION_PANEL_UPLOAD, label: 'NUTRITION_PANEL_UPLOAD' },
  { value: TaskType.PRODUCT_STATUS_UPDATE, label: 'PRODUCT_STATUS_UPDATE' },
  { value: TaskType.REFRESH_CACHE_FOR_PRODUCT_LIST, label: 'REFRESH_CACHE_FOR_PRODUCT_LIST' },
  { value: TaskType.STATS_CALCULATION, label: 'STATS_CALCULATION' },
  { value: TaskType.UPB_PRODUCT_SCORE_UPDATE, label: 'UPB_PRODUCT_SCORE_UPDATE' },
  { value: TaskType.INDEXING_ATTRIBUTE_VALUES, label: 'INDEXING_ATTRIBUTE_VALUES' },
  { value: TaskType.ASSIGNING_UPB_SKUS, label: 'ASSIGNING_UPB_SKUS' },
];

export { TaskStatus, TaskStatusOptions, TaskType, TaskTypeOptions };
