import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { ADVANCED_MAPPING_FUNCTIONS } from 'lib/constants';
import AdvancedAtttributeMaooingModalAttributeValueSection from 'components/data_sources/single_data_source_view/AdvancedAtttributeMappingModalAttributeValueSection';
import { ATTRIBUTE, ATTRIBUTE_VALUES, GLOBAL_ATTRIBUTE } from 'lib/networking/endpoints';
import isAdvancedMapping from 'lib/utils/isAdvancedMapping';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';
function AdvancedAttributeMappingModal({
  onHide,
  attributes,
  attributeMap,
  unsavedAttributeMap,
  setUnsavedAttributeMap,
}) {
  const [sourceAttribute, setSourceAttribute] = useState('');
  const [selectedOperation, setSelectedOperation] = useState('');
  const [newAdvancedMapping, setNewAdvancedMapping] = useState({});
  const [attribute_id, setAttributeID] = useState();
  const [global_attribute_id, setGlobalAttributeID] = useState();
  const [displayedAttribute, setDisplayedAttribute] = useState();

  const { data: { attribute } = {} } = useGet(ATTRIBUTE, { attribute_id: attribute_id });

  const { data: { global_attribute } = {} } = useGet(
    GLOBAL_ATTRIBUTE,
    { global_attribute_id: global_attribute_id },
    !global_attribute_id,
  );

  const { data: { attribute_values } = {} } = useGet(
    ATTRIBUTE_VALUES,
    {
      ...(attribute_id && { attribute_id: attribute_id }),
      ...(global_attribute_id && { global_attribute_id: global_attribute_id }),
    },
    !attribute_id && !global_attribute_id,
  );

  const sourceAttributeOptions = Object.keys(attributeMap)
    .filter(attribute => !isAdvancedMapping(attribute))
    .map(attribute => ({
      value: attribute,
      label: attribute,
    }));
  const attributeOptions = attributes.map(attr => ({
    label: attr.name, // the name displayed in the dropdown
    value: {
      attribute_id: attr.attribute_id,
      global_attribute_id: attr.global_attribute_id,
    },
  }));

  const operationOptions = Object.keys(ADVANCED_MAPPING_FUNCTIONS).map(operation => ({
    value: operation,
    label: operation,
  }));

  const quickButtonsOperations = Object.keys(ADVANCED_MAPPING_FUNCTIONS).filter(
    operation => ADVANCED_MAPPING_FUNCTIONS[operation]?.isQuickAdd,
  );

  const isNewMappingsAreValid =
    Object.keys(newAdvancedMapping).length &&
    Object.keys(newAdvancedMapping).every(
      attribute => !attribute.includes('SOURCE_ATTRIBUTE') && newAdvancedMapping[attribute] !== '',
    );

  const handleAddOperation = useCallback(
    operation => {
      if (!sourceAttribute || (!selectedOperation && !operation)) {
        return;
      }
      if (!operation) {
        operation = selectedOperation;
      }
      const operationInsertValue = ADVANCED_MAPPING_FUNCTIONS[operation].insertValue;
      let newSourceAttribute = operationInsertValue.replace('SOURCE_ATTRIBUTE', sourceAttribute);
      const newAdvancedMappingKeys = Object.keys(newAdvancedMapping);
      if (newAdvancedMappingKeys.includes(newSourceAttribute)) {
        const existingKeys = newAdvancedMappingKeys.filter(key =>
          key.startsWith(newSourceAttribute),
        );
        const copyCount = existingKeys.length - 1;
        if (copyCount > 0) {
          // increment the _copy number
          newSourceAttribute = `${newSourceAttribute}_copy${copyCount + 1}`;
        } else {
          // if the newSourceAttribute already exists, add a _copy number
          newSourceAttribute = `${newSourceAttribute}_copy1`;
        }
      }
      setNewAdvancedMapping({
        ...newAdvancedMapping,
        [newSourceAttribute]: '',
      });
    },
    [newAdvancedMapping, selectedOperation, sourceAttribute],
  );
  useEffect(() => {
    if (selectedOperation && newAdvancedMapping && !Object.keys(newAdvancedMapping).length) {
      handleAddOperation();
    }
  }, [handleAddOperation, newAdvancedMapping, selectedOperation]);

  useEffect(() => {
    setDisplayedAttribute(attribute_id ? attribute : global_attribute);
  }, [attribute_id, global_attribute_id, attribute, global_attribute]);

  const handleChange = (option, attribute) => {
    setNewAdvancedMapping({
      ...newAdvancedMapping,
      [attribute]: option.label,
    });
    setAttributeID(option.value.attribute_id);
    setGlobalAttributeID(option.value.global_attribute_id);
  };
  return (
    <Modal size="xl" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Advanced Attribute Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ height: '250px' }}>
          <Col>
            <Container>
              <Row>
                <Col>
                  <div className="font-weight-bold mb-2">1. Select source attribute</div>
                  <Select
                    className="w-75"
                    value={
                      sourceAttributeOptions.find(option => option.value === sourceAttribute) || ''
                    }
                    options={sourceAttributeOptions}
                    onChange={option => setSourceAttribute(option.value)}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <div className="font-weight-bold mb-2">2. Select operation</div>
                  <div className="d-flex">
                    <Select
                      isClearable
                      className="w-75"
                      value={
                        operationOptions.find(option => option.value === selectedOperation) || ''
                      }
                      options={operationOptions}
                      onChange={option => {
                        if (option === null) {
                          setSelectedOperation('');
                          return;
                        }
                        setSelectedOperation(option.value || '');
                      }}
                    />
                    <Button
                      className="ml-3"
                      variant="outline-primary"
                      disabled={!sourceAttribute || !selectedOperation}
                      onClick={() => handleAddOperation()}
                    >
                      + New Mapping
                    </Button>
                  </div>
                  <div>
                    {quickButtonsOperations.length > 0 &&
                      quickButtonsOperations.map((operation, index) => {
                        return (
                          <Button
                            key={index}
                            className="mt-2 mr-2"
                            variant="outline-primary"
                            disabled={!sourceAttribute}
                            onClick={() => handleAddOperation(operation)}
                          >
                            {operation}
                          </Button>
                        );
                      })}
                  </div>
                </Col>
              </Row>
              <div className="text-muted m-2">
                {selectedOperation && ADVANCED_MAPPING_FUNCTIONS[selectedOperation].description}
              </div>
            </Container>
          </Col>
          <Col>
            {attribute_values?.length > 0 && (
              <AdvancedAtttributeMaooingModalAttributeValueSection
                attribute_values={attribute_values}
                subSection={displayedAttribute?.attribute_sub_section || ''}
                section={displayedAttribute?.attribute_sub_section?.attribute_section || ''}
                attributeId={displayedAttribute?.attribute_id || ''}
              />
            )}
          </Col>
        </Row>

        <Container className="mt-3">
          <Row>
            <Col>
              {newAdvancedMapping &&
                Object.keys(newAdvancedMapping).map((attribute, index) => {
                  const isValidSourceAttribute = !attribute.includes('SOURCE_ATTRIBUTE');
                  return (
                    <div key={index} className={css(styles.mappingSection)}>
                      <Row>
                        <Col md={6} className="d-flex justify-content-center">
                          <Form.Label>Source Attribute</Form.Label>
                        </Col>
                        <Col md={1} className="d-flex justify-content-center"></Col>
                        <Col className="d-flex justify-content-center">
                          <Form.Label>FSA Attribute</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={attribute}
                            onChange={e => {
                              const newMapping = { ...newAdvancedMapping };
                              delete newMapping[attribute];
                              setNewAdvancedMapping({
                                ...newMapping,
                                [e.target.value]: newAdvancedMapping[attribute],
                              });
                            }}
                            className={css(styles.smallText)}
                          />
                          <span className={css(styles.redText)}>
                            {!isValidSourceAttribute &&
                              sourceAttribute &&
                              'Invalid Source Attribute.Please replace SOURCE_ATTRIBUTE ' +
                                'with a valid attribute'}
                          </span>
                        </Col>
                        <Col md={1} className="d-flex justify-content-center align-items-center">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <Select
                            className="w-100"
                            options={attributeOptions}
                            value={
                              attributeOptions.find(
                                option => option.label === newAdvancedMapping[attribute],
                              ) || ''
                            }
                            onChange={option => {
                              handleChange(option, attribute);
                            }}
                            placeholder="Select FSA attribute"
                          />
                          <span className="ml-3">
                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                              <FontAwesomeIcon
                                className={pointerOnHover}
                                icon={faTrashAlt}
                                onClick={() => {
                                  const newMapping = { ...newAdvancedMapping };
                                  delete newMapping[attribute];
                                  setNewAdvancedMapping(newMapping);
                                }}
                              />
                            </OverlayTrigger>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              {!isNewMappingsAreValid && (
                <div className={css(styles.redText)}>
                  Please fill all the fields to insert the mapping
                </div>
              )}
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                disabled={!isNewMappingsAreValid}
                variant="outline-primary"
                onClick={() => {
                  setUnsavedAttributeMap({ ...unsavedAttributeMap, ...newAdvancedMapping });
                  onHide();
                }}
              >
                Insert
              </Button>
            </Col>
            <Col md="auto" className="d-flex justify-content-end">
              <Button variant="outline-secondary" onClick={onHide}>
                Close
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  smallText: {
    fontSize: '0.8rem',
  },
  mappingSection: {
    border: '1px solid #e0e0e0',
    padding: '1rem',
    borderRadius: '5px',
    marginBottom: '1rem',
  },
  redText: {
    color: 'red',
    fontSize: '0.8rem',
  },
});

AdvancedAttributeMappingModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired,
  attributeMap: PropTypes.object.isRequired,
  unsavedAttributeMap: PropTypes.object.isRequired,
  setUnsavedAttributeMap: PropTypes.func.isRequired,
};

export default AdvancedAttributeMappingModal;
