// update along with app/lib/enums/attribute_input_type.py
const AttributeInputType = {
  LIST: 'List',
  TEXT: 'Text',
  BOOLEAN: 'Boolean',
  INTEGER: 'Integer',
  POSITIVE_INTEGER: 'Positive Integer',
  FLOAT: 'Float',
};

const ATTRIBUTE_INPUT_TYPE_LIST = [
  { value: AttributeInputType.TEXT, label: 'Text' },
  { value: AttributeInputType.LIST, label: 'List' },
  { value: AttributeInputType.BOOLEAN, label: 'Boolean' },
  { value: AttributeInputType.INTEGER, label: 'Integer' },
  { value: AttributeInputType.POSITIVE_INTEGER, label: 'Positive Integer' },
  { value: AttributeInputType.FLOAT, label: 'Float' },
];

const ATTRIBUTE_STATUS_LIST = [
  { value: true, label: 'Mandatory' },
  { value: false, label: 'Optional' },
  { value: null, label: 'Unspecified' },
];

export { ATTRIBUTE_INPUT_TYPE_LIST, ATTRIBUTE_STATUS_LIST };
export default AttributeInputType;
