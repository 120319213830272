import React from 'react';

import { useParams } from 'react-router-dom';

import { AttributePriorityOptions } from 'lib/enums/AttributePriorityLevel';
import {
  ATTRIBUTE_VALUE,
  ATTRIBUTE_VALUES,
  ATTRIBUTE_VALUE_DETAILS,
  ATTRIBUTE_VALUE_GROUP_MAP,
  ATTRIBUTE_VALUE_GROUP_MAPS_BY_ATTRIBUTE_ID,
  GLOBAL_ATTRIBUTE,
} from 'lib/networking/endpoints';
import AttributeInputType from 'lib/enums/AttributeInputType';
import AttributeValueGroupSection from 'components/manage_attribute_value/shared/AttributeValueGroupSection';
import AttributeValueSection from 'components/manage_attribute_value/shared/AttributeValueSection';
import DetailsSection from 'components/manage_attribute_value/shared/DetailsSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import useDelete from 'lib/hooks/useDelete';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';

function GlobalAttributeViewSection() {
  const { attribute_name, attribute_id } = useParams();

  const {
    data: { attribute_values } = {},
    loading: attributeValuesLoading,
    refetch: refetchValues,
  } = useGet(ATTRIBUTE_VALUES, { global_attribute_id: attribute_id });

  const {
    data: { value_group_records: valueGroupMapRecords } = [],
    loading: valueGroupMapsLoading,
    refetch: refetchValueGroupMaps,
  } = useGet(ATTRIBUTE_VALUE_GROUP_MAPS_BY_ATTRIBUTE_ID, { global_attribute_id: attribute_id });

  const {
    data: { global_attribute } = {},
    loading,
    refetch,
  } = useGet(GLOBAL_ATTRIBUTE, { global_attribute_id: attribute_id }, !attribute_id);

  const { postData: createOrUpdateGlobalAttribute } = usePost(
    GLOBAL_ATTRIBUTE,
    () => {
      refetch();
      refetchValues();
      refetchValueGroupMaps();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while updating attribute details',
        'center',
      );
    },
  );

  const { postData: createNewGlobalAttributeValues, loading: adding } = usePost(
    ATTRIBUTE_VALUES,
    () => {
      SwalDialog('success', 'Created successfully', 'Success', 'center');
      refetchValues();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while updating attribute values',
        'center',
      );
    },
  );

  const { postData: createNewAttributeValueMap, loading: newValueMap } = usePost(
    ATTRIBUTE_VALUE_GROUP_MAP,
    () => {
      SwalDialog('success', 'Created successfully', 'Success', 'center');
      refetchValueGroupMaps();
    },
    () => SwalDialog('error', 'An error occurred while creating', 'Error', 'center'),
  );

  const { putData: updateAttributeValue, loading: valueUpdating } = usePut(
    ATTRIBUTE_VALUE,
    () => {
      SwalDialog('success', 'Updating the values', 'Success', 'center');
      refetchValues();
      refetchValueGroupMaps();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while updating attribute values',
        'center',
      );
    },
  );

  const { deleteData: deleteAttributeValueRecord } = useDelete(
    ATTRIBUTE_VALUE_DETAILS,
    false,
    () => refetchValues(),
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  if (
    attributeValuesLoading ||
    loading ||
    adding ||
    valueGroupMapsLoading ||
    newValueMap ||
    valueUpdating
  ) {
    return <LoadingSpinner />;
  }

  const updateGlobalAttribute = data => {
    createOrUpdateGlobalAttribute({
      global_attribute_name_updated: true,
      attribute_display_name: data.attributeDisplayName,
      global_attribute_id: data.id,
      global_attribute_name: data.attributeName,
      attribute_sub_section_id: data.subSection.id,
      attribute_sub_section_name: data.subSection.attribute_sub_section_name,
      attribute_section_id: data.section.id,
      attribute_section_name: data.section.attribute_section_name,
      mandatory: data.mandatory,
      visibility: data.visibility,
      key_attribute: data.keyAttribute,
      input_type: data.inputType ? data.inputType.value : null,
      priority_level: data.priorityLevel,
      universal_specific_field: data.universalSpecificField,
      use_display_value: data.useDisplayValue,
      fsa_attribute_id: data.fsaAttributeId,
      usda_code: data.USDACode,
      is_indexed: data.isIndexed,
    });
  };

  const editAttributeValue = data => {
    updateAttributeValue({
      attribute_value_id: data.attributeValueId,
      value: data.attributeValue,
      usda_code: data.usdaCode,
      fsa_attribute_value_id: data.fsaAttributeValueId,
      global_attribute_id: attribute_id,
    });
  };

  const createNewValue = data => {
    createNewGlobalAttributeValues({
      attribute_values: data.attributeValues,
      attribute_value_ids: data.attributeValueIds,
      usda_codes: data.usdaCodes,
      global_attribute_id: attribute_id,
    });
  };

  const createNewValueGroupMap = data => {
    createNewAttributeValueMap({
      category_id: data.category.value,
      category_level: Object.keys(TaxonomyLevel).find(
        key => TaxonomyLevel[key] === data.categoryLevel.value,
      ),
      global_attribute_id: attribute_id,
      attribute_value_ids: data.newlyAddedAttributeValueIds,
      removed_attribute_value_ids: data.removedAttributeValueIds,
    });
  };

  const deleteValueRecord = data => {
    deleteAttributeValueRecord({ attribute_value_id: data.attributeValueId });
  };

  return (
    <>
      {global_attribute ? (
        <DetailsSection
          attributeDetails={{
            attributeName: global_attribute.global_attribute_name,
            attributeDisplayName: global_attribute.attribute_display_name,
            id: global_attribute.id,
            keyAttribute: global_attribute.key_attribute,
            mandatory: global_attribute.mandatory,
            visibility: global_attribute.visibility,
            inputType: {
              label: global_attribute.input_type || '',
              value: global_attribute.input_type || '',
            },
            priorityLevel: AttributePriorityOptions.find(
              op => op.label === global_attribute.priority_level,
            )?.value,
            subSection: global_attribute.attribute_sub_section,
            section: global_attribute.attribute_sub_section.attribute_section,
            universalSpecificField: global_attribute.universal_specific_field,
            useDisplayValue: global_attribute.use_display_value,
            fsaAttributeId: global_attribute.attribute_id,
            USDACode: global_attribute.usda_code,
            isIndexed: global_attribute.is_indexed,
          }}
          updateAttribute={updateGlobalAttribute}
          showVisibilityStatusOnly={false}
        />
      ) : null}
      {global_attribute?.input_type === AttributeInputType.LIST ? (
        <>
          <AttributeValueSection
            attributeName={attribute_name}
            createNewAttributeValue={createNewValue}
            attribute_values={attribute_values}
            editRecord={editAttributeValue}
            deleteRecord={deleteValueRecord}
            subSection={global_attribute.attribute_sub_section}
            section={global_attribute.attribute_sub_section.attribute_section}
            attributeId={global_attribute.attribute_id}
          />
          <AttributeValueGroupSection
            attributeValues={attribute_values}
            attributeValueGroupMaps={valueGroupMapRecords}
            createNewAttributeValueMap={createNewValueGroupMap}
            refetchAll={refetchValueGroupMaps}
          />
        </>
      ) : null}
    </>
  );
}

export default GlobalAttributeViewSection;
