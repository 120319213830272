import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Row } from 'react-bootstrap';

import { CATALOG_FILTER_ATTRIBUTES_VENDOR } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import CatalogAttributeFilterView from 'components/manage_vendors/vendor_details/catalog_settings/CatalogAttributeFilterView';
import LoadingSpinner from 'components/shared/LoadingSpinner';

function VendorCatalogSettingsView({ globalVendorId }) {
  const { data: { data: filter_values } = {}, loading } = useGet(
    CATALOG_FILTER_ATTRIBUTES_VENDOR,
    { global_vendor_id: globalVendorId },
    !globalVendorId,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <span className="mr-3 h6">Manage Catalog Filters</span>
          <Button variant="outline-primary">Add New Filter</Button>
        </Col>
      </Row>
      {filter_values?.map(filter => (
        <CatalogAttributeFilterView key={filter.id} catalogFilterAttribute={filter} />
      ))}
    </Container>
  );
}

VendorCatalogSettingsView.propTypes = {
  globalVendorId: PropTypes.number,
};

export default VendorCatalogSettingsView;
