import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Button, Col, Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';
import Tooltip from 'react-bootstrap/Tooltip';
import { useParams } from 'react-router-dom';

import { anchovy, backgroundGrey4, grape } from 'lib/css/colors';
import { CHANGE_LOG_TYPE } from 'lib/enums/ChangeLogEnums';
import CloneVendorProductModal from 'components/global_products/detail_view/CloneVendorProductModal';
import {
  CHANGE_LOGS,
  getURL,
  GLOBAL_PRODUCT,
  GLOBAL_PRODUCT_VENDOR_MARK_AS_DEMO,
  RECALCULATE_PRODUCT_SCORES,
} from 'lib/networking/endpoints';
import { GLOBAL_PRODUCT_TYPE, ProductType } from 'lib/enums/ProductType';
import GlobalProductsAttributesView from 'components/global_products/detail_view/GlobalProductsAttributesView';
import { GlobalProductsDetailContext } from 'components/global_products/detail_view/GlobalProductsDetailContainer';
import GlobalProductAssetView from 'components/global_products/detail_view/GlobalProductAssetView';
import GlobalProductsChangelogView from 'components/global_products/detail_view/GlobalProductsChangelogView';
import GlobalProductsRelationshipsView from 'components/global_products/detail_view/GlobalProductsRelationshipsView';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import { PRODUCT_DETAIL_VIEW_TABS, SORT_DIRECTIONS } from 'lib/constants';
import ProductScoreCard from 'components/shared/ProductScoreCard';
import UPBProductDetailsPage from 'components/upb_viewer/UPBProductDetailsPage';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import useQueryString from 'lib/hooks/useQueryString';

function GlobalProductsDetailView() {
  const { globalProductId, productID, productType } = useParams();
  const tab = useQueryString().get('tab');
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(tab || PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW);
  const [searchInput, setSearchInput] = useState('');
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const { setOtherImages, setThumbnailImage, setLoading } = useContext(GlobalProductsDetailContext);
  const selectedVendorProductId =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.productId
      : null;
  const selectedManufacturerProductId =
    selectedProductOption?.productType === ProductType.MANUFACTURER
      ? selectedProductOption?.productId
      : null;

  const isADemoProduct =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.product.is_demo_product
      : null;

  const {
    data: { data: globalProduct } = {},
    loading,
    error,
    refetch,
  } = useGet(getURL(GLOBAL_PRODUCT, { productId: globalProductId }), {}, !globalProductId);

  const { data: { data: changeLogs } = {} } = useGet(
    CHANGE_LOGS,
    {
      vendor_product_id: selectedVendorProductId,
      manufacturer_product_id: selectedManufacturerProductId,
      sort_direction: SORT_DIRECTIONS.DESC,
      change_log_type: CHANGE_LOG_TYPE.PRODUCT_UPDATE,
    },
    selectedProductOption?.productType === GLOBAL_PRODUCT_TYPE,
  );

  const { postData: reCalculateProductScores, loading: scoresLoading } = usePost(
    RECALCULATE_PRODUCT_SCORES,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product Scores re-calculated successfully',
      }).then(() => refetch());
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while re-calculating scores: ${error?.response?.data?.message}`,
      }),
  );

  const { postData: markAsDemoProduct, loading: markingAsDemoProduct } = usePost(
    GLOBAL_PRODUCT_VENDOR_MARK_AS_DEMO,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: isADemoProduct
          ? 'Product marked as not a demo product successfully'
          : 'Product marked as a demo product successfully',
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while marking the product as a demo: ` +
          ` ${error?.response?.data?.message}`,
      }),
  );

  const selectedProductOptions = useMemo(() => {
    if (!globalProduct) {
      return [];
    }
    const options = [
      {
        label: 'Global Product',
        value: `global-${globalProductId}`,
        productId: globalProduct.id,
        productType: GLOBAL_PRODUCT_TYPE,
        product: globalProduct,
      },
    ];
    globalProduct?.global_product_manufacturers?.forEach(product =>
      options.push({
        label: `${product.name} (${product.global_manufacturer.name} \
          - ${product.manufacturer_sku})`,
        value: `manufacturer-${product.id}`,
        productId: product.id,
        productType: ProductType.MANUFACTURER,
        product,
      }),
    );
    globalProduct?.global_product_vendors?.forEach(product =>
      options.push({
        label: `${product.name} (${product.global_vendor.name} - ${product.vendor_sku})`,
        value: `vendor-${product.id}`,
        productId: product.id,
        productType: ProductType.DISTRIBUTOR,
        product,
      }),
    );
    return options;
  }, [globalProduct, globalProductId]);

  // select initial product
  useEffect(() => {
    if (globalProduct && productID && productType) {
      const matchedProd = selectedProductOptions.filter(
        option => option?.productType === productType && option.productId.toString() === productID,
      );
      setSelectedProductOption(...matchedProd);
    } else if (globalProduct) {
      setSelectedProductOption({
        label: 'Global Product',
        value: `global-${globalProduct.id}`,
        productId: globalProduct.id,
        productType: GLOBAL_PRODUCT_TYPE,
        product: globalProduct,
      });
    }
  }, [globalProduct, productID, productType, selectedProductOptions]);

  if (loading || scoresLoading) {
    return <LoadingSpinner />;
  }

  if (error || !globalProduct) {
    return null;
  }

  const reCalculateToolTip = props => (
    <Tooltip {...props}>Recalculate product scores for this product</Tooltip>
  );

  const selectedProduct = selectedProductOption?.product;

  return (
    <Container fluid className={classNames(css(styles.container), 'py-3')}>
      <Row>
        <Col sm={3} className={css(styles.leftCol)}>
          <Image
            src={
              selectedProduct?.thumbnail_image?.url ??
              selectedProduct?.propagated_thumbnail_image_url ??
              placeholderProductImage
            }
            fluid
            className="mx-auto d-block w-100"
            alt="Product"
          />
          <h5 className="font-weight-bold py-3">{selectedProduct?.name}</h5>
          <Row className="mt-3">
            <Col className={css(styles.attributeName)}>Product</Col>
          </Row>
          <Row className={css(styles.productSelect)}>
            <Col>
              <Select
                className="w-100"
                value={selectedProductOptions.filter(
                  option => option.value === selectedProductOption?.value,
                )}
                options={selectedProductOptions}
                onChange={option => {
                  setOtherImages([]);
                  setThumbnailImage({});
                  setLoading(true);
                  setSelectedProductOption(option);
                }}
              />
            </Col>
          </Row>
          <div>
            <p className={css(styles.attributeName)}>UPB Score</p>
            <div className="mb-4">
              <ProductScoreCard
                product={selectedProduct}
                productType={selectedProductOption?.productType}
              />
            </div>
          </div>
          {selectedProduct?.global_manufacturer ? (
            <div>
              <p className={css(styles.attributeName)}>Manufacturer SKU</p>
              <p className={css(styles.attributeValue)}>{selectedProduct?.manufacturer_sku}</p>
            </div>
          ) : (
            <div>
              <p className={css(styles.attributeName)}>Vendor SKU</p>
              <p className={css(styles.attributeValue)}>{selectedProduct?.vendor_sku}</p>
            </div>
          )}
          <div>
            <p className={css(styles.attributeTitle)}>
              Product Scores
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={reCalculateToolTip}
              >
                <FontAwesomeIcon
                  icon={faRecycle}
                  className="mx-2"
                  onClick={e => {
                    e.stopPropagation();
                    reCalculateProductScores({
                      product_id: selectedProductOption?.productId,
                      product_type: selectedProductOption?.productType,
                    });
                  }}
                />
              </OverlayTrigger>
            </p>
            <div className="ml-3">
              <div>
                <p className={css(styles.attributeName)}>P1 Score</p>
                <p className={css(styles.attributeValue)}>
                  {selectedProduct?.p1_score_as_a_percentage !== undefined
                    ? selectedProduct.p1_score_as_a_percentage.toLocaleString(undefined, {
                        style: 'percent',
                      })
                    : '-'}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>FSA Score</p>
                <p className={css(styles.attributeValue)}>
                  {selectedProduct?.fsa_score_as_a_percentage !== undefined
                    ? selectedProduct.fsa_score_as_a_percentage.toLocaleString(undefined, {
                        style: 'percent',
                      })
                    : '-'}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>Propagated P1 Score</p>
                <p className={css(styles.attributeValue)}>
                  {selectedProduct?.propagated_p1_score !== undefined
                    ? selectedProduct.propagated_p1_score.toLocaleString(undefined, {
                        style: 'percent',
                      })
                    : '-'}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Cut and Dry ID</p>
            <p className={css(styles.attributeValue)}>{selectedProduct?.cnd_id || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>UPC</p>
            <p className={css(styles.attributeValue)}>{globalProduct.upc || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>GTIN</p>
            <p className={css(styles.attributeValue)}>{globalProduct.gtin || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>GTIN ITEM</p>
            <p className={css(styles.attributeValue)}>{globalProduct.gtin_item || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Global Product ID</p>
            <p className={css(styles.attributeValue)}>{globalProduct.id}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Created Date</p>
            <p className={css(styles.attributeValue)}>{globalProduct.date_created}</p>
          </div>
          {globalProduct.data_source_origin && (
            <div>
              <p className={css(styles.attributeName)}>Original Data Source</p>
              <p className={css(styles.attributeValue)}>{globalProduct.data_source_origin?.name}</p>
            </div>
          )}
          {globalProduct?.l4_category ? (
            <div>
              <div>
                <p className={css(styles.attributeName)}>L1 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.l3_category.l2_category.l1_category.category_name}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>L2 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.l3_category.l2_category.category_name}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>L3 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.l3_category.category_name}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>L4 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.category_name}
                </p>
              </div>
            </div>
          ) : null}
        </Col>
        <Col sm={9}>
          <div className="d-flex justify-content-end">
            <div className="mr-3 d-flex align-items-center">
              Mark as a demo product
              <ToggleSwitch
                className="ml-2"
                onColor={grape}
                checked={isADemoProduct}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={e => {
                  markAsDemoProduct({
                    vendor_product_id: selectedVendorProductId,
                    demo_status: !isADemoProduct,
                  });
                }}
                disabled={!selectedVendorProductId || markingAsDemoProduct}
              />
            </div>
            <Button
              variant="primary"
              onClick={() => setShowCloneModal(true)}
              disabled={!selectedVendorProductId}
            >
              Clone this product
            </Button>
          </div>
          <Tabs
            className="border-top-0"
            defaultActiveKey={activeTabKey}
            onSelect={selectedKey => setActiveTabKey(selectedKey)}
            transition={false}
          >
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW}
              title={PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <UPBProductDetailsPage
                propProductId={selectedProductOption?.productId}
                productType={selectedProductOption?.productType}
                hideHeader
              />
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES}
              title={PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              {selectedProductOption ? (
                <GlobalProductsAttributesView
                  productId={selectedProductOption.productId}
                  productType={selectedProductOption?.productType}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  refetch={refetch}
                />
              ) : null}
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.ASSETS}
              title={PRODUCT_DETAIL_VIEW_TABS.ASSETS}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.ASSETS
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <GlobalProductAssetView
                refetch={refetch}
                selectedProductOption={selectedProductOption}
              />
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.RELATIONSHIPS}
              title={PRODUCT_DETAIL_VIEW_TABS.RELATIONSHIPS}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.RELATIONSHIPS
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <GlobalProductsRelationshipsView globalProduct={globalProduct} refetch={refetch} />
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.CHANGE_LOG}
              title={PRODUCT_DETAIL_VIEW_TABS.CHANGE_LOG}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.CHANGE_LOG
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <GlobalProductsChangelogView
                changeLogs={
                  selectedProductOption?.productType !== GLOBAL_PRODUCT_TYPE ? changeLogs : []
                }
                refetch={refetch}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <CloneVendorProductModal
        showCloneModal={showCloneModal}
        setShowCloneModal={setShowCloneModal}
        vendorProductId={selectedVendorProductId}
        refetch={refetch}
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
  },
  attributeTitle: {
    fontWeight: 'bold',
    color: anchovy,
    marginBottom: 4,
  },
  attributeName: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  attributeValue: {
    display: 'block',
  },
  leftCol: {
    paddingLeft: '1.9rem',
    minHeight: '100vh',
    borderRight: '3px solid ' + backgroundGrey4,
  },
  categoryValue: {
    display: 'block',
  },
  productSelect: {
    marginBottom: '1.2rem',
  },
});

export default GlobalProductsDetailView;
