import React from 'react';

import classNames from 'classnames';
import { Col, Container, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { useParams } from 'react-router-dom';

import { categoryStyles } from 'lib/css/categoryTexts';
import { getURL, GLOBAL_PRODUCT_CATEGORY_CONFLICTS } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManufacturersSection from 'components/global_products/single_product_view/ManufacturersSection';
import useGet from 'lib/hooks/useGet';

function GlobalProductCategoryConflictDetails() {
  const { globalProductId } = useParams();

  const {
    data: { data: product } = {},
    loading,
    error,
  } = useGet(
    getURL(GLOBAL_PRODUCT_CATEGORY_CONFLICTS, { productId: globalProductId }),
    {},
    !globalProductId,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !product) {
    return null;
  }

  const category = product.category;
  const manufacturerData = product.global_product_manufacturers;

  return (
    <Container fluid className={classNames(css(styles.container), 'py-3 px-5')}>
      <h3 className="font-weight-bold mb-3">{product.name}</h3>
      <Table bordered>
        <tbody>
          <tr>
            <td className="font-weight-bold bg-light">ID</td>
            <td>{product.id}</td>
          </tr>
          <tr>
            <td className="font-weight-bold bg-light">GTIN</td>
            <td>{product.gtin}</td>
          </tr>
          <tr>
            <td className="font-weight-bold bg-light">UPC</td>
            <td>{product.upc}</td>
          </tr>
          <tr>
            <td className="font-weight-bold bg-light">Category</td>
            <td className="d-flex px-0">
              <Col className={css(categoryStyles.l1_category)}>{category?.l1_category}</Col>
              <Col className={css(categoryStyles.l2_category)}>{category?.l2_category}</Col>
              <Col className={css(categoryStyles.l3_category)}>{category?.l3_category}</Col>
              <Col className={css(categoryStyles.l4_category)}>{category?.l4_category}</Col>
            </td>
          </tr>
        </tbody>
      </Table>
      {manufacturerData?.length ? (
        <ManufacturersSection manufacturerData={manufacturerData} />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
    fontSize: '0.75rem',
  },
});

export default GlobalProductCategoryConflictDetails;
