import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import CustomContainer from 'components/shared/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import SwalDialog from 'lib/utils/toast';
import { uniq, without } from 'lodash';
import { useHistory } from 'react-router-dom';

import CategoryFilter from 'components/l4_default_images/review_view/CategoryFilter';
import L4ImageStatus, { L4ImageStatusOptions } from 'lib/enums/L4ImageStatus';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import ReviewCard from 'components/l4_default_images/review_view/ReviewCard';
import {
  TAXONOMY_GET_L4_CATEGORIES_BY_IMAGE_UPLOAD_STATUS,
  TAXONOMY_REVIEW_L4_DEFAULT_IMAGES,
} from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import UpdateReviewStatusModal from './UpdateReviewStatusModal';

function ReviewView() {
  const history = useHistory();
  const [l4ImageStatus, setL4ImageStatus] = useState(
    L4ImageStatusOptions.find(op => op.value === L4ImageStatus.PENDING_REVIEW),
  );
  const [selectedL3, setSelectedL3] = useState();
  const [checkedL4CategoryIds, setCheckedL4CategoryIds] = useState([]);
  const [uncheckedL4CategoryIds, setUncheckedL4CategoryIds] = useState([]);
  const [notApplicableL4CategoryIds, setNotApplicableL4CategoryIds] = useState([]);
  const [l4ImagesUploadedCategories, setL4ImagesUploadedCategories] = useState([]);
  const [showUpdateReviewStatusModal, setShowUpdateReviewStatusModal] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const toggleCheck = categoryId => {
    setSelectedCategoryId(categoryId);
    if (l4ImageStatus.value === L4ImageStatus.REJECTED) {
      if (checkedL4CategoryIds.find(id => id === categoryId)) {
        setCheckedL4CategoryIds([...without(checkedL4CategoryIds, categoryId)]);
      } else {
        setCheckedL4CategoryIds([...uniq([...checkedL4CategoryIds, categoryId])]);
      }
    } else if (l4ImageStatus.value === L4ImageStatus.REVIEWED) {
      if (!checkedL4CategoryIds.find(category => category.categoryId === categoryId)) {
        setShowFeedback(true);
        setShowUpdateReviewStatusModal(true);
      } else {
        const newCheckedIds = checkedL4CategoryIds.filter(
          category => category.categoryId !== categoryId,
        );
        setCheckedL4CategoryIds(newCheckedIds);
      }
    } else {
      if (checkedL4CategoryIds.find(id => id === categoryId)) {
        setCheckedL4CategoryIds([...without(checkedL4CategoryIds, categoryId)]);
        setShowFeedback(true);
        setShowUpdateReviewStatusModal(true);
      } else {
        setCheckedL4CategoryIds([...uniq([...checkedL4CategoryIds, categoryId])]);
        const newUncheckedIds = uncheckedL4CategoryIds.filter(
          item => item.categoryId !== categoryId,
        );
        setUncheckedL4CategoryIds(newUncheckedIds);
        const newNotApplicableIds = notApplicableL4CategoryIds.filter(id => id !== categoryId);
        setNotApplicableL4CategoryIds(newNotApplicableIds);
      }
    }
  };

  const { data: { data: l4Categories = [] } = [], loading: allProductsLoading } = useGet(
    TAXONOMY_GET_L4_CATEGORIES_BY_IMAGE_UPLOAD_STATUS,
    {
      l3_category_id: selectedL3,
      status: l4ImageStatus.value,
    },
    !l4ImageStatus || !selectedL3,
  );

  const { postData: submitReviews, loading: submitingReviews } = usePost(
    TAXONOMY_REVIEW_L4_DEFAULT_IMAGES,
    () => {
      SwalDialog('success', 'Updated reviews successfully', 'Success', 'center', () =>
        window.location.reload(),
      );
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred while adding the review: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  // if l4 categories gets updates then reset the checked ones
  useEffect(() => {
    if (l4Categories.length > 0) {
      const ids = l4Categories.map(cat => cat.id);
      if (l4ImageStatus.value === L4ImageStatus.REJECTED) {
        setCheckedL4CategoryIds([]);
      } else if (l4ImageStatus.value === L4ImageStatus.REVIEWED) {
        setCheckedL4CategoryIds([]);
      } else {
        setCheckedL4CategoryIds(ids);
        setUncheckedL4CategoryIds([]);
      }
    }
    setL4ImagesUploadedCategories(l4Categories);
  }, [l4Categories, l4ImageStatus]);

  useEffect(() => {
    if (
      !l4ImagesUploadedCategories ||
      l4ImagesUploadedCategories?.length === 0 ||
      l4ImageStatus.value !== L4ImageStatus.PENDING_REVIEW
    ) {
      return;
    }
    // When in PENDING_REVIEW view then all categories are checked except not applicable ones
    // Categories without default image url are considered not applicable
    const notApplicableIds = l4ImagesUploadedCategories
      .filter(cat => !cat.default_image_url)
      ?.map(cat => cat.id);
    setNotApplicableL4CategoryIds(notApplicableIds);
    // make not applicable categories unchecked
    setCheckedL4CategoryIds(prevL4Ids => prevL4Ids.filter(id => !notApplicableIds.includes(id)));
  }, [l4ImageStatus.value, l4ImagesUploadedCategories]);

  useEffect(() => {
    setSelectedL3();
    setL4ImagesUploadedCategories([]);
    setSelectedCategoryId();
  }, [l4ImageStatus]);

  function handleMarkAsReviewed() {
    if (l4ImageStatus.value === L4ImageStatus.REVIEWED) {
      submitReviews({
        verified_l4_category_ids: uncheckedL4CategoryIds,
        rejected_l4_category_ids: checkedL4CategoryIds,
        not_applicable_l4_category_ids: notApplicableL4CategoryIds,
      });
    } else {
      submitReviews({
        verified_l4_category_ids: checkedL4CategoryIds,
        rejected_l4_category_ids: uncheckedL4CategoryIds,
        not_applicable_l4_category_ids: notApplicableL4CategoryIds,
      });
    }
  }

  if (allProductsLoading || submitingReviews) {
    <LoadingSpinner />;
  }

  return (
    <CustomContainer>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/enrich-products/l4-default-images')}
        />
        Review L4 Category Images
      </h3>
      <Row className="mt-4">
        <Col sm="2">
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select
              options={L4ImageStatusOptions}
              value={l4ImageStatus}
              onChange={option => {
                setSelectedL3();
                setL4ImageStatus(option);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm="2">
          <CategoryFilter
            l4ImageStatus={l4ImageStatus.value}
            selectedL3CategoryId={selectedL3}
            setSelectedL3CategoryId={setSelectedL3}
          />
        </Col>
        <Col sm="2" className="d-flex align-items-center mt-3">
          {unsavedChanges && <span className="text-danger">You have unsaved changes</span>}
        </Col>
        <Col>
          <Button className="float-right" onClick={handleMarkAsReviewed}>
            {l4ImageStatus.value === L4ImageStatus.REVIEWED ? 'Mark As Rejected' : 'Save Changes'}
          </Button>
        </Col>
      </Row>
      <div className={classNames(css(styles.container), 'd-flex flex-wrap justify-content-left')}>
        {l4ImagesUploadedCategories.map(cat => (
          <ReviewCard
            key={cat.id}
            l4ImageStatus={l4ImageStatus.value}
            categoryName={cat.category_name}
            categoryId={cat.id}
            imageUrl={cat.default_image_url}
            checkedL4CategoryIds={checkedL4CategoryIds}
            handleToggleCheck={toggleCheck}
            isNotApplicable={notApplicableL4CategoryIds.includes(cat.id)}
            isRejected={
              l4ImageStatus.value !== L4ImageStatus.REVIEWED &&
              !!uncheckedL4CategoryIds.find(c => c.categoryId === cat.id)
            }
            isReviewed={
              l4ImageStatus.value !== L4ImageStatus.REVIEWED &&
              !!checkedL4CategoryIds.includes(cat.id)
            }
          />
        ))}
      </div>
      <UpdateReviewStatusModal
        show={showUpdateReviewStatusModal}
        onHide={() => setShowUpdateReviewStatusModal(false)}
        showFeedback={showFeedback}
        categoryId={selectedCategoryId}
        onSave={(categoryId, notApplicable, feedback) => {
          setUnsavedChanges(true);
          if (notApplicable) {
            setNotApplicableL4CategoryIds(uniq([...notApplicableL4CategoryIds, categoryId]));
            return;
          } else {
            const newNotApplicableIds = notApplicableL4CategoryIds.filter(id => id !== categoryId);
            setNotApplicableL4CategoryIds(newNotApplicableIds);
          }
          if (l4ImageStatus.value === L4ImageStatus.REVIEWED) {
            setCheckedL4CategoryIds([
              ...checkedL4CategoryIds,
              { categoryId: categoryId, feedback },
            ]);
          } else {
            setUncheckedL4CategoryIds([
              ...uncheckedL4CategoryIds,
              { categoryId: categoryId, feedback },
            ]);
          }
        }}
      />
    </CustomContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 500,
  },
});

export default ReviewView;
